import React, { useState } from 'react';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha';
import { useParams } from 'react-router-dom';

import { api } from '../../../services/api';

import './styles.scss';

const ReCaptcha = () => {
  const { orderUuid, key, version } = useParams<{
    orderUuid: string;
    key: string;
    version: string;
  }>();

  const onVerify = (token: any) => {
    try {
    } catch (error) {}
  };

  const setToken = async (token: string) => {
    const response = await api.put(`/orders/${orderUuid}`, {
      integrationProperties: {
        vtex: {
          recaptchaToken: token,
        },
      },
    });
  };

  return (
    <div className="chat-commerce">
      <div className="chat-commerce-content">
        <h2>Olá</h2>
        <p>Para maior segurança precisamos confirmar que você não é um robô</p>
        {version === '3' ? (
          <GoogleReCaptchaProvider reCaptchaKey={key}>
            <GoogleReCaptcha onVerify={onVerify} action="verify" />
          </GoogleReCaptchaProvider>
        ) : (
          ''
        )}
        {version === '2' ? <ReCAPTCHA sitekey={key} onChange={onVerify} /> : ''}
      </div>
    </div>
  );
};

export default ReCaptcha;
