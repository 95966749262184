import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import './styles.scss';

interface DisplayProgressBarProperties {
  proposalStatus: string;
}

export const steps = [
  {
    status: 'created',
    description: 'Proposta Criada',
  },
  {
    status: 'pending',
    description: 'Proposta Pendente',
  },
  {
    status: 'progress',
    description: 'Em Processo',
  },
  {
    status: 'reproved',
    description: 'Proposta Reprovada',
  },
  {
    status: 'completed',
    description: 'Proposta Concluída',
  },
];

export function DisplayProgressBar(props: DisplayProgressBarProperties) {
  const { proposalStatus } = props;

  const getStepPosition = (transferStatus) => {
    return steps.findIndex(({ status }) => status === transferStatus);
  };

  return (
    <>
      <div className="progress-bar-container">
        <ProgressBar
          percent={118 * (getStepPosition(proposalStatus) / steps.length)}
          filledBackground={
            proposalStatus === 'Proposta Reprovada' ? 'red' : '#3A6491'
          }
        >
          {steps.map((step, index, arr) => {
            return (
              <Step
                position={100 * (index / arr.length)}
                transition="scale"
                children={({ accomplished }) => (
                  <div className="container">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        color: 'white',
                        backgroundColor: accomplished ? '#3A6491' : '#A9A9A9',
                      }}
                    >
                      {index + 1}
                    </div>
                    <div className="status-container">
                      <p>{step.description}</p>
                    </div>
                  </div>
                )}
              />
            );
          })}
        </ProgressBar>
      </div>
    </>
  );
}
