import React, { useState, useEffect, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import InputMask from "react-input-mask";

import { api } from '../../../services/api';

import bannerImg from '../../../assets/banner/consignado_g.jpg';
import handMoneyImg from '../../../assets/icons/hand_money.svg';

import SimulationResult from '../../../shared/components/simulator/components/simulation-result/SimulationResult';

import classes from './styles.module.scss';
import '../../../styles/form.scss';

import listReasons from '../../../json/reasons.json';

function Home() {
    const productId = process.env.REACT_APP_PRODUCT_ID_CONSIGNADO;
    const [partnerId, setPartnerId] = useState<number | null>(null);
    const [value, setValue] = useState<string | number>('');
    const [installments, setInstallments] = useState<string>('84');
    const [cpf, setCpf] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [reason, setReason] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);

    const routeParams = useParams();

    const faker = window.location.href.indexOf('localhost') !== -1;
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [currentUrl, setCurrentUrl] = useState<string>('Home');
    const [simulatorForWhatsApp, setSimulatorForWhatsApp] = useState<boolean>(false);

    const paramToken = params.get('token');
    const paramName = params.get('nome') ?? '';
    const paramCpf = params.get('cpf') ?? '';
    const paramEmail = params.get('email') ?? '';
    const paramPhone = params.get('telefone') ?? '';
    const paramValue:number = Number(params.get('valor')) ?? 0;
    const paramInstallments = params.get('parcelas') ?? '84x';
    const paramSimulator = params.get('simulator');
    const paramAdhesionContractData = params.get('adhesion-contract') ? params.get('adhesion-contract').replace(/ /g, "+") : '';

    useEffect(() => {
        requestPartnerId();

        if(paramToken){
            // openResult(paramToken);
            clickTracking('#simulation-status-open');
        }

        if(paramSimulator){
            // openSimulator({
            //     value: 0,
            // });

            clickTracking('#simulator-open-with-link');

            if (paramSimulator === 'for-whatsapp') {
                setSimulatorForWhatsApp(true);
            }

            setShowModal(true);
        }

        if(paramName && paramEmail && paramPhone && !paramValue && !paramInstallments){
            clickTracking('#simulator-open-with-name-email-phone');
            // openSimulator({
            //     value: 0,
            // });
        }

        if(paramName && paramEmail && paramPhone && paramValue && paramInstallments){
            clickTracking('#simulator-open-with-name-email-phone-value-installment');
            // openSimulator({
            //     value: paramValue,
            // });
        }

        if (routeParams?.proposalId) {
            setShowModal(true);
        }

        if (process.env.REACT_APP_FAKER === "true") {
            setName(process.env.REACT_APP_FAKER_NAME);
            setCpf(process.env.REACT_APP_FAKER_CPF); //H - 17790460064 P - 28859766915
            setPhone(process.env.REACT_APP_FAKER_PHONE);
            setEmail(process.env.REACT_APP_FAKER_EMAIL);
            setInstallments(process.env.REACT_APP_FAKER_INSTALLMENTS);
            setReason(process.env.REACT_APP_FAKER_REASON);
            setValue(process.env.REACT_APP_FAKER_VALUE);
        }
    }, []);

    async function requestPartnerId() {
        // const { data } = await api.get(`partner-product/product/${productId}/default`);

        // if (data) {
        //     setPartnerId(data.partner.id);
        // } else {
            setPartnerId(null);
        // }
    }

    function clickTracking(url: string) {
        setCurrentUrl(url);
    }

    function isValidCPF(cpf) {
        if (typeof cpf !== 'string') return false;

        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

        cpf = cpf.split('');

        const validator = cpf
            .filter((digit, index, array) => index >= array.length - 2 && digit)
            .map( el => +el );

        const toValidate = pop => cpf
            .filter((digit, index, array) => index < array.length - pop && digit)
            .map(el => +el);

        const rest = (count, pop) => (toValidate(pop)
            .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11 % 10;

        return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1]);
    }

    function handleSimulate(event: FormEvent) {
        event.preventDefault();

        setErrorMessage('');

        if (
            name === '' ||
            cpf === '' ||
            phone === '' ||
            email === ''
        ) {
            setErrorMessage('Você deve preencher todos os campos!');
            return;
        }

        if (
            value === 0 ||
            installments === ''
        ) {
            setErrorMessage('Você deve preencher o valor e as parcelas!');
            return;
        }

        if (!isValidCPF(cpf)) {
            setErrorMessage('Você deve informar um CPF válido.');
            return;
        }

        if (partnerId) {
            setShowModal(true);
        } else {
            sendForWhatsApp();
        }
    }

    function sendForWhatsApp() {
        const whatsAppPhone = '5521965773075';
        const text = `Tipo de simulação: Empréstimo consignado
        Nome: ${name}
        E-mail: ${email}
        CPF: ${cpf}
        Telefone: ${phone}
        Valor: ${value}
        Parcelas: ${installments}`;

        window.open(`https://wa.me/${whatsAppPhone}?text=${text.replace(/  +/g, '').replace(/(?:\r\n|\r|\n)/g, '%0a').replace(/ /g, '%20')}`, '_blank');
    }

    return (
        <section className={classes.home} style={{backgroundImage: `url(${bannerImg})`}}>
            {showModal && (
                <SimulationResult
                    name={name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")}
                    cpf={cpf.replace(/[^0-9]/g, "")}
                    phone={phone.replace(/[^0-9]/g, "")}
                    value={value}
                    installments={installments}
                    email={email}
                    reason={reason}
                    handleClose={() => setShowModal(false)}
                    visible={showModal}
                    faker={faker}
                    clickTracking={clickTracking}
                    simulatorForWhatsApp={simulatorForWhatsApp}
                    adhesionContractData={paramAdhesionContractData}
                />
            )}
            <div className="container">
                <div className="row justify-content-left">
                    <div className="col-lg-8 col-md-9 col-xs-12">
                        <div className={classes.card} id="simulate-card">
                            <div className={classes.cardIcon}>
                                <div className={classes.roundIcon}>
                                    <img src={handMoneyImg} alt="Imagem de mão com dinheiro" />
                                </div>
                            </div>
                            <div className={classes.cardBody}>
                                <h3 className={classes.cardTitle}>Empréstimo consignado INSS e SIAPE</h3>
                                <p className={classes.cardText}>
                                    Preencha seus dados para simular sem compromisso
                                </p>
                                <div className={classes.cardForm}>
                                    <form onSubmit={handleSimulate}>
                                        <div className="row">
                                            <div className="col-xs-12 col-lg-6 form-group">
                                                <label htmlFor="inputName">Nome completo</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="inputName"
                                                    onChange={(e: any) => setName(e.target.value)}
                                                    value={name}
                                                    placeholder=""
                                                    required
                                                />
                                            </div>
                                            <div className="col-xs-12 col-lg-6 form-group">
                                                <label htmlFor="inputEmail">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="inputEmail"
                                                    onChange={(e: any) => setEmail(e.target.value)}
                                                    value={email}
                                                    placeholder=""
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col form-group">
                                                <label htmlFor="inputCpf">CPF</label>
                                                <InputMask
                                                    mask="999.999.999-99"
                                                    className="form-control"
                                                    id="inputCpf"
                                                    onChange={(e: any) => setCpf(e.target.value)}
                                                    value={cpf}
                                                    placeholder=""
                                                    required
                                                />
                                                </div>
                                            <div className="col form-group">
                                                <label htmlFor="inputPhone">Celular</label>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    className="form-control"
                                                    id="inputPhone"
                                                    onChange={(e: any) => setPhone(e.target.value)}
                                                    value={phone}
                                                    placeholder=""
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col form-group">
                                                <label htmlFor="inputValue">Qual o valor?</label>
                                                <CurrencyInput
                                                    className="form-control"
                                                    id="inputValue"
                                                    onValueChange={(value) => setValue(value)}
                                                    value={value}
                                                    prefix={'R$ '}
                                                    decimalsLimit={2}
                                                    decimalSeparator={','}
                                                    groupSeparator={'.'}
                                                    required
                                                />
                                            </div>
                                            <div className="col form-group">
                                                <label htmlFor="selectInstallments">Parcelas?</label>
                                                <select
                                                    className="form-control"
                                                    id="selectInstallments"
                                                    onChange={(e: any) => setInstallments(e.target.value)}
                                                    value={installments}
                                                    required
                                                >
                                                    <option value="24">24x</option>
                                                    <option value="36">36x</option>
                                                    <option value="48">48x</option>
                                                    <option value="60">60x</option>
                                                    <option value="72">72x</option>
                                                    <option value="84">84x</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={`form-group ${errorMessage && 'is-invalid'}`}>
                                            <label htmlFor="selectReason">Qual o motivo?</label>
                                            <select
                                                className="form-control"
                                                id="selectReason"
                                                onChange={(e: any) => setReason(e.target.value)}
                                                value={reason}
                                            >
                                                {listReasons.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="invalid-feedback" style={{marginBottom: '1rem'}}>{errorMessage}</div>
                                        <button type="submit" className="btn btn-block btn-primary">SIMULAR AGORA</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home;
