import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { FaBars, FaCaretDown } from 'react-icons/fa';

import logoImg from '../../assets/logo/logo_header.svg';
import logoChristmasImg from '../../assets/doodles/logo-header-christmas.png';
import septemberYellowImg from '../../assets/icons/september-yellow.png';
import octoberPinkImg from '../../assets/icons/october-pink.png';
import novemberBlueImg from '../../assets/icons/november-blue.png';

import './styles.scss';
import { api } from '../../services/api';

type Props = {
  toggle: () => void;
  fgtsPartner?: string;
};

function Nav({ toggle, fgtsPartner = '' }: Props) {
  const { partnerParam: partnerParams }: any = useParams();
  const partnerParam = partnerParams ? partnerParams : fgtsPartner;
  const [partnerName, setPartnerName] = useState('');
  const [partnerLogo, setPartnerLogo] = useState('');
  const month = new Date().getMonth() + 1;
  let navClass = '';
  let logo = logoImg;
  let preventionLogo: any = null;
  const tanobolsoLogo = (
    <img
      src={logo}
      alt="tá no BOLSO logo"
      title="tá no BOLSO"
      className="logo-tanobolso"
    />
  );

  useEffect(() => {
    if (partnerParam) {
      requestPartner(partnerParam);
    }
  }, []);

  switch (month) {
    case 9: {
      navClass = 'septemberYellom';
      preventionLogo = (
        <img
          src={septemberYellowImg}
          alt="Setembro amarelo"
          className="preventionLogo"
        />
      );
      break;
    }
    case 10: {
      navClass = 'octoberPink';
      preventionLogo = (
        <img
          src={octoberPinkImg}
          alt="Outubro rosa"
          className="preventionLogo"
        />
      );
      break;
    }
    case 11: {
      navClass = 'novemberBlue';
      preventionLogo = (
        <img
          src={novemberBlueImg}
          alt="Novembro azul"
          className="preventionLogo"
        />
      );
      break;
    }
    case 12: {
      logo = logoChristmasImg;
      break;
    }
  }

  async function requestPartner(reqPartner: string) {
    const { data } = await api.get(
      `/partners/name/${reqPartner.toLowerCase()}`,
    );

    if (data) {
      const partnerLogoUrl = data.logo
        ? `${api.defaults.baseURL}/img/partner/${data.logo}`
        : '';
      setPartnerName(data.friendlyName);

      setPartnerLogo(partnerLogoUrl);
    } else {
      setPartnerName('');
      setPartnerLogo('');
    }
  }

  if (!partnerParam) {
    return (
      <nav>
        <div className="navbar-container">
          <Link to="/" className="nav-logo">
            <img
              src={logo}
              alt="tá no BOLSO logo"
              title="tá no BOLSO"
              className="logo-default"
            />
            {preventionLogo}
          </Link>
          <div className={`nav-mobile-icon ${navClass}`} onClick={toggle}>
            <FaBars />
          </div>
          <>
            <ul className={`nav-menu ${navClass}`}>
              <li className="subnav">
                <span>
                  Produtos <FaCaretDown />
                </span>
                <div className="subnav-content">
                  <a href="https://conteudo.qualitydigital.global/8dh-lp-chat-commerce-ta-no-bolso#rd-section-joq3m2vm" target="_blank">
                    Chat-Commerce
                  </a>
                  <Link to="/fgts-pay-banner" target="_blank">
                    FGTS PAY
                  </Link>
                  <Link to="/fgts">Saque-Aniversário FGTS</Link>
                </div>
              </li>
              <li>
                <LinkS
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Quem somos
                </LinkS>
              </li>
              <li>
                <LinkS
                  to="faq"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Perguntas frequentes
                </LinkS>
              </li>
              <li>
                <LinkS
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Contato
                </LinkS>
              </li>
              <li>
                <Link to="/#blog">Blog</Link>
              </li>
            </ul>
            <div className="nav-buttons" onClick={toggle}>
              <Link to="/signin">Entrar</Link>
              <Link to="/signup">Registrar</Link>
            </div>
          </>
        </div>
      </nav>
    );
  }

  if (partnerParam === 'volus') {
    return (
      <nav>
        <div className="navbar-container partner-fgts">
          <Link to={`/fgts/parceiro/${partnerParam}`} className="nav-logo">
            <div className="div-logo-volus">
              <img
                src={partnerLogo}
                alt="tá no BOLSO logo"
                title={`${partnerName}`}
                className="logo-default partner-logo"
              />
              <p>
                Este é um serviço oferecido e administrado pela Tá No Bolso. Uma
                parceria para os clientes Vólus.
              </p>
            </div>
            {tanobolsoLogo}
          </Link>
          {/* <div className={`nav-mobile-icon ${navClass}`} onClick={toggle}>
            <FaBars />
          </div> */}
          <>
            <ul className={`nav-menu ${navClass}`}>
              <li>
                <LinkS
                  to="simulate-card"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  <span>ANTECIPE SEU FGTS</span>
                </LinkS>
              </li>
              <li>
                <LinkS
                  to="faq"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Perguntas Frequentes
                </LinkS>
              </li>
              {/* <li>
                  <Link to={`/signin/${partnerParam}`}>Área do Cliente</Link>
                </li> */}
            </ul>
          </>
        </div>
      </nav>
    );
  }

  return (
    <nav>
      <div className="navbar-container partner-fgts">
        <Link to={`/fgts/parceiro/${partnerParam}`} className="nav-logo">
          <div className="div-logo">
            <img
              src={partnerLogo}
              alt="tá no BOLSO logo"
              title={`${partnerName}`}
              className="logo-default partner-logo"
            />
            <span>Em parceria com a Tá no Bolso</span>
          </div>
          {preventionLogo}
        </Link>
        {/* <div className={`nav-mobile-icon ${navClass}`} onClick={toggle}>
          <FaBars />
        </div> */}
        <>
          <ul className={`nav-menu ${navClass}`}>
            <li>
              <LinkS
                to="simulate-card"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                <span>ANTECIPE SEU FGTS</span>
              </LinkS>
            </li>
            <li>
              <LinkS
                to="faq"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Perguntas Frequentes
              </LinkS>
            </li>
            {/* <li>
                <Link to={`/signin/${partnerParam}`}>Área do Cliente</Link>
              </li> */}
          </ul>
        </>
      </div>
    </nav>
  );
}

export default Nav;
