import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Fgts from '../pages/Fgts';
import Consignado from '../pages/Consignado';
import Portabilidade from '../pages/Portabilidade';
import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfUse from '../pages/TermsOfUse';
import EmailConfirmation from '../pages/EmailConfirmation';
import FgtsPayPage from '../pages/fgts-pay/pages/home/index';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import Sidebar from '../components/Sidebar';
import AuthRoutes from './AuthRoutes';
import Redirect from '../pages/Redirect';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import ResendEmail from '../pages/ResendEmail';
import { LandingPageProvider } from '../contexts/LandingPageContext';
import { getApp } from '../utils/helpers';
import PageNotFound from '../pages/NotFound';
import FgtsPayTermsAndConditions from '../pages/fgts-pay/pages/terms-and-conditions';
import SignInAncar from '../pages/SignIn/Ancar';
import ReCaptcha from '../pages/ChatCommerce/ReCaptcha';
import FgtsPayBanner from '../assets/banner-pages/fgts_pay_v2.jpg';
import ChatCommerceBanner from '../assets/banner-pages/chat_commerce.jpg';

export default function Routes() {
  const currentApp = getApp();
  const partnerParam = currentApp;
  const [isOpen, setIsOpen] = useState(false);

  function toggleNavMobile() {
    setIsOpen(!isOpen);
  }

  return (
    <LandingPageProvider>
      <Switch>
        <Route path="/home" exact>
          <Sidebar isOpen={isOpen} toggle={toggleNavMobile} />
          <Nav toggle={toggleNavMobile} />
          <Home />
          <Footer />
        </Route>
        <Route path="/redirect/:type" exact>
          <Redirect />
        </Route>
        <Route
          path={[
            '/',
            '/fgts',
            '/fgts/parceiro/:partnerParam',
            '/fgts/parceiro/:partnerParam/:cpfParam/:bankNumberParam/:agencyParam/:accountNumberParam',
            '/fgts/parceiro/:partnerParam/:cpfParam/:accountNumberParam',
            '/fgts/parceiro/:partnerParam/:cpfParam',
          ]}
          exact
        >
          <Sidebar
            isOpen={isOpen}
            toggle={toggleNavMobile}
            partnerParam={partnerParam}
          />
          <Nav toggle={toggleNavMobile} fgtsPartner={partnerParam} />
          <Fgts fgtsPartner={partnerParam} />
          <Footer fgtsPartner={partnerParam} />
        </Route>
        <Route path={['/fgts-pay']} exact>
          <FgtsPayPage />
        </Route>
        <Route path={['/fgts-pay/:proposalUuid']} exact>
          <FgtsPayPage />
        </Route>
        <Route path={['/terms-and-conditions-fgts-pay']} exact>
          <FgtsPayTermsAndConditions />
        </Route>
        <Route path={['/consignado', '/simulator/:step/:proposalId']} exact>
          <Sidebar isOpen={isOpen} toggle={toggleNavMobile} />
          <Nav toggle={toggleNavMobile} />
          <Consignado />
          <Footer />
        </Route>
        <Route path="/portabilidade" exact>
          <Sidebar isOpen={isOpen} toggle={toggleNavMobile} />
          <Nav toggle={toggleNavMobile} />
          <Portabilidade />
          <Footer />
        </Route>
        <Route path="/privacy-policy" exact>
          <Sidebar isOpen={isOpen} toggle={toggleNavMobile} />
          <Nav toggle={toggleNavMobile} />
          <PrivacyPolicy />
          <Footer />
        </Route>
        <Route path="/terms-of-use" exact>
          <Sidebar isOpen={isOpen} toggle={toggleNavMobile} />
          <Nav toggle={toggleNavMobile} />
          <TermsOfUse />
          <Footer />
        </Route>
        <Route path={['/signin/ancar']} component={SignInAncar} exact />
        <Route
          path={['/signin', '/signin/:partner']}
          component={SignIn}
          exact
        />
        <Route
          path={['/signup', '/signup/:partner']}
          component={SignUp}
          exact
        />
        <Route path="/recover-password" component={RecoverPassword} exact />
        <Route
          path="/resend-confirmation-email"
          component={ResendEmail}
          exact
        />
        <Route path="/reset-password/:token" component={ResetPassword} exact />
        <Route path="/email-confirmation/:token" exact>
          <Sidebar isOpen={isOpen} toggle={toggleNavMobile} />
          <Nav toggle={toggleNavMobile} />
          <EmailConfirmation />
          <Footer />
        </Route>
        <Route
          path="/fgts-pay-banner"
          component={() => {
            return <img src={FgtsPayBanner} alt="FGTS PAY" />;
          }}
        />
        <Route
          path="/chat-commerce-banner"
          component={() => {
            return <img src={ChatCommerceBanner} alt="Chat-Commerce" />;
          }}
        />
        <Route
          path={['/chat-commerce/recaptcha/:orderUuid/:key/:version']}
          component={ReCaptcha}
          exact
        />
        <AuthRoutes />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </LandingPageProvider>
  );
}
