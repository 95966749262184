import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';

import './styles.scss';

function TermsOfUse() {
    useEffect(() => {
        scroller.scrollTo(
            'terms-of-use',
            {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -80,
            }
        );
    }, []);

    return (
        <div className="container terms-of-use" id='terms-of-use'>
            <div className="row">
                <div className="col-12">
                    <h1>Termos e condições de uso</h1>
                    <section className='header'>
                        <p>
                            A seguir apresentamos a você os Termos e Condições de Uso, documento que relaciona as principais regras que devem ser observadas por todos que acessam a PLATAFORMA da Tá no Bolso.
                            Como condição para acesso e uso das funcionalidades exclusivas da PLATAFORMA, sobretudo a contratação dos serviços oferecidos, o USUÁRIO declara que fez a leitura completa e atenta das regras deste documento e de sua Política de Privacidade, estando plenamente ciente e de acordo com elas.
                        </p>
                    </section>

                    <section>
                        <h3>1. Funcionalidades da Plataforma da Tá no Bolso</h3>
                        <p><strong>1.1.</strong> Para utilizar as funcionalidades da Plataforma da Tá no Bolso, o USUÁRIO deverá criar uma Conta de Acesso e ser maior de 18 (dezoito) anos e juridicamente capaz.</p>
                        <ul>
                            <li><strong>1.1.1.</strong> A Tá no Bolso poderá recusar ou não aprovar solicitações de cadastros realizadas por USUÁRIOS que não fornecerem dados corretos ou não enviarem os documentos solicitados.</li>
                        </ul>
                        <p><strong>1.2.</strong> A Tá no Bolso poderá alterar os critérios de elegibilidade de acesso e utilização da Plataforma Tá no Bolso a qualquer momento, sem que para isso tenha de fazer qualquer tipo de comunicação ou aviso prévio aos USUÁRIOS.</p>
                        <p><strong>1.3.</strong> A Tá no Bolso possui a faculdade de se recusar a conceder ou cancelar a Conta de Acesso de qualquer USUÁRIO que a utilizar de forma fraudulenta, violar ou tentar violar os presentes Termos e Condições de Uso, a Política de Privacidade, outras políticas ou qualquer documento legal na plataforma da Tá no Bolso.</p>
                        <p><strong>1.4.</strong> Para criar uma Conta de Acesso na plataforma é necessário que o USUÁRIO envie seus dados, documentos e registre sua biometria facial por meio da câmera de seu dispositivo móvel.</p>
                        <p><strong>1.5.</strong> A Plataforma da Tá no Bolso permite realizar simulações de crédito, de acordo com o perfil do USUÁRIO.</p>
                        <ul>
                            <li><strong>1.5.1.</strong> Para contratar um produto ou serviço bancário disponibilizado na plataforma Tá no Bolso, o USUÁRIO deverá firmar contrato específico com a Instituição Financeira responsável pelo produto/serviço de interesse, que estará disponível na própria plataforma.</li>
                        </ul>
                    </section>

                    <section>
                        <h3>2. Responsabilidades e Obrigações dos Usuários</h3>
                        <p><strong>2.1.</strong> Os USUÁRIOS são responsáveis e se obrigam a:</p>
                        <ul>
                            <li><strong>2.1.1.</strong> Utilizar com retidão e de forma ética a PLATAFORMA respeitando as condições que regem a sua utilização e finalidade.</li>
                            <li><strong>2.1.2.</strong> Fornecer dados cadastrais corretos, completos e atualizados, além de informar o canal de contato apto a ser acionado pela Tá no Bolso para o melhor cumprimento dos serviços. Se a qualquer momento for constatado que o USUÁRIO forneceu dados falsos ou não condizentes com a realidade, a Tá no Bolso se reserva ao direito de suspender ou cancelar sua Conta de Acesso, sem prejuízo de adotar as medidas que entender cabíveis.</li>
                            <li><strong>2.1.3.</strong> Manter o sigilo dos dados de sua Conta de Acesso em relação a terceiros e utilizá-la de modo individual e intransferível, não disponibilizando, divulgando ou compartilhando sua senha ou qualquer mecanismo de autenticação com quem quer que seja. Caso suspeite que a confidencialidade de sua senha foi violada, o USUÁRIO deverá proceder sua troca ou atualização o mais rápido possível ou contar os canais de atendimento disponíveis.</li>
                            <li><strong>2.1.4.</strong> Adotar senha de acesso, forte com no mínimo 6 e no máximo 8 dígitos, sem sequências obvias de fácil dedução. Em adição, o USUÁRIO não deve reutilizar sua senha em aplicativos, portais e sites de terceiros.</li>
                            <li><strong>2.1.5.</strong> Reconhecer que todos os acessos e operações realizadas após sua autenticação digital bem-sucedida são interpretados como sendo de sua responsabilidade de forma incontestável, inclusive aqueles derivados de uso indevido ou divulgação desta para terceiros.</li>
                            <li><strong>2.1.6.</strong> Deixar seus sistemas de anti-spam, filtros similares ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento dos comunicados e materiais da PLATAFORMA, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados. A Tá no Bolso não envia e-mails, SMS ou outros tipos de comunicação com links que não sejam do domínio tanobolso.com.br, desta forma, caso o USUÁRIO receba alguma mensagem desse tipo, deve estar ciente dos riscos ao clicar no link, pois pode ser tentativa de fraude conhecida como phishing.</li>
                            <li><strong>2.1.7.</strong> Respeitar todos os direitos de propriedade intelectual de titularidade da Tá no Bolso, incluindo todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma ali disponíveis. Da mesma forma, os USUÁRIOS somente poderão reproduzir os conteúdos disponíveis na PLATAFORMA, em especial suas marcas e layout do ambiente, caso tenham sido expressamente autorizados pela Tá no bolso neste sentido.</li>
                            <li><strong>2.1.8.</strong> Não acessar áreas de programação da Plataforma da Tá no Bolso, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes.</li>
                            <li><strong>2.1.9.</strong> Não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das funcionalidades da Plataforma da Tá no Bolso.</li>
                            <li><strong>2.1.10.</strong> Não utilizar softwares spider ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado que atue de modo similar.</li>
                        </ul>
                        <p><strong>2.2.</strong> As funcionalidades que compõem a PLATAFORMA da Tá no Bolso são oferecidas na forma de prestação de serviço, não conferindo ao USUÁRIO nenhum direito sobre o software utilizado ou sobre suas estruturas de informática que sustentam a plataforma da Tá no Bolso.</p>
                        <p><strong>2.3.</strong> A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade da Plataforma Tá no Bolso em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais, como reconhecimento de culpa ou de qualquer infração pela Tá no Bolso a direito de terceiro.</p>
                        <p><strong>2.4.</strong> Na incidência de danos à Tá no bolso ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos praticados por meio de sua Conta de Acesso, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo a exclusão da Tá no Bolso, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-a livre de prejuízos e ônus.</p>
                        <ul>
                            <li><strong>2.4.1.</strong> O descumprimento de quaisquer das obrigações aqui estipuladas poderá acarretar na suspensão total ou parcial das funcionalidades, ou exclusão da Conta de Acesso, sem aviso prévio, conforme previsto neste documento.</li>
                        </ul>
                    </section>

                    <section>
                        <h3>3. Isenção e Limitações de Responsabilidade</h3>
                        <p><strong>3.1.</strong> A Plataforma da Tá no Bolso e suas funcionalidades são apresentadas aos USUÁRIOS na maneira como estão disponíveis, podendo passar por constantes aprimoramentos e atualizações, obrigando-se a Tá No Bolso a:</p>
                        <ul>
                            <li><strong>a)</strong> preservar a sua funcionalidade, com links não quebrados e utilizando layout que respeita a usabilidade e navegabilidade, sempre que possível;</li>
                            <li><strong>b)</strong> exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas;</li>
                            <li><strong>c)</strong> proteger, por meio do estado da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas.</li>
                        </ul>
                        <p><strong>3.2.</strong> A Tá no envida seus esforços para a manutenção da disponibilidade contínua e permanente da Plataforma que, no entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à Internet ou fatos de terceiro que fogem de sua esfera de vigilância e responsabilidade.</p>
                        <ul>
                            <li><strong>3.2.1.</strong> Se isso ocorrer, a Tá no Bolso fará o que estiver ao seu alcance para restabelecer o acesso à Plataforma o mais breve possível, dentro das limitações técnicas de seus serviços e serviços de terceiros, dos quais depende para ficar online.</li>
                            <li><strong>3.2.2.</strong> Eventuais procedimentos de manutenção que acarretem a indisponibilidade da plataforma por longos períodos serão informados por meio dos canais oficiais de comunicação, como e-mails, perfis oficiais em mídias sociais ou telefone de atendimento.</li>
                        </ul>
                        <p><strong>3.3.</strong> O USUÁRIO não possui qualquer direito para exigir a disponibilidade da Plataforma tampouco poderá pleitear indenização ou reparação de danos em caso da plataforma permanecer fora do ar, independente da motivação.</p>
                        <p><strong>3.4.</strong> A Tá no Bolso não se responsabiliza:</p>
                        <ul>
                            <li><strong>3.4.1.</strong> Por quaisquer problemas, bugs, glitches ou funcionamentos indevidos que ocorrerem nos dispositivos e equipamentos dos USUÁRIOS e sejam resultantes direta ou indiretamente do uso regular da plataforma.</li>
                            <li><strong>3.4.2.</strong> Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando a ataque de hackers, falhas no sistema, no servidor ou na conexão à internet, inclusive por ações de softwares que possam, de algum modo, danificar ativos físicos ou lógicos ou a conexão dos USUÁRIOS em decorrência do acesso, utilização ou navegação na Plataforma, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos neste.</li>
                            <li><strong>3.4.3.</strong> Pela navegação dos USUÁRIOS nos links externos disponibilizados na Plataforma, sendo seus deveres a leitura dos Termos e Condições de Uso e Política de Privacidade do recurso acessado e agir conforme o determinado.</li>
                            <li><strong>3.4.4.</strong> Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais links, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela visita de tais sites, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato.</li>
                        </ul>
                    </section>

                    <section>
                        <h3>4. Privacidade dos Usuários</h3>
                        <p><strong>4.1.</strong> A Política de Privacidade da Tá no Bolso, regula o tratamento dos dados coletados na PLATAFORMA Tá no Bolso, sendo parte integrante e inseparável dos presentes Termos e Condições de Uso e pode ser acessada pelo link encontrado em seu rodapé.</p>
                        <p><strong>4.2.</strong> Caso alguma disposição da Política de Privacidade conflite com qualquer outra do presente documento, deverá prevalecer o descrito na norma mais específica.</p>
                    </section>

                    <section>
                        <h3>5. Disposições Gerais</h3>
                        <p><strong>5.1.</strong> O atendimento aos USUÁRIOS poderá ser realizado por meio do canal de atendimento disponibilizado conforme a seguir, cujo horário de funcionamento é das 09:00 às 13:00 horas, das 14:00 às 18:00 horas, exceto feriados nacionais:</p>
                        <ul>
                            <li><strong>a)</strong> E-mail: atendimento@tanobolso.com.br</li>
                        </ul>
                        <p><strong>5.2.</strong> Os USUÁRIOS poderão se valer dos canais de atendimento toda vez que presenciarem ou verificarem conteúdo impróprio na PLATAFORMA, seja ele notadamente ilícito ou contrário às regras de uso aqui estipuladas.</p>
                        <p><strong>5.3.</strong> Os presentes Termos e Condições de Uso estão sujeitos a constante melhoria e aprimoramento. Assim, a Tá no Bolso se reserva o direito de modificá-los a qualquer momento, conforme sua finalidade ou conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo aos USUÁRIOS verificá-lo sempre que efetuar o acesso na nossa Plataforma.</p>
                        <p><strong>5.4.</strong> Ao navegar pela Plataforma e utilizar suas funcionalidades, o USUÁRIO aceita guiar-se pelos Termos e Condições de Uso e pela Política de Privacidade que se encontram vigentes na data de acesso. Por isso, é recomendável que o USUÁRIO se mantenha atualizado.</p>
                        <p><strong>5.5.</strong> A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.</p>
                        <p><strong>5.6.</strong> O USUÁRIO reconhece que toda comunicação realizada por e-mail (ao endereço de e-mail informado no seu cadastro) é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos serviços objeto deste contrato, bem como às condições de sua prestação ou a qualquer outro assunto nele abordado, ressalvadas as disposições expressamente diversas previstas nestes Termos e Condições de Uso.</p>
                        <p><strong>5.7.</strong> A Plataforma Tá no Bolso tem como base a data e horários oficiais de Brasília.</p>
                    </section>

                    <section>
                        <h3>6. Lei Aplicável e Jurisdição</h3>
                        <p><strong>6.1.</strong> Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca do USUÁRIO para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</p>
                    </section>

                    <section>
                        <h3>7. Glossário</h3>
                        <p><strong>7.1.</strong> Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:</p>
                        <ul>
                            <li><strong>Anti-Spam:</strong> Sistema que evita correspondências não desejadas, como publicidade em massa, pelo bloqueio de mensagens ou as movendo para pasta específica.</li>
                            <li><strong>Conta de Acesso:</strong> Credencial de um USUÁRIO cadastrado que permite o acesso às funcionalidades exclusivas da Plataforma, que é definida pelo nome de usuário e senha.</li>
                            <li><strong>Crawler/Spider:</strong> Programas desenvolvidos para atuar ou obter informações de modo automatizado em sites.</li>
                            <li><strong>Layout:</strong> Conjunto compreendido entre aparência, design e fluxos da Plataforma Tá no Bolso.</li>
                            <li><strong>Link:</strong> Terminologia para endereço de internet.</li>
                            <li><strong>USUÁRIO:</strong> Pessoa Física que acessa ou interage com as atividades oferecidas pela Plataforma.</li>
                        </ul>
                    </section>

                    <section className='footer'>
                        <h5><strong>Atualização:</strong> 20 de julho de 2021.</h5>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default TermsOfUse;
