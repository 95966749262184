import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { api } from '../../../../services/api';
import Layout from '../../../../components/Admin/Layout';
import { LocationStateType } from '../Create';
import { FiChevronLeft } from 'react-icons/fi';
import imageLogo from '../../../../assets/logo/logo_header.svg';
import './styles.scss';

export type ParamsType = {
  proposalUuid: string;
};

const Concluded = ({integrationName}) => {
  const { proposalUuid } = useParams<ParamsType>();
  const location = useLocation();
  const locationState = location.state as LocationStateType;
  const [linkDigitalAuthorization, setLinkDigitalAuthorization] =
    useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const requestDigitalAuthorization = async () => {
    setLoading(true);

    const { data } = await api.get(`/fgts/digital-authorization/${proposalUuid}`);

    if (!!data.linkEncurtado) {
      setLinkDigitalAuthorization(data.linkEncurtado);
      setLoading(false);
    } else {
      await delay(15000);
      await requestDigitalAuthorization();
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(linkDigitalAuthorization);
    alert('Link copiado com sucesso.');
  };

  useEffect(() => {
    if (integrationName === 'Mercantil') {
      requestDigitalAuthorization();
    }
  }, []);

  if (integrationName === 'Mercantil') {
    return (
      <Layout
        pageBreadcrumb={`Criar proposta - ${locationState.name} (${locationState.cpf})`}
        pageTitle="Habilitar banco"
      >
        <div>
          <h3 style={{ paddingBottom: '20px' }}>
            Proposta incluída com sucesso, segue link das autorizações digitais
            para enviar para o cliente.
          </h3>
          <div className="form-row">
            {loading ? (
              <p>Gerando link, isso pode demorar alguns segundos, aguarde...</p>
            ) : (
              <>
                <div className="form-group col-md-12">
                  <input
                    className="form-control"
                    placeholder=""
                    value={linkDigitalAuthorization}
                  />
                </div>
                {/* <button className="btn btn-primary" onClick={copyLink}>
                Copiar link
              </button> */}
              </>
            )}
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <>
        <p className="concluded-back-home-link">
          <FiChevronLeft className="left-chevron" />
          <Link to="/admin/home">Voltar</Link>
        </p>
        <div className="modal-body concluded">
          <div className="container info-concluded">
            <h3>Parabéns! Sua solicitação foi concluída.</h3>
            <p>
              {locationState.message}
            </p>
            <h4>Obrigado!</h4>
            <div className="logo-container-concluded-admin">
              <img
                className="backgroundLogo"
                src={imageLogo}
                alt="Ta no bolso"
                height="80"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer"></div>
      </>
    );
  }
};

export default Concluded;
