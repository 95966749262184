import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';

const nationalityList = [
  {
    id: '',
    name: 'Selecione...',
  },
  {
    id: 'Brazilian',
    name: 'Brasileiro',
  },
  {
    id: 'Foreign',
    name: 'Estrangeiro',
  },
];

const genderList = [
  {
    id: '',
    name: 'Selecione...',
  },
  {
    id: 'Masculine',
    name: 'Masculino',
  },
  {
    id: 'Feminine',
    name: 'Feminino',
  },
];

const civilStatusList = [
  {
    id: '',
    name: 'Selecione...',
  },
  {
    id: 'Single',
    name: 'Solteiro(a)',
  },
  {
    id: 'Married',
    name: 'Casado(a)',
  },
  {
    id: 'Divorced',
    name: 'Divorciado(a)',
  },
  {
    id: 'Widowed',
    name: 'Viúvo(a)',
  },
];

const ufJson = require('../../../../../json/uf.json');

const ufList = [
  {
    id: '',
    sigla: 'Selecione...',
  },
  ...ufJson,
];

const documentTypeList = [
  {
    id: 'Cnh',
    name: 'CNH',
  },
  {
    id: 'RG',
    name: 'RG',
  },
];

const ComplementaryData = ({
  handleNext,
  stepNumber,
  haveError,
  cpf,
  setCpf,
  email,
  setEmail,
  cellPhoneNumber,
  setCellPhoneNumber,
  incomeValue,
  setIncomeValue,
  occupation,
  setOccupation,
  nationality,
  setNationality,
  gender,
  setGender,
  civilStatus,
  setCivilStatus,
  motherName,
  setMotherName,
  postalCode,
  setPostalCode,
  address,
  setAddress,
  streetNumber,
  setStreetNumber,
  neighborhood,
  setNeighborhood,
  city,
  setCity,
  uf,
  setUf,
  customerAlreadyExists,
  pixType,
  handlePixTypeChange,
  pixValue,
  handlePixValueChange,
  setDocumentType,
  documentType,
  documentNumber,
  setDocumentNumber,
  documentIssueDate,
  setDocumentIssueDate,
  documentIssuingAgency,
  setDocumentIssuingAgency,
  documentState,
  setDocumentState,
  documentExpirationDate,
  setDocumentExpirationDate,
  statusMessageAlert,
  handlePrevious,
}) => {
  const { register } = useForm();
  const [CepLoading, setCepLoading] = useState<boolean>(false);

  async function getCep(cep: string) {
    if (!cep || cep.length < 8) return;

    setCepLoading(true);

    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      setAddress(data.logradouro);
      setNeighborhood(data.bairro);
      setCity(data.localidade);
      setUf(data.uf);
    } catch (error) {
      console.log(error);
    }
    setCepLoading(false);
  }

  function onChangeDocumentType(documentType: string) {
    setDocumentType(documentType);
    setDocumentIssuingAgency('');

    if (documentType === 'Cnh') {
      setDocumentIssuingAgency('DETRAN');
    }
  }

  return (
    <>
      <div className="modal-body customer-data data-fields">
        <h3>Agora precisamos de alguns dados seus.</h3>
        <div className="container">
          <div className="row">
            <div></div>
          </div>
          <form onSubmit={handleNext} className="form-complementary-data">
            {stepNumber === 1 && (
              <>
                <h4>Dados pessoais</h4>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>CPF *</label>
                    <InputMask
                      mask="999.999.999-99"
                      className={`form-control ${
                        haveError && !cpf && 'is-invalid'
                      }`}
                      autoComplete="off"
                      placeholder=""
                      value={cpf}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCpf(e.target.value)
                      }
                    />
                    <div className="invalid-feedback">
                      {haveError && !cpf && 'Por favor digite seu CPF.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>E-mail *</label>
                    <input
                      className={`form-control ${
                        haveError && !email && 'is-invalid'
                      }`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                      value={email}
                    />
                    <div className="invalid-feedback">
                      {haveError && !email && 'Por favor digite seu e-mail.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Celular (com WhatsApp) *</label>
                    <InputMask
                      mask="(99) 99999-9999"
                      className={`form-control ${
                        haveError && !cellPhoneNumber && 'is-invalid'
                      }`}
                      autoComplete="off"
                      placeholder=""
                      value={cellPhoneNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCellPhoneNumber(e.target.value)
                      }
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !cellPhoneNumber &&
                        'Por favor digite seu celular.'}
                    </div>
                  </div>
                  {/* <div className="form-group col-md-4">
                    <label>Sua renda *</label>
                    <CurrencyInput
                      className={`form-control ${
                        haveError &&
                        (!incomeValue || incomeValue === '0') &&
                        'is-invalid'
                      }`}
                      id="inputValue"
                      onValueChange={(value) => setIncomeValue(value!)}
                      value={incomeValue}
                      prefix={'R$ '}
                      decimalsLimit={2}
                      decimalSeparator={','}
                      groupSeparator={'.'}
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        (!incomeValue || incomeValue === '0') &&
                        'Por favor informe a sua renda.'}
                    </div>
                  </div> */}
                  {/* <div className="form-group col-md-4">
                    <label>Sua profissão *</label>
                    <input
                      className={`form-control ${
                        haveError && !occupation && 'is-invalid'
                      }`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setOccupation(e.target.value)
                      }
                      value={occupation}
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !occupation &&
                        'Por favor informe a sua profissão.'}
                    </div>
                  </div> */}
                  <div className="form-group col-md-4">
                    <label>Sexo *</label>
                    <select
                      className={`form-control ${
                        haveError && !gender && 'is-invalid'
                      }`}
                      value={gender}
                      onChange={(e: any) => setGender(e.target.value)}
                    >
                      {genderList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {haveError &&
                        !gender &&
                        'Por favor selecione uma das opções.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Nacionalidade *</label>
                    <select
                      className={`form-control ${
                        haveError && !nationality && 'is-invalid'
                      }`}
                      value={nationality}
                      onChange={(e: any) => setNationality(e.target.value)}
                    >
                      {nationalityList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {haveError &&
                        !nationality &&
                        'Por favor selecione uma das opções.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Estado Civil *</label>
                    <select
                      className={`form-control ${
                        haveError && !civilStatus && 'is-invalid'
                      }`}
                      value={civilStatus}
                      onChange={(e: any) => setCivilStatus(e.target.value)}
                    >
                      {civilStatusList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {haveError &&
                        !civilStatus &&
                        'Por favor selecione uma das opções.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Nome completo da mãe *</label>
                    <input
                      className={`form-control ${
                        haveError && !motherName && 'is-invalid'
                      }`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setMotherName(e.target.value)
                      }
                      value={motherName}
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !motherName &&
                        'Por favor digite o número do documento.'}
                    </div>
                  </div>
                </div>
              </>
            )}
            {stepNumber === 2 && (
              <>
                <h4>Endereço</h4>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>CEP *</label>
                    <div className="input-group">
                      <InputMask
                        {...register('cep')}
                        mask="99.999-999"
                        className={`form-control ${
                          haveError && !postalCode && 'is-invalid'
                        }`}
                        autoComplete="off"
                        placeholder=""
                        value={postalCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const unmaskedCep = e.target.value.replace(/\D/g, '');
                          setPostalCode(e.target.value);
                          getCep(unmaskedCep);
                        }}
                      />
                      <div className="invalid-feedback">
                        {haveError && !postalCode && 'Por favor digite o CEP.'}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Avenida, rua etc *</label>
                    <input
                      {...register('address')}
                      className={`form-control ${
                        haveError && !address && 'is-invalid'
                      } street-info`}
                      value={CepLoading ? 'Buscando dados...' : address}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAddress(e.target.value)
                      }
                    />
                    <div className="invalid-feedback">
                      {haveError && !address && 'Por favor digite a rua.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>N° da residência *</label>
                    <input
                      {...register('streetNumber')}
                      className={`form-control ${
                        haveError && !streetNumber && 'is-invalid'
                      }`}
                      maxLength={6}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setStreetNumber(e.target.value)
                      }
                      placeholder=""
                      value={streetNumber}
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !streetNumber &&
                        'Por favor digite o número.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Bairro *</label>
                    <input
                      {...register('neighborhood')}
                      className={`form-control ${
                        haveError && !neighborhood && 'is-invalid'
                      }`}
                      value={neighborhood}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNeighborhood(e.target.value)
                      }
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !neighborhood &&
                        'Por favor digite o bairro.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Cidade *</label>
                    <input
                      {...register('city')}
                      className={`form-control ${
                        haveError && !city && 'is-invalid'
                      }`}
                      value={city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCity(e.target.value)
                      }
                    />
                    <div className="invalid-feedback">
                      {haveError && !city && 'Por favor digite a cidade.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Estado *</label>
                    <select
                      {...register('uf')}
                      className={`form-control ${
                        haveError && !uf && 'is-invalid'
                      }`}
                      value={uf}
                      onChange={(e: any) => setUf(e.target.value)}
                    >
                      {ufList.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.sigla}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {haveError && !uf && 'Por favor selecione o estado.'}
                    </div>
                  </div>
                </div>
              </>
            )}
            {stepNumber === 3 && (
              <>
                {customerAlreadyExists ? (
                  <h4>Confirme a chave PIX que será utilizada para o troco:</h4>
                ) : (
                  <h4>Informe a chave PIX que será utilizada para o troco:</h4>
                )}
                <div className="pix-container">
                  <div className="pix-radio-outter-container">
                    <div className="pix-radio-container">
                      <input
                        type="radio"
                        name="pixType"
                        value="CPF"
                        checked={pixType === 'CPF'}
                        onChange={handlePixTypeChange}
                      />
                      <label htmlFor="cpf">CPF</label>
                    </div>
                    <div className="pix-radio-container">
                      <input
                        type="radio"
                        name="pixType"
                        value="Email"
                        checked={pixType === 'Email'}
                        onChange={handlePixTypeChange}
                      />
                      <label htmlFor="email">E-mail</label>
                    </div>
                    <div className="pix-radio-container">
                      <input
                        type="radio"
                        name="pixType"
                        value="Cellphone"
                        checked={pixType === 'Cellphone'}
                        onChange={handlePixTypeChange}
                      />
                      <label htmlFor="cellphone">Celular</label>
                    </div>
                    <div className="pix-radio-container">
                      <input
                        type="radio"
                        name="pixType"
                        value="EVP"
                        checked={pixType === 'EVP'}
                        onChange={handlePixTypeChange}
                      />
                      <label htmlFor="cellphone">Chave aleatória</label>
                    </div>
                  </div>
                  <div className="pix-input-container">
                    {pixType === 'CPF' && (
                      <InputMask
                        mask="999.999.999-99"
                        className="pix-text-input"
                        autoComplete="off"
                        placeholder="Digite o CPF"
                        value={pixValue}
                        onChange={(e: any) => handlePixValueChange(e)}
                      />
                    )}
                    {pixType === 'Email' && (
                      <input
                        type="text"
                        className="pix-text-input"
                        placeholder="Digite o e-mail"
                        value={pixValue}
                        onChange={(e: any) => handlePixValueChange(e)}
                      />
                    )}
                    {pixType === 'Cellphone' && (
                      <InputMask
                        mask="(99) 99999-9999"
                        className="pix-text-input"
                        autoComplete="off"
                        placeholder="Digite o n° do celular"
                        value={pixValue}
                        onChange={(e: any) => handlePixValueChange(e)}
                      />
                    )}
                    {pixType === 'EVP' && (
                      <input
                        type="text"
                        className="pix-text-input"
                        placeholder="Digite a chave aleatória"
                        value={pixValue}
                        onChange={(e: any) => handlePixValueChange(e)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {stepNumber === 4 && (
              <>
                <h4>Documento de identificação</h4>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Tipo de documento de identificação *</label>
                    <select
                      className={`form-control ${
                        haveError && !documentType && 'is-invalid'
                      }`}
                      value={documentType}
                      onChange={(e: any) =>
                        onChangeDocumentType(e.target.value)
                      }
                    >
                      {documentTypeList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {haveError &&
                        !documentType &&
                        'Por favor selecione o tipo de documento de identificação.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Número do documento *</label>
                    <input
                      className={`form-control ${
                        haveError && !documentNumber && 'is-invalid'
                      }`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setDocumentNumber(e.target.value)
                      }
                      value={documentNumber}
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !documentNumber &&
                        'Por favor digite o número do documento.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Data de emissão *</label>
                    <InputMask
                      mask="99/99/9999"
                      className={`form-control ${
                        haveError && !documentIssueDate && 'is-invalid'
                      }`}
                      autoComplete="off"
                      placeholder=""
                      value={documentIssueDate}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setDocumentIssueDate(e.target.value)
                      }
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !documentIssueDate &&
                        'Por favor digite a data de emissão.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Órgão emissor *</label>
                    <input
                      className={`form-control ${
                        haveError && !documentIssuingAgency && 'is-invalid'
                      }`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setDocumentIssuingAgency(e.target.value)
                      }
                      value={documentIssuingAgency}
                    />
                    <div className="invalid-feedback">
                      {haveError &&
                        !documentIssuingAgency &&
                        'Por favor digite o órgão emissor.'}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label>UF do órgão emissor *</label>
                    <select
                      className={`form-control ${
                        haveError && !documentState && 'is-invalid'
                      }`}
                      value={documentState}
                      onChange={(e: any) => setDocumentState(e.target.value)}
                    >
                      {ufList.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.sigla}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {haveError &&
                        !documentState &&
                        'Por favor selecione a UF do órgão emissor.'}
                    </div>
                  </div>
                  {documentType === 'Cnh' ? (
                    <div className="form-group col-md-4">
                      <label>Data de vencimento *</label>
                      <InputMask
                        mask="99/99/9999"
                        className={`form-control ${
                          haveError && !documentExpirationDate && 'is-invalid'
                        }`}
                        autoComplete="off"
                        placeholder=""
                        value={documentExpirationDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setDocumentExpirationDate(e.target.value)
                        }
                      />
                      <div className="invalid-feedback">
                        {haveError &&
                          !documentExpirationDate &&
                          'Por favor digite a data de vencimento.'}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      {statusMessageAlert && (
        <div
          className={
            statusMessageAlert.status === 'error'
              ? 'alert alert-danger alert-text-center-fgts-pay-page-6'
              : 'alert alert-primary alert-text-center-fgts-pay-page-6'
          }
          role="alert"
        >
          {statusMessageAlert.message}
        </div>
      )}
      <div className="modal-footer data-fields-buttons-fgts-pay-page-6">
        {stepNumber === 1 || stepNumber === 2 || stepNumber === 4 ? (
          ''
        ) : customerAlreadyExists ? (
          ''
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={handlePrevious}
          >
            Voltar
          </button>
        )}
        <button type="button" className="btn btn-primary" onClick={handleNext}>
          Prosseguir
        </button>
      </div>
    </>
  );
};

export default ComplementaryData;
