import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { FiChevronLeft } from 'react-icons/fi';

import '../../styles/global.scss';
import './styles.scss';

import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/Button';

import logoImg from '../../assets/logo/logo_header.svg';

type StatusAlertType = {
  status: string;
  message: string;
};

function SignIn() {
  const { partner } = useParams();
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [cpf, setCpf] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { handleLogin, loading, user } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setCpf(process.env.REACT_APP_FAKER_CPF_FGTS);
    }
  }, []);

  async function handleSignIn() {
    const unmaskedCpf = cpf.replace(/[^\d]+/g, '');

    setStatusMessageAlert(null);

    if (unmaskedCpf === '' || password === '') {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha o cpf e senha para continuar.',
      });

      return;
    }

    const response = await handleLogin(unmaskedCpf, password);

    if (response) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Dados incorretos, favor verificar e tentar novamente.',
      });
    }
  }

  return (
    <div id="page-auth">
      <aside>
        <p className='back-home-link'>
          <FiChevronLeft className='left-chevron'/>
          <Link to="/">Voltar</Link>
        </p>
        <strong>Entrar</strong>
        <p>Aqui você consegue entrar na sua área logada.</p>
      </aside>
      <main>
        <div className="main-content">
          <div className="logoTnb">
            <img src={logoImg} alt="Ta no bolso" />
          </div>
          <form onSubmit={() => {}}>
            <h2>Login</h2>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>CPF</label>
                <InputMask
                  name="cpf"
                  mask="999.999.999-99"
                  className={`form-control`}
                  onChange={(e: any) => setCpf(e.target.value)}
                  onKeyDown={(e) => (e.key === 'Enter' ? handleSignIn() : '')}
                  autoComplete="off"
                  placeholder=""
                  value={cpf}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Senha</label>
                <input
                  name="password"
                  type="password"
                  className={`form-control`}
                  onChange={(e: any) => setPassword(e.target.value)}
                  onKeyDown={(e) => (e.key === 'Enter' ? handleSignIn() : '')}
                  value={password}
                />
              </div>
            </div>
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger alert-text-center'
                    : 'alert alert-primary alert-text-center'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
            <Button type="button" onClick={handleSignIn}>
              {!loading ? 'Entrar' : 'Entrando...'}
            </Button>
            <p>
              <Link to="/recover-password">Esqueceu a senha?</Link>
            </p>
            <p>
              <Link to="/resend-confirmation-email">
                Não recebeu o e-mail de confirmação?
              </Link>
            </p>
            <p>
              <Link to={partner ? `/signup/${partner}` : '/signup'}>
                Não tem conta? Registre-se.
              </Link>
            </p>
          </form>
        </div>
      </main>
    </div>
  );
}

export default SignIn;
