import React, { useState, FormEvent } from 'react';
import InputMask from "react-input-mask";

import bannerImg from '../../../assets/banner/portabilidade_g.jpg';
import arrowsImg from '../../../assets/icons/arrows.svg';

import classes from './styles.module.scss';

function Home() {
    const productId = process.env.REACT_APP_PRODUCT_ID_REFINANCIAMENTO;
    const [cpf, setCpf] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleSimulateWhatsApp = (event: FormEvent) => {
        event.preventDefault();

        setErrorMessage('');

        if (
            name === '' ||
            cpf === '' ||
            phone === '' ||
            email === ''
        ) {
            setErrorMessage('Você deve preencher todos os campos!');
            return;
        }

        const whatsAppPhone = '5521965773075';
        const text = `Tipo de simulação: Refinanciamento do consignado
        Nome: ${name}
        E-mail: ${email}
        CPF: ${cpf}
        Telefone: ${phone}`;

        window.open(`https://wa.me/${whatsAppPhone}?text=${text.replace(/  +/g, '').replace(/(?:\r\n|\r|\n)/g, '%0a').replace(/ /g, '%20')}`, '_blank');
    }

    return (
        <section className={classes.home} style={{backgroundImage: `url(${bannerImg})`}}>
            <div className="container">
                <div className="row justify-content-left">
                    <div className="col-lg-8 col-md-9 col-xs-12" id="simulate-card">
                        <div className={classes.card}>
                            <div className={classes.cardIcon}>
                                <div className={classes.roundIcon}>
                                    <img src={arrowsImg} alt="Imagem de mão com dinheiro" />
                                </div>
                            </div>
                            <div className={classes.cardBody}>
                                <h3 className={classes.cardTitle}>Refinanciamento!</h3>
                                <p className={classes.cardText}>
                                    Preencha seus dados para simular sem compromisso.
                                </p>
                                <div className={classes.cardForm}>
                                    <div className="row">
                                        <div className="col-xs-12 col-lg-6 form-group">
                                            <label htmlFor="inputName">Nome completo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputName"
                                                onChange={(e: any) => setName(e.target.value)}
                                                value={name}
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="col-xs-12 col-lg-6 form-group">
                                            <label htmlFor="inputEmail">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail"
                                                onChange={(e: any) => setEmail(e.target.value)}
                                                value={email}
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className={`row ${errorMessage && 'is-invalid'}`}>
                                        <div className="col form-group">
                                            <label htmlFor="inputCpf">CPF</label>
                                            <InputMask
                                                mask="999.999.999-99"
                                                className="form-control"
                                                id="inputCpf"
                                                onChange={(e: any) => setCpf(e.target.value)}
                                                value={cpf}
                                                placeholder=""
                                            />
                                            </div>
                                        <div className="col form-group">
                                            <label htmlFor="inputPhone">Celular</label>
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                className="form-control"
                                                id="inputPhone"
                                                onChange={(e: any) => setPhone(e.target.value)}
                                                value={phone}
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="invalid-feedback" style={{marginBottom: '1rem'}}>{errorMessage}</div>
                                    <button type="button" className="btn btn-block btn-primary" onClick={handleSimulateWhatsApp}>SIMULAR AGORA</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home;
