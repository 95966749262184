import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Link } from 'react-router-dom';

import { api } from '../../../../services/api';
import { useAuth } from '../../../../hooks/useAuth';

type EstimateType = {
    changePage: (pageName: string) => void;
    productName: string | null;
    setProposalId: Function;
}

type StatusAlertType = {
    status: string;
    message: ReactElement | string;
}

export default function Estimate({
    changePage,
    productName,
    setProposalId,
}: EstimateType) {
    const { user: { cpf, birthDate, name, phone, email } } = useAuth();
    const [calculated, setCalculated] = useState<boolean>(false);
    const [statusMessageAlert, setStatusMessageAlert] = useState<StatusAlertType | null>(null);
    const [value, setValue] = useState<string | number>('');
    const [calculatedValue, setCalculatedValue] = useState<string | number>('');

    useEffect(() => {
        if (!birthDate) {
            setStatusMessageAlert({
                status: 'error',
                message: (<>Você deve atualizar sua data de nascimento no menu <Link to="/admin/my-data">Meus dados</Link> para simular.</>),
            });
        }
    }, []);

    async function handleSimulate(event: FormEvent) {
        event.preventDefault();

        setStatusMessageAlert(null);

        if (
            value === ''
        ) {
            setStatusMessageAlert({
                status: 'error',
                message: 'Preencha todos os campos obrigatórios.',
            });
            return;
        }

        if (!birthDate) {
            setStatusMessageAlert({
                status: 'error',
                message: 'Você deve atualizar sua data de nascimento no menu "Meus dados".',
            });
            return;
        }

        const { data: integrationDefault } = await api.get(`/integration-product/product/name/${productName}/default`);

        if (!!integrationDefault === true) {
            setCalculated(false);
            setStatusMessageAlert({
                status: 'info',
                message: 'Só um momento, estamos calculando...',
            });

            try {
                const { product: { uuid: productUuid } } = integrationDefault;
                const { data } = await api.post('/mercantil/request-previous-simulation-fgts', {
                    name,
                    phone: phone.replace(/[^0-9]/g, ''),
                    cpf: cpf.replace(/[^0-9]/g, ''),
                    email,
                    birthDate: new Date(birthDate).toLocaleDateString("pt-BR"),
                    balance: +(value as string).replace('.', '').replace(',', '.'),
                    product: {
                        uuid: productUuid,
                    }
                });

                if (data.status === 'success') {
                    const regexMoney = /\d(?=(\d{3})+,)/g;
                    const { valorEmprestimo, proposalUuid } = data;
                    const formattedCalculatedValue = valorEmprestimo.toFixed(2).replace('.', ',').replace(regexMoney, '$&.');

                    setProposalId(proposalUuid);
                    setCalculatedValue(formattedCalculatedValue);
                    setStatusMessageAlert(null);
                    setCalculated(true);
                } else if (data.status === 'error') {
                    setStatusMessageAlert({
                        status: 'error',
                        message: data.message,
                    });
                } else {
                    setStatusMessageAlert({
                        status: 'error',
                        message: 'Ops, algo ocorreu, tente novamente.',
                    });
                }

            } catch (error) {
                setStatusMessageAlert({
                    status: 'error',
                    message: 'Ops, algo ocorreu, tente novamente.',
                });

            }
        } else {
            sendForWhatsApp();
        }
    }

    function sendForWhatsApp() {
        const whatsAppPhone = '5521965773075';
        const text = `Tipo de simulação: Antecipação Saque-Aniversário
        Nome: ${name}
        E-mail: ${email}
        CPF: ${cpf}
        Telefone: ${phone}
        Data de nasc.: ${birthDate}
        Saldo: ${value}`;

        window.open(`https://wa.me/${whatsAppPhone}?text=${text.replace(/  +/g, '').replace(/(?:\r\n|\r|\n)/g, '%0a').replace(/ /g, '%20')}`, '_blank');
    }

    return (
        <div className="estimate">
            <h3 style={{ paddingBottom: '20px' }}>O que acha de realizar uma estimativa? Preencha seu saldo e pressione simular:</h3>
            <form onSubmit={handleSimulate} style={{ paddingBottom: '30px' }}>
                <div className="row">
                    <div className="col-md-4 col-sm-12 form-group">
                        <label htmlFor="inputValue">Qual o seu saldo?*</label>
                        <CurrencyInput
                            className="form-control"
                            id="inputValue"
                            onValueChange={(value) => setValue(value)}
                            value={value}
                            prefix={'R$ '}
                            decimalsLimit={2}
                            decimalSeparator={','}
                            groupSeparator={'.'}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-block btn-primary">SIMULAR</button>
            </form>
            {statusMessageAlert && (
                <div className={statusMessageAlert.status === 'error' ? 'alert alert-danger alert-text-center' : 'alert alert-primary alert-text-center'} role="alert">{statusMessageAlert.message}</div>
            )}
            {calculated && (
                <div style={{ textAlign: 'center' }}>
                    <h4>Você pode receber até</h4>
                    <h3>R$ {calculatedValue}</h3>
                    <button type="button" className="btn btn-block btn-primary" style={{ marginTop: '15px' }} onClick={() => { changePage('Release'); }}>CONTRATAR AGORA</button>
                </div>
            )}
        </div>
    );
}
