import { FiLayers } from 'react-icons/fi';
import { BsPencil, BsAlarm } from 'react-icons/bs';
import { BiHappy } from 'react-icons/bi';

import './styles.scss';

type AboutMoreTypes = {
  partnerPage: boolean;
};

function AboutMore({ partnerPage = false }: AboutMoreTypes) {
    return (
        <section className={`new-about-more ${partnerPage ? 'partner-fgts' : ''}`} id="about-more">
            <div className={`container`}>
                <h5>E TEM MAIS</h5>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <h2>Vantagens de antecipar o saque-aniversário do FGTS</h2>
                    </div>
                </div>
                <div className="benefits-fgts">
                    <div className="row">
                        <div className="col-md-3">
                            <FiLayers className="icon" />
                            <h6>
                                Sem parcelas<br />
                                mensais
                            </h6>
                            <p>A taxa é descontada sobre o valor total recebido</p>
                        </div>
                        <div className="col-md-3">
                            <BsPencil className="icon" />
                            <h6>Contratação rápida e fácil</h6>
                            <p>É possível receber o dinheiro em sua conta em até 1 hora*</p>
                        </div>
                        <div className="col-md-3">
                            <BiHappy className="icon" />
                            <h6>Valor acessível a negativados</h6>
                            <p>Contratação sem consulta ao SPC e SERASA</p>
                        </div>
                        <div className="col-md-3">
                            <BsAlarm className="icon" />
                            <h6>
                                Sem<br />
                                espera
                            </h6>
                            <p>O saque-aniversário é liberado rapidamente e não é preciso esperar o mês do seu aniversário para fazer o resgate</p>
                        </div>
                    </div>
                </div>

                <p className="obs">* O tempo pode variar dependendo do valor.</p>
            </div>
        </section>
    )
}

export default AboutMore;
