import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input-field';

import Layout from '../../../../components/Admin/Layout';
import { api } from '../../../../services/api';
import { ParamsType } from '../AlertEnabledBank';

import './styles.scss';
import { LocationStateType } from '../Create';

const ufJson = require('../../../../json/uf.json');
const bankJson = require('../../../../json/banks.json');

const ufList = [
  {
    id: '',
    sigla: 'Selecione...',
  },
  ...ufJson,
];
const bankList = [
  {
    COMPE: '',
    LongName: 'Selecione...',
  },
  ...bankJson,
];
const documentTypeList = [
  {
    id: 'CNH',
    name: 'CNH',
  },
  {
    id: 'CTPS',
    name: 'Carteira de trabalho',
  },
  {
    id: 'RG',
    name: 'RG',
  },
];
const accountTypeList = [
  {
    id: '',
    name: 'Selecione...',
  },
  {
    id: 'IndividualCurrentAccount',
    name: 'Conta corrente',
  },
  {
    id: 'IndividualSavingsAccount',
    name: 'Conta poupança',
  },
];

const civilStatusList = [
  {
    id: '',
    name: 'Selecione...',
  },
  {
    id: 'Single',
    name: 'Solteiro(a)',
  },
  {
    id: 'Married',
    name: 'Casado(a)',
  },
  {
    id: 'Divorced',
    name: 'Divorciado(a)',
  },
  {
    id: 'Widowed',
    name: 'Viúvo(a)',
  },
  {
    id: 'Others',
    name: 'Outro',
  },
];

const nationalityList = [
  {
    id: '',
    name: 'Selecione...',
  },
  {
    id: 'Brazilian',
    name: 'Brasileiro',
  },
  {
    id: 'Foreign',
    name: 'Estrangeiro',
  },
];

type StatusAlertType = {
  status: string;
  message: string;
};

type bankInfo = {
  bankNumber: string;
  name: string;
};

const CustomerData = () => {
  let history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationStateType;
  const { proposalUuid } = useParams<ParamsType>();
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [haveError, setHaveError] = useState<boolean>(false);
  const [occupation, setOccupation] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [streetNumber, setStreetNumber] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [uf, setUf] = useState<string>('');
  const [motherName, setMotherName] = useState<string>('');
  const [nationality, setNationality] = useState<string>('');
  const [civilStatus, setCivilStatus] = useState<string>('');
  const [incomeValue, setIncomeValue] = useState<string>('0');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>(
    locationState.cellPhoneNumber,
  );
  const [email, setEmail] = useState<string>(locationState.email);
  const [cpf, setCpf] = useState<string>(locationState.cpf as string);
  const [documentType, setDocumentType] = useState<string>('RG');
  const [documentNumber, setDocumentNumber] = useState<string>('');
  const [documentState, setDocumentState] = useState<string>('');
  const [documentIssueDate, setDocumentIssueDate] = useState<string>('');
  const [documentIssuingAgency, setDocumentIssuingAgency] =
    useState<string>('');
  const [bank, setBank] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [digitAccount, setDigitAccount] = useState<string>('');
  const [agency, setAgency] = useState<string>('');
  const [bankAccountType, setBankAccountType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [CepLoading, setCepLoading] = useState<boolean>(false);
  const [bankNames, setBankNames] = useState<bankInfo[]>([]);
  const { register } = useForm();
  const [gender, setGender] = useState<string>('');

  useEffect(() => {
    if (proposalUuid) {
      getBanksList();
    }
  }, [proposalUuid]);

  async function getBanksList() {
    const data = {
      proposalUuid,
    };

    const response = await api.post('/fgts/banks', data);

    if (response.data.status === 'success') {
      setBankNames(response.data.banks);
    }
  }

  const bankList = [
    {
      bankNumber: '',
      name: 'Selecione...',
    },
    ...bankNames,
  ];

  async function getCep(cep: string) {
    if (!cep || cep.length < 8) return;

    setCepLoading(true);

    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      setAddress(data.logradouro);
      setNeighborhood(data.bairro);
      setCity(data.localidade);
      setUf(data.uf);
    } catch (error) {
      console.log(error);
    }
    setCepLoading(false);
  }

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setIncomeValue('10000');
      setPostalCode('88.161-789');
      setStreetNumber('1234');
      setDocumentNumber('1234');
      setDocumentIssueDate('01/01/2021');
      setDocumentState('SC');
      setDocumentIssuingAgency('SSP');
      setAgency('1234');
      setAccount('1234');
      setDigitAccount('0');
      setBankAccountType('IndividualCurrentAccount');
      setNationality('Brazilian');
      setMotherName('Mãe');
      setCivilStatus('Single');
      setOccupation('Pedreiro');
    }
  }, []);

  const formValidate = () => {
    setHaveError(false);
    setStatusMessageAlert(null);

    if (
      email === '' ||
      cellPhoneNumber === '' ||
      incomeValue === '0' ||
      incomeValue === '' ||
      occupation === '' ||
      nationality === '' ||
      civilStatus === '' ||
      motherName === '' ||
      gender === '' ||
      documentNumber === '' ||
      documentIssueDate === '' ||
      documentIssuingAgency === '' ||
      documentState === '' ||
      postalCode === '' ||
      address === '' ||
      streetNumber === '' ||
      neighborhood === '' ||
      city === '' ||
      uf === '' ||
      bank === '' ||
      agency === '' ||
      account === '' ||
      digitAccount === '' ||
      bankAccountType === ''
    ) {
      setHaveError(true);
      setStatusMessageAlert({
        status: 'error',
        message: 'Você deve preencher todos os campos obrigatórios(*).',
      });
      return false;
    }

    return true;
  };

  const handleIncludeProposal = async () => {
    if (formValidate()) {
      setLoading(true);

      const formattedAgency = agency.replace(/[^0-9]/g, '');
      const formattedAccount = account.replace(/[^0-9]/g, '');
      const formattedDigitAccount = digitAccount.replace(/[^X-Xx-x0-9]/g, '');
      const formattedIncomeValue = +incomeValue.replace(/,/i, '.');
      const formattedZipCode = postalCode.replace(/[^0-9]/g, '');
      const formattedCellPhoneNumber = cellPhoneNumber.replace(/[^0-9]/g, '');
      const finalFormattedCellPhoneNumber = formattedCellPhoneNumber.substring(
        2,
        11,
      );
      const cellphoneCode = formattedCellPhoneNumber.substring(0, 2);

      const data = {
        proposalUuid,
        bankData: {
          bankAccountType,
          bankNumber: bank,
          agency: formattedAgency,
          accountNumber: formattedAccount,
          digitAccount: formattedDigitAccount,
        },
        client: {
          incomeValue: formattedIncomeValue,
          otherIncomeValue: 0,
          occupation,
          addressData: {
            zipCode: formattedZipCode,
            address,
            addressNumber: streetNumber,
            neighborhood,
            city,
            uf,
          },
          motherName,
          gender,
          nationality,
          politicallyExposedCustomer: false,
          civilStatus,
          contacts: {
            email,
            cellPhoneDdd: cellphoneCode,
            cellPhone: finalFormattedCellPhoneNumber,
          },
          identificationDocument: {
            docType: documentType,
            docNumber: documentNumber,
            docUf: documentState,
            docDate: documentIssueDate,
            docIssuer: documentIssuingAgency,
          },
        },
      };
      const response = await api.post('/fgts/include', data);

      if (response.data.status === 'success') {
        history.push({
          pathname: `/admin/fgts/${proposalUuid}/concluded`,
          state: {
            ...response.data,
          },
        });
      } else if (response.data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: response.data.message,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu, tente novamente.',
        });
      }

      setLoading(false);
    }
  };

  function onChangeDocumentType(documentType: string) {
    setDocumentType(documentType);
    setDocumentIssuingAgency('');

    switch (documentType) {
      case 'CNH': {
        setDocumentIssuingAgency('DETRAN');
        break;
      }
      case 'CTPS': {
        setDocumentIssuingAgency('Ministério do Trabalho e Emprego');
        break;
      }
    }
  }

  return (
    <Layout
      pageBreadcrumb={`Criar proposta - ${locationState.name} (${locationState.cpf}) - [${locationState.integrationName}]`}
      pageTitle="Dados Complementares"
    >
      <div className="admin customer-data">
        <div className="row">
          <div>
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger'
                    : 'alert alert-primary'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
          </div>
        </div>
        <h2 style={{ marginBottom: '10px', textAlign: 'center' }}>
          Preencha as informações abaixo, com os dados do cliente.
        </h2>
        {loading ? (
          <div className="form-row">
            <div className="col-md-12">
              <p>Enviando dados, aguarde...</p>
            </div>
          </div>
        ) : (
          <>
            <div>
              <h3>Dados pessoais</h3>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>CPF *</label>
                  <InputMask
                    mask="999.999.999-99"
                    className={`form-control ${
                      haveError && !cpf && 'is-invalid'
                    }`}
                    autoComplete="off"
                    placeholder=""
                    value={cpf}
                    onChange={(e: any) =>
                      setCpf(e.target.value.replace(/[^0-9]/g, ''))
                    }
                  />
                  <div className="invalid-feedback">
                    {haveError && !cpf && 'Por favor digite seu CPF.'}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>E-mail *</label>
                  <input
                    className={`form-control ${
                      haveError && !email && 'is-invalid'
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    value={email}
                  />
                  <div className="invalid-feedback">
                    {haveError && !email && 'Por favor digite o e-mail.'}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Celular (que possua WhatsApp) *</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    className={`form-control ${
                      haveError && !cellPhoneNumber && 'is-invalid'
                    }`}
                    autoComplete="off"
                    placeholder=""
                    value={cellPhoneNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCellPhoneNumber(e.target.value)
                    }
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      !cellPhoneNumber &&
                      'Por favor digite seu celular.'}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Renda *</label>
                  <CurrencyInput
                    className={`form-control ${
                      haveError &&
                      (!incomeValue || incomeValue === '0') &&
                      'is-invalid'
                    }`}
                    id="inputValue"
                    onValueChange={(value) => setIncomeValue(value!)}
                    value={incomeValue}
                    prefix={'R$ '}
                    decimalsLimit={2}
                    decimalSeparator={','}
                    groupSeparator={'.'}
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      (!incomeValue || incomeValue === '0') &&
                      'Por favor digite sua renda.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Profissão *</label>
                  <input
                    className={`form-control ${
                      haveError && !occupation && 'is-invalid'
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setOccupation(e.target.value)
                    }
                    value={occupation}
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      !occupation &&
                      'Por favor informe a sua profissão.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Nacionalidade *</label>
                  <select
                    className={`form-control ${
                      haveError && !nationality && 'is-invalid'
                    }`}
                    value={nationality}
                    onChange={(e: any) => setNationality(e.target.value)}
                  >
                    {nationalityList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {haveError &&
                      !nationality &&
                      'Por favor selecione uma das opções.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Estado Civil *</label>
                  <select
                    className={`form-control ${
                      haveError && !civilStatus && 'is-invalid'
                    }`}
                    value={civilStatus}
                    onChange={(e: any) => setCivilStatus(e.target.value)}
                  >
                    {civilStatusList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {haveError &&
                      !civilStatus &&
                      'Por favor selecione uma das opções.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Nome completo da mãe *</label>
                  <input
                    className={`form-control ${
                      haveError && !motherName && 'is-invalid'
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setMotherName(e.target.value)
                    }
                    value={motherName}
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      !motherName &&
                      'Por favor informe o nome completo da mãe.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Sexo *</label>
                  <div style={{ display: 'flex' }}>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="Feminine"
                          checked={gender === 'Feminine'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setGender(e.target.value)
                          }
                        />
                        Feminino
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="Masculine"
                          checked={gender === 'Masculine'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setGender(e.target.value)
                          }
                        />
                        Masculino
                      </label>
                    </div>
                  </div>
                  <div className="invalid-feedback">
                    {haveError && !gender && 'Por favor informe sexo.'}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Documento de identificação</h3>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>Tipo de documento *</label>
                  <select
                    className={`form-control ${
                      haveError && !documentType && 'is-invalid'
                    }`}
                    value={documentType}
                    onChange={(e: any) => onChangeDocumentType(e.target.value)}
                  >
                    {documentTypeList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {haveError &&
                      !documentType &&
                      'Por favor selecione o tipo de documento de identificação.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Número do documento *</label>
                  <input
                    className={`form-control ${
                      haveError && !documentNumber && 'is-invalid'
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDocumentNumber(e.target.value)
                    }
                    value={documentNumber}
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      !documentNumber &&
                      'Por favor digite o número do documento.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Data de emissão *</label>
                  <InputMask
                    mask="99/99/9999"
                    className={`form-control ${
                      haveError && !documentIssueDate && 'is-invalid'
                    }`}
                    autoComplete="off"
                    placeholder=""
                    value={documentIssueDate}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDocumentIssueDate(e.target.value)
                    }
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      !documentIssueDate &&
                      'Por favor digite a data de emissão.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Órgão emissor *</label>
                  <input
                    className={`form-control ${
                      haveError && !documentIssuingAgency && 'is-invalid'
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDocumentIssuingAgency(e.target.value)
                    }
                    value={documentIssuingAgency}
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      !documentIssuingAgency &&
                      'Por favor digite o órgão emissor.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>UF do órgão emissor *</label>
                  <select
                    className={`form-control ${
                      haveError && !documentState && 'is-invalid'
                    }`}
                    value={documentState}
                    onChange={(e: any) => setDocumentState(e.target.value)}
                  >
                    {ufList.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.sigla}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {haveError &&
                      !documentState &&
                      'Por favor selecione a UF do órgão emissor.'}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Endereço</h3>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>CEP *</label>
                  <div className="input-group">
                    <InputMask
                      {...register('cep')}
                      mask="99.999-999"
                      className={`form-control ${
                        haveError && !postalCode && 'is-invalid'
                      }`}
                      autoComplete="off"
                      placeholder=""
                      value={postalCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const unmaskedCep = e.target.value.replace(/\D/g, '');

                        setPostalCode(e.target.value);
                        getCep(unmaskedCep);
                      }}
                    />
                    <div className="invalid-feedback">
                      {haveError && !postalCode && 'Por favor digite o CEP.'}
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Avenida, rua etc *</label>
                  <input
                    {...register('address')}
                    className={`form-control ${
                      haveError && !address && 'is-invalid'
                    }`}
                    value={CepLoading ? 'Buscando dados...' : address}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAddress(e.target.value)
                    }
                  />
                  <div className="invalid-feedback">
                    {haveError &&
                      !address &&
                      'Por favor informe a avenida ou rua.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>N° da residência *</label>
                  <input
                    {...register('streetNumber')}
                    className={`form-control ${
                      haveError && !streetNumber && 'is-invalid'
                    }`}
                    maxLength={6}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setStreetNumber(e.target.value)
                    }
                    placeholder=""
                    value={streetNumber}
                  />
                  <div className="invalid-feedback">
                    {haveError && !streetNumber && 'Por favor digite o número.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Bairro *</label>
                  <input
                    {...register('neighborhood')}
                    className={`form-control ${
                      haveError && !neighborhood && 'is-invalid'
                    }`}
                    value={neighborhood}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setNeighborhood(e.target.value)
                    }
                  />
                  <div className="invalid-feedback">
                    {haveError && !neighborhood && 'Por favor digite o bairro.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Cidade *</label>
                  <input
                    {...register('city')}
                    className={`form-control ${
                      haveError && !city && 'is-invalid'
                    }`}
                    value={city}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCity(e.target.value)
                    }
                  />
                  <div className="invalid-feedback">
                    {haveError && !city && 'Por favor digite a cidade.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Estado *</label>
                  <select
                    {...register('uf')}
                    className={`form-control ${
                      haveError && !uf && 'is-invalid'
                    }`}
                    value={uf}
                    onChange={(e: any) => setUf(e.target.value)}
                  >
                    {ufList.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.sigla}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {haveError && !uf && 'Por favor selecione o estado.'}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Dados Bancários</h3>
              <div className="form-row">
                <div
                  className="form-group col-md-12"
                  style={{
                    border: 'none',
                  }}
                >
                  <label>Banco *</label>
                  <select
                    className={`form-control ${
                      haveError && !bank && 'is-invalid'
                    }`}
                    value={bank}
                    onChange={(e: any) => setBank(e.target.value)}
                  >
                    {bankList.map((option) => (
                      <option key={option.bankNumber} value={option.bankNumber}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {haveError && !bank && 'Por favor selecione o banco.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Agência *</label>
                  <input
                    className={`form-control ${
                      haveError && !agency && 'is-invalid'
                    }`}
                    maxLength={4}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAgency(e.target.value)
                    }
                    placeholder=""
                    value={agency}
                  />
                  <div className="invalid-feedback">
                    {haveError && !agency && 'Por favor digite a agência.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Conta *</label>
                  <div
                    className={`form-row ${
                      haveError && (!account || !digitAccount) && 'is-invalid'
                    }`}
                  >
                    <div className="form-group col-md-9">
                      <input
                        className={`form-control ${
                          haveError && !account && 'is-invalid'
                        }`}
                        maxLength={10}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setAccount(e.target.value)
                        }
                        placeholder=""
                        value={account}
                      />
                    </div>
                    <div
                      className="form-group col-md-1 col-1"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,
                        textAlign: 'center',
                      }}
                    >
                      -
                    </div>
                    <div className="form-group col-md-2">
                      <input
                        className={`form-control ${
                          haveError && !digitAccount && 'is-invalid'
                        }`}
                        maxLength={10}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setDigitAccount(e.target.value)
                        }
                        placeholder=""
                        value={digitAccount}
                      />
                    </div>
                  </div>
                  <div className="invalid-feedback">
                    {haveError && !account && 'Por favor digite a conta.'}
                    {haveError &&
                      !digitAccount &&
                      'Por favor digite o dígito da conta.'}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Tipo de conta *</label>
                  <select
                    className={`form-control ${
                      haveError && !bankAccountType && 'is-invalid'
                    }`}
                    value={bankAccountType}
                    onChange={(e: any) => setBankAccountType(e.target.value)}
                  >
                    {accountTypeList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    {haveError &&
                      !bankAccountType &&
                      'Por favor selecione o tipo de conta.'}
                  </div>
                </div>
              </div>
              <div className="include-proposal-button-container">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  style={{ marginTop: '15px', border: 'none' }}
                  onClick={handleIncludeProposal}
                >
                  Incluir proposta
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default CustomerData;
