import React, { useState, useRef, useEffect } from 'react';
import { FiChevronRight } from 'react-icons/fi';

import "./styles.scss";

type Props = {
    id: number;
    title: string;
    selected: number | null;
    toggleAccordion: () => void;
    children?: React.ReactNode;
};

function Accordion({
    id,
    title,
    selected,
    toggleAccordion,
    children,
}: Props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const content = useRef(null);

    useEffect(() => {
        if (selected === id) {
            setActiveState("active");
            setHeightState(`${content.current.scrollHeight}px`);
            setRotateState("accordion__icon rotate");
        } else {
            setActiveState("");
            setHeightState("0px");
            setRotateState("accordion__icon");
        }
    }, [selected]);

    return (
        <div className="accordion__section">
            <button type="button" className={`accordion ${setActive}`} onClick={toggleAccordion}>
                <FiChevronRight className={`${setRotate} icon`} />
                <p className="accordion__title">{title}</p>
            </button>
            <div
                ref={content}
                style={{ maxHeight: `${setHeight}` }}
                className="accordion__content"
            >
                <div className="accordion__text">{children}</div>
            </div>
        </div>
    );
}

export default Accordion;
