import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoImg from '../../assets/logo/logo_header.svg';
import { Button } from '../../components/Button';
import InputMask from 'react-input-mask';

import '../../styles/global.scss';
import { api } from '../../services/api';

type StatusAlertType = {
  status: string;
  message: string;
}

export default function ResendEmail() {
  const [statusMessageAlert, setStatusMessageAlert] = useState<StatusAlertType | null>(null);
  const [cpf, setCpf] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === "true") {
      setCpf(process.env.REACT_APP_FAKER_CPF_FGTS);
    }
  }, []);

  async function handleResendEmail() {
    setStatusMessageAlert(null);
    setLoading(true);

    if (cpf === '') {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha o cpf para continuar.',
      });
      setLoading(false);

      return;
    }

    try {
      const { data } = await api.post('/auth/resend-confirmation-email', {
        cpf
      });

      setStatusMessageAlert({
        status: 'info',
        message: data.message,
      });
    } catch (error) {
      setStatusMessageAlert({
        status: 'error',
        message: error.response.data.message,
      });
    }

    setLoading(false);
  }

  return (
    <div id="page-auth">
      <aside>
        <strong>Reenvio de email de confirmação</strong>
        <p>Aqui você solicita o reenvio do e-mail de confirmação.</p>
      </aside>
      <main>
        <div className="main-content">
          <div className="logoTnb"><img src={logoImg} alt="Ta no bolso" /></div>
          <form onSubmit={() => { }}>
            <h2>Reenviar o e-mail de confirmação</h2>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Digite seu CPF</label>
                <InputMask
                  name="cpf"
                  mask="999.999.999-99"
                  className={`form-control`}
                  onChange={(e: any) => setCpf(e.target.value)}
                  autoComplete="off"
                  placeholder=""
                  value={cpf}
                />
              </div>
            </div>
            {statusMessageAlert && (
              <div className={statusMessageAlert.status === 'error' ? 'alert alert-danger alert-text-center' : 'alert alert-primary alert-text-center'} role="alert">{statusMessageAlert.message}</div>
            )}
            <Button type="button" onClick={handleResendEmail}>
              {!loading ? 'Enviar' : 'Enviando...'}
            </Button>
            <p><Link to="/signin">Voltar ao login.</Link></p>
          </form>
        </div>
      </main>
    </div>
  )
}
