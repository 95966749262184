import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCopyToClipboard } from '../../../../utils/helpers';
import Layout from '../../../../components/Admin/Layout';
import { api } from '../../../../services/api';
import { ParamsType } from '../AlertEnabledBank';
import { StatusAlertType } from '../Balance';
import { LocationStateType } from '../Create';

import './styles.scss';

type SimulationStatesType = {
  liquidValue: string;
  amountFinanced: string;
  iofValue: string;
  amountWithdrawals: number;
  monthlyRate: string;
  yearlyRate: string;
  installments: [];
  monthlyCet: string;
  yearlyCet: string;
  totalInterestAmount: string;
  registrationFeeValue: string;
};

const Simulation = () => {
  let history = useHistory();
  const { proposalUuid } = useParams<ParamsType>();
  const location = useLocation();
  const locationState = location.state as LocationStateType;
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const regexMoney = /\d(?=(\d{3})+,)/g;
  const [simulationStates, setSimulationStates] =
    useState<SimulationStatesType>({
      liquidValue: '',
      amountFinanced: '',
      iofValue: '',
      amountWithdrawals: 0,
      monthlyRate: '',
      yearlyRate: '',
      installments: [],
      monthlyCet: '',
      yearlyCet: '',
      totalInterestAmount: '',
      registrationFeeValue: '',
    });
  const [totalValueInstallments, setTotalValueInstallments] =
    useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [value, copy] = useCopyToClipboard();
  const [copiedMessage, setCopiedMessage] = useState<string>('');
  const [loanInfo, setLoanInfo] = useState<string>('');

  useEffect(() => {
    simulate();
  }, []);

  // function sumInstallments(installments: any) {
  //   return installments.reduce((sum, currentState) => {
  //     return sum + currentState.valorTotalParcela;
  //   }, 0);
  // }

  async function simulate() {
    try {
      setLoading(true);
      const { parcelas } = location.state as LocationStateType;
      const { data } = await api.post('/fgts/simulate', {
        numberOfInstallments: parcelas,
        proposalUuid,
      });

      if (data.status === 'error') {
        if (
          data.message === 'Não foi possível consultar o saldo.' ||
          data.message === 'Não foi possível simular.' ||
          data.message === 'Rate limit exceeded' ||
          data.message === ''
        ) {
          // setTimeout(simulate, 3000)
        }
      }

      if (data.status === 'success') {
        const totalValue = data?.sumValueInstallments;

        setTotalValueInstallments(totalValue);
        setSimulationStates({
          liquidValue: data.formattedValues.liquidValue,
          amountFinanced: data.formattedValues.financedAmount,
          iofValue: data.formattedValues.iofValue
            .replace('.', ',')
            .replace(regexMoney, '$&.'),
          amountWithdrawals: data.numberOfInstallments,
          monthlyRate: data.formattedValues.interestRateMonth,
          yearlyRate: data.formattedValues.interestRateYear,
          installments: data.installments,
          monthlyCet: data.formattedValues.totalEffectiveCostMonth,
          yearlyCet: data.formattedValues.totalEffectiveCostYear,
          totalInterestAmount: data.formattedValues.totalInterestAmount,
          registrationFeeValue: data.formattedValues.registrationFeeValue,
        });
      } else if (data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: data.message,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu, tente novamente.',
        });
      }
    } catch (error) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Ops, algo ocorreu, tente novamente.',
      });
    }

    setLoading(false);
  }

  const handleCustomerData = () => {
    history.push({
      pathname: `/admin/fgts/${proposalUuid}/customer-data`,
      state: { ...locationState },
    });
  };

  const copyLoanText = () => {
    copy(loanInfo);
    setCopiedMessage('Texto copiado!');
  };

  useEffect(() => {
    fillLoanInfo();
  }, [simulationStates]);

  const fillLoanInfo = () => {
    if (simulationStates.amountWithdrawals < 2) {
      setLoanInfo(
        `O banco liberou ${
          simulationStates.liquidValue
        } e será descontado R$ ${totalValueInstallments
          .toFixed(2)
          .replace('.', ',')
          .replace(regexMoney, '$&.')} em 0${
          simulationStates.amountWithdrawals
        } parcela anual, diretamente do seu FGTS conforme a tabela. Vamos prosseguir?`,
      );
    }

    if (simulationStates.amountWithdrawals > 1) {
      setLoanInfo(
        `O banco liberou ${
          simulationStates.liquidValue
        } e será descontado R$ ${totalValueInstallments
          .toFixed(2)
          .replace('.', ',')
          .replace(regexMoney, '$&.')} em ${
          simulationStates.amountWithdrawals
        } parcelas anuais, diretamente do seu FGTS conforme a tabela. Vamos prosseguir?`,
      );
    }
  };

  return (
    <Layout
      pageBreadcrumb={`Criar proposta  ${locationState.name} (${locationState.cpf}) - [${locationState.integrationName}]`}
      pageTitle="Simulação"
    >
      <div className="admin simulation">
        <div className="row">
          <div>
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger'
                    : 'alert alert-primary'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
          </div>
        </div>
        <h3>Abaixo estão as informações da simulação.</h3>
        {loading ? (
          <div className="form-row">
            <p>Gerando a simulação, aguarde...</p>
          </div>
        ) : (
          <>
            <div className="row justify-content-center">
              <div className="col-md-4 col-sm-6">
                <div className="pricingTable10">
                  <div className="pricingTable-header">
                    <h3 className="heading">Você vai receber</h3>
                    <span className="price-value">
                      <span>{simulationStates.liquidValue}</span>
                    </span>
                  </div>
                  <div className="pricing-content">
                    <ul>
                      <li>
                        Valor a ser descontado do seu saldo de FGTS: <br></br>
                        <span>
                          R${' '}
                          {totalValueInstallments
                            .toFixed(2)
                            .replace('.', ',')
                            .replace(regexMoney, '$&.')}
                        </span>
                      </li>
                      <li>
                        Qtd. saques:{' '}
                        <span>{simulationStates.amountWithdrawals}</span>
                      </li>
                      <li className="info-simulation">
                        IOF: <span>R$ {simulationStates.iofValue}</span>
                        <br />
                        Taxa de juros:{' '}
                        <span>{simulationStates.monthlyRate}</span> /{' '}
                        <span>{simulationStates.yearlyRate}</span>
                        <br />
                        Valor total de juros:{' '}
                        <span>{simulationStates.totalInterestAmount}</span>
                        <br />
                        CET: <span>{simulationStates.monthlyCet}</span> /{' '}
                        <span>{simulationStates.yearlyCet}</span>
                        <br />
                        Taxa de cadastro:{' '}
                        <span>{simulationStates.registrationFeeValue}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-12">
                <div className="table-responsive">
                  <table className="table table-light table-sm table-hover">
                    <thead>
                      <tr>
                        <th>N° da parcela</th>
                        <th>Valor</th>
                        <th>Data saque</th>
                      </tr>
                    </thead>
                    <tbody>
                      {simulationStates.installments?.map((parcelData: any) => (
                        <tr key={parcelData.number}>
                          <td>{parcelData.number}</td>
                          <td>
                            R${' '}
                            {parcelData.value
                              .toFixed(2)
                              .replace('.', ',')
                              .replace(regexMoney, '$&.')}
                          </td>
                          <td>
                            {format(parseISO(parcelData.date), 'dd/MM/yyyy')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <thead>
                      <tr>
                        <th colSpan={3} style={{ textAlign: 'center' }}>
                          Valor de garantia{' '}
                          <span
                            style={{ fontSize: '12px', fontStyle: 'italic' }}
                          >
                            (de acordo com as parcelas acima)
                          </span>
                          : R${' '}
                          {totalValueInstallments
                            .toFixed(2)
                            .replace('.', ',')
                            .replace(regexMoney, '$&.')}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div className="loan-info-container">
                    <h3> {loanInfo ? loanInfo : ''}</h3>
                    <button className="copy-text-button" onClick={copyLoanText}>
                      Copiar texto
                    </button>
                    <h4>{copiedMessage ? copiedMessage : ''} </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <button
                type="button"
                className="btn btn-block btn-primary"
                style={{ marginTop: '15px' }}
                onClick={handleCustomerData}
              >
                Preencher dados complementares
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Simulation;
