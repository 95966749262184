import React, { useEffect, useState } from 'react';
import { useLandingPage } from '../../hooks/useLandingPage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import './styles.scss';
import { FaWhatsapp } from 'react-icons/fa';

const schema = yup.object().shape({
  username: yup.string().required('Campo não preenchido'),
  email: yup
    .string()
    .required('Campo não preenchido')
    .email('Informar um e-mail válido'),
  telephone: yup
    .string()
    .required('Campo não preenchido')
    .min(11, 'Informar um número com DDD'),
});

export const showModal = () => {
  const modal = document.querySelector('#modal-whatsapp');
  modal!.classList.remove('hide');
  document.body.classList.add('no-scroll');
  window.scrollTo(0, 0);
};

function WhatsappModal({ partnerInfo }) {
  const { handleWhatsAppButton } = useLandingPage();
  const [textWhatsApp, setTextWhatsApp] = useState('Olá! Estou com dúvidas e gostaria de receber ajuda.');
  const [whatsAppPhone, setWhatsAppPhone] = useState<string>('5521965773075');

  const {
    register,
    handleSubmit,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(schema),
  });

  const formContent = {
    inputs: [
      {
        label: 'Nome completo:',
        name: 'username',
        placeholder: 'João da Silva',
        type: 'text',
      },
      {
        label: 'Email:',
        name: 'email',
        placeholder: 'joao.silva@tanobolso.com.br',
        type: 'email',
      },
      {
        label: 'Celular:',
        name: 'telephone',
        placeholder: '11 99999 9999',
        type: 'tel',
      },
    ],
  };

  const closeModal = () => {
    const modal = document.querySelector('#modal-whatsapp');
    modal!.classList.add('hide');
    document.body.classList.remove('no-scroll');
  };

  const loadWhatsappPage = () => {
    handleWhatsAppButton({ phone: whatsAppPhone, text: textWhatsApp });
    closeModal();
  };

  useEffect(() => {
    if (partnerInfo.name !== null) {
      setTextWhatsApp(
        ` Olá! Vim pelo parceiro ${partnerInfo.friendlyName} e estou com dúvidas sobre a antecipação do FGTS.`,
      );

      if (partnerInfo.ownWhatsAppNumber) {
        setWhatsAppPhone(partnerInfo.ownWhatsAppNumber);
      }
    }
  }, [partnerInfo]);

  return (
    <>
      <div id="modal-whatsapp" className="hide">
        <div className="whatsapp-modal-container">
          <div className="close-button-container">
            <button onClick={closeModal}>
              <p>X</p>
            </button>
          </div>
          <h2>Preencha as informações antes de seguir para o WhatsApp:</h2>
          <form
            onSubmit={handleSubmit(loadWhatsappPage)}
            className="form-whatsapp"
          >
            {formContent.inputs.map((input, key) => {
              return (
                <div className="input-container-whatsapp" key={key}>
                  <label htmlFor={input.name}>{input.label}</label>
                  <input
                    type={input.type}
                    name={input.name}
                    placeholder={input.placeholder}
                    {...register(input.name)}
                  />
                  <p>{errors && errors[input.name]?.message}</p>
                </div>
              );
            })}
            <button type="submit">
              <FaWhatsapp className="whatsapp-icon" />
              Continuar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default WhatsappModal;
