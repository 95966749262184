import { BsAlarm } from 'react-icons/bs';
import { TiArrowShuffle } from 'react-icons/ti';

import classes from './styles.module.scss';

function Benefits() {
    return (
        <section className={classes.about} id="about">
            <div className="container">
                <h5>IMPORTANTE SABER</h5>
                <div className={`row ${classes.iconsList} `}>
                    <div className="col-lg-4">
                        <TiArrowShuffle className={classes.icon} />
                        <h2>Sobre o processo de portabilidade</h2>
                        <p>
                            O banco onde você tem o empréstimo é obrigado a aceitar o pedido da portabilidade.
                            Pode ocorrer uma contraproposta e fica a seu critério aceitar ou não.
                            Essas operações estão de acordo com normas emitidas pelo Banco Central.<br /><br />

                            A nova instituição, compradora da dívida, não tem o compromisso de aceitar o novo cliente ou “cobrir a oferta financeira”.
                            Neste caso, também pode fazer uma nova análise para liberação da operação e, dependendo da avaliação, negar a portabilidade.
                        </p>
                    </div>
                    <div className="col-lg-4">
                        <BsAlarm className={classes.icon} />
                        <h2>Tempo de efetivação</h2>
                        <p>
                            O novo banco irá transferir o dinheiro para o banco da dívida original, liquidando o empréstimo antecipadamente. <br /><br />

                            <strong>O prazo varia entre 5 e 7 dias úteis.</strong><br /><br />

                            A transferência é realizada via TED (Transferência Eletrônica Disponível) e os custos não podem ser repassados para o cliente.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits;
