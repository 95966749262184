import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';

import Layout from '../../../components/Admin/Layout';
import { api } from '../../../services/api';

import './styles.scss';
import { DisplayProgressBar } from '../../../components/progress-bar';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';

type ProposalsType = {
  uuid: string;
  value: string;
  installment: number;
  reason: string;
  bank_proposal_id: string;
  bank_proposal_valid: boolean;
  proposal_canceled: boolean;
  proposal_completed: boolean;
  proposal_last_status: string;
  click_sign_document_key: string;
  click_sign_signature_key: string;
  for_whatsapp: boolean;
  step: string;
  step_error: string;
  created_email: string;
  created_phone: string;
  createdAt: string;
  updatedAt: string;
  product: ProductType;
};

type ProductType = {
  id: number;
  name: string;
  active: boolean;
};

export default function MyLoans() {
  const [proposals, setProposals] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const regexMoney = /\d(?=(\d{3})+,)/g;
  const statusProposal = {
    created: 'Proposta Criada',
    pending: 'Proposta Pendente',
    analysis: 'Em análise',
    pendingFormalizationSignature: 'Proposta Pendente',
    pendingFormalizationSelfie: 'Proposta Pendente',
    pendingSubmissionDocuments: 'Proposta Pendente',
    pendingResubmissionDocuments: 'Proposta Pendente',
    progress: 'Em Processo',
    completed: 'Proposta Concluída',
    canceled: 'Proposta Cancelada',
    reproved: 'Proposta Reprovada',
    transferToCustomer: 'Transferência/conta troco',
    transferToPartner: 'Transferência/loja',
    temporaryAccount: 'Em cadastro',
    temporaryAccountCreated: 'Cadastro realizado',
    temporaryAccountNotCreated: 'Cadastro não permitido',
  };

  useEffect(() => {
    getLoans();
  }, []);

  const getLoans = async () => {
    setLoading(true);
    const { data } = await api.get(`/proposals`, {
      params: {
        status:
          'created,progress,pending,analysis,pendingFormalizationSignature,pendingFormalizationSelfie,pendingSubmissionDocuments,pendingResubmissionDocuments,completed,canceled,reproved,transferToCustomer,transferToPartner,temporaryAccount,temporaryAccountCreated',
        sort: {
          'proposal.createdAt': 'DESC',
        },
      },
    });

    setProposals(data.proposals);
    setLoading(false);
  };

  return (
    <Layout pageBreadcrumb="Antecipações" pageTitle="Antecipações">
      <div className="container">
        <div className="my-loans">
          <div className="row">
            <div className="col-md-12">
              <div className="div-container">
                <ul className="title-list">
                  <li>Antecipações</li>
                </ul>

                <div>
                  {loading ? (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        <strong>Carregando...</strong>
                      </td>
                    </tr>
                  ) : (
                    proposals?.map((proposal, index) => {
                      return (
                        <>
                          <div className="accordion-container">
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className="accordion-button">
                                    <span>
                                      {proposal.product !== null
                                        ? proposal.product.name
                                        : '-'}
                                    </span>
                                    <span>
                                      {format(
                                        parseISO(proposal.createdAt),
                                        'dd/MM/yyyy',
                                      )}
                                    </span>
                                    <span>
                                      R${' '}
                                      {parseFloat(proposal.value)
                                        .toFixed(2)
                                        .replace('.', ',')
                                        .replace(regexMoney, '$&.')}
                                    </span>
                                    <span>
                                      <strong>
                                        {statusProposal[proposal.status]}
                                      </strong>
                                    </span>
                                    <ChevronUpIcon
                                      className={`${
                                        open ? 'accordion-icon-closed' : ''
                                      } accordion-icon-opened `}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="accordion-panel">
                                    <DisplayProgressBar
                                      proposalStatus={proposal.status}
                                    />
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
