import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

import Home from '../pages/Admin/Home';
import Fgts from '../pages/Admin/Fgts';
import ServiceChannel from '../pages/Admin/ServiceChannel';
import MyData from '../pages/Admin/MyData';
import MyLoans from '../pages/Admin/MyLoans';
import Create from '../pages/Admin/Fgts/Create';
import CreatePay from '../pages/Admin/Fgts/Create-pay';
import CreatePartner from '../pages/Admin/CreatePartner';
import AlertEnabledBank from '../pages/Admin/Fgts/AlertEnabledBank';
import Balance from '../pages/Admin/Fgts/Balance';
import Simulation from '../pages/Admin/Fgts/Simulation';
import CustomerData from '../pages/Admin/Fgts/CustomerData';
import Concluded from '../pages/Admin/Fgts/Concluded';
import IndexFgts from '../pages/Admin/Fgts/IndexFgts';
import DigitalAuthorization from '../pages/Admin/Fgts/DigitalAuthorization';

export function CustomRoute({ isPrivate, isRouteAdmin = false, ...rest }) {
  const { loading, authenticated, user } = useAuth();

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/signin" />;
  }

  // if (isRouteAdmin && user.role !== 'ADMIN') {
  //   return <Redirect to="/admin/home" />
  // }

  return <Route {...rest} />;
}

function AuthRoutes() {
  return (
    <Switch>
      <CustomRoute isPrivate exact path="/admin/home" component={Home} />
      <CustomRoute isPrivate exact path="/admin/my-loans" component={MyLoans} />
      <CustomRoute
        isPrivate
        exact
        path="/admin/saque-aniversario-fgts"
        component={Fgts}
      />
      <CustomRoute
        isPrivate
        exact
        path="/admin/service-channel"
        component={ServiceChannel}
      />
      <CustomRoute
        isPrivate
        exact
        path="/admin/create-partner"
        component={CreatePartner}
      />
      <CustomRoute isPrivate exact path="/admin/my-data" component={MyData} />

      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/index"
        component={IndexFgts}
      />
      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/create"
        component={Create}
      />
      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/create-pay"
        component={CreatePay}
      />
      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/:proposalUuid/balance"
        component={Balance}
      />
      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/:proposalUuid/simulation"
        component={Simulation}
      />
      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/:proposalUuid/customer-data"
        component={CustomerData}
      />
      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/:proposalUuid/concluded"
        component={Concluded}
      />
      <CustomRoute
        isPrivate
        isRouteAdmin
        exact
        path="/admin/fgts/:proposalUuid/digital-authorization"
        component={DigitalAuthorization}
      />
    </Switch>
  );
}

export default AuthRoutes;
