const minimumFractionDigits = 2;
const maximumFractionDigits = 2;
const realFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits,
  maximumFractionDigits,
});

export const formatReal = (number: number, currency = false) =>
  currency
    ? `R$ ${realFormatter.format(number)}`
    : realFormatter.format(number);

export const formatStringNumberCommaToDot = (number: string) =>
  number.replace(',', '.');

export const formatDateBrToSql = (date: string) => {
  const arrayDate = date.includes('/') ? date.split('/') : date.split('-');

  return `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
};

export const formatDateSqlToBr = (date: string) => {
  const arrayDate = date.split('-');

  return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
};

export const formatDateToDateSqlNoHyphen = (date: string) => {
  if (date.includes('/')) date = formatDateBrToSql(date);

  return date.replace('-', '');
};

export const formatIntToDecimal = (number: number) => {
  const numberString = number.toString();
  const intNumber = numberString.substring(0, numberString.length - 2);
  const cents = numberString.substring(numberString.length - 2);

  return parseFloat(`${intNumber}.${cents}`);
};

export const formatDecimalToInt = (number: number) => {
  const numberString = number.toString();
  const intNumber = numberString.replace('.', '');

  return parseInt(intNumber);
};
