import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import OtpInput from 'react-otp-input';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

import './style.scss';

import { api } from '../../../../services/api';

import Button from '../../../button/Button';
import Accordion from '../../../../../components/Accordion';

import moment from 'moment';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import InputMask from 'react-input-mask';

import BidOffer from './Steps/BidOffer';
import ComplementaryData from './Steps/ComplementaryData';

import imageLogo from '../../../../../assets/logo/logo_header.svg';
import imageIdentificationDocument from '../../../../../assets/simulation_result/identification_document.jpg';

type SimulatedDataType = {
  yearlyCet: number;
  monthlyCet: number;
  iof: number;
  simulationDate: string;
  financedAmount: number;
  operationGrossValue: number;
  finalAvailableLimit: number;
  availablePaymentCapacity: number;
  finalDueDate: string;
  firstDueDate: string;
  installments: number;
  yearlyRate: number;
  rate: number;
  limit: {
    availablePaymentCapacity: number;
    finalAvailableLimit: number;
    maxInstallments: number;
    maxNetAvailableLimit: number;
    minAvailablePaymentCapacity: number;
    minFinalAvailableLimit: number;
    minInstallments: number;
    minNetAvailableLimit: number;
  };
  hasPaymentInformation: boolean;
  percentageCompositionCet: {
    finalAvailableLimit: number;
    financedAmount: number;
    iof: number;
    operationGrossValue: number;
  }
};

const SimulationResult = ({
  visible,
  handleClose,
  name,
  cpf,
  phone,
  value,
  installments,
  email,
  reason,
  faker,
  clickTracking,
  simulatorForWhatsApp,
  adhesionContractData,
}: {
  visible: boolean,
  handleClose: any,
  name: string,
  cpf: string,
  phone: string,
  value: any,
  installments: any,
  email: string,
  reason: string,
  faker: boolean,
  clickTracking: any,
  simulatorForWhatsApp: boolean,
  adhesionContractData: string,
}) => {
  const routeParams = useParams();

  const [simulationResultStep, setSimulationResultStep] = useState<string>(null);
  const [userId, setUserId] = useState<number>(null);
  const [proposalId, setProposalId] = useState<string | null>(routeParams?.proposalId ?? null);
  const [bankProposalId, setBankProposalId] = useState<string | null>(null);
  const [authorizationTerm, setAuthorizationTerm] = useState<boolean>(false);
  const [buttonTextResendSMSTokenTerm, setButtonTextResendSMSTokenTerm] = useState<string>('Enviar novamente');
  const [hasPaymentInformation, setHasPaymentInformation] = useState<boolean>(false);
  const [cryptedAdhesionContractDataLink, setCryptedAdhesionContractDataLink] = useState<string>('');
  const [proposalDataAdhesionContractDataLink, setProposalDataAdhesionContractDataLink] = useState<any>(null);
  const [selectedAccordionIn100, setSelectedAccordionIn100] = useState<number>(0);
  const [digitalSignatureUrl, setDigitalSignatureUrl] = useState<string>('');

  const [simulationResultModalTitle, setSimulationResultModalTitle] = useState(null);
  const [statusMessageAlert, setStatusMessageAlert] = useState<any>(null);
  const [numPages, setNumPages] = useState(null);
  const [pdf, setPdf] = useState<any>(null);
  const [contract, setContract] = useState<any>(null);
  const [document, setDocument] = useState<any>(null);
  const [photo, setPhoto] = useState<any>(null);
  const [biometry, setBiometry] = useState<any>(null);
  const [proposalData, setProposalData] = useState<any>(null);
  const [proposalUserData, setProposalUserData] = useState<any>(null);
  const [waiting, setWaiting] = useState<any>(null);
  const [tokenSMSDigitalTerm, setTokenSMSDigitalTerm] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [simulatedData, setSimulatedData] = useState<SimulatedDataType | null>(null);

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isFilePicked, setIsSelected] = useState(false);
  let stopMe;
  const [haspendencia, setPendencia] = useState<any>(null);
  const [hasEffect, setEffect] = useState<any>(null);
  const [hasAproved, setAproved] = useState<any>(null);
  const [hasReproved, setReproved] = useState<any>(null);
  const [documentoAdicional, setDocumentoAdicional] = useState(null);
  const [hideScreen, setHideScreen] = useState(null);
  const [comprovantesResidencia, setComprovantesResidencia] = useState(null);
  const [nacionalidade, setNacionalidade] = useState(null);
  const [identificationDocumentImage, setIdentificationDocumentImage] = useState(null);
  const [facialBiometricsImage, setFacialBiometricsImage] = useState(null);
  const [documentoAdicionalValor, setDocumentoAdicionalValor] = useState(null);
  const [nacionalidadeValor, setNacionalidadeValor] = useState(null);
  const [
    documentExpeditionStateValor,
    setDocumentExpeditionStateValor,
  ] = useState(null);
  const [nomeMaeValor, setNomeMaeValor] = useState(null);
  const [nomePaiValor, setNomePaiValor] = useState(null);
  const [dataNascimentoValor, setDataNascimentoValor] = useState(null);
  const [nomeCompletoValor, setNomeCompletoValor] = useState(null);
  const [documentoBiometria, setDocumentoBiometria] = useState(null);
  const [nomeMae, setNomeMae] = useState(null);
  const [nomePai, setNomePai] = useState(null);
  const [dataNascimento, setDataNascimento] = useState(null);
  const [nomeCompleto, setNomeCompleto] = useState(null);
  const [estado, setestado] = useState(null);
  const [dataExpedicaoDocumento, setdataExpedicaoDocumento] = useState(null);
  const [tipoRua, settipoRua] = useState(null);
  const [rua, setrua] = useState(null);
  const [ruaValor, setruaValor] = useState(null);
  const [
    documentoIdentificacaoValor,
    setdocumentoIdentificacaoValor,
  ] = useState(null);
  const [documentoIdentificacao, setdocumentoIdentificacao] = useState(null);
  const [estadoValor, setestadoValor] = useState(null);
  const [
    dataExpedicaoDocumentoValor,
    setdataExpedicaoDocumentoValor,
  ] = useState(null);
  const [tipoRuaValor, settipoRuaValor] = useState(null);
  const [orgaoExpedidorDocumento, setOrgaoExpedidorDocumento] = useState(null);
  const [tipoDocumentoAdicional, settipoDocumentoAdicional] = useState(null);
  const [
    tipoDocumentoAdicionalValor,
    settipoDocumentoAdicionalValor,
  ] = useState(null);
  const [phoneCountryCode, setphoneCountryCode] = useState(null);
  const [ddi, setDDI] = useState(null);
  const [ddd, setDDD] = useState(null);
  const [areaCode, setAreaCode] = useState(null);
  const [city, setCity] = useState(null);
  const [number, setNumber] = useState(null);
  const [neighborhood, setNeighborhood] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [telefone, setTelefone] = useState(null);
  const [emailErro, setEmailErro] = useState(null);
  const [emailValor, setEmailValor] = useState(null);
  const [zip, setZip] = useState(null);
  const [additionalAttribute, setAdditionalAttribute] = useState(null);
  const [cep, setCep] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [numero, setNumero] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [complemento, setcomplemento] = useState(null);
  const [errors, setErrors] = useState(null);
  let StatusInterval = null;

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    if (contract) {
      const linkSource = `${contract}`;
      const downloadLink = window.document.createElement('a');
      const fileName = 'contrato.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    setNumPages(numPages);
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  const changeSimulationResultModalTitle = (title: string) => {
    setSimulationResultModalTitle(title);
  }

  function downloadTermIn100(e) {
    e.preventDefault();

    const linkSource = `${pdf}`;
    const downloadLink = window.document.createElement('a');
    const fileName = 'termoin100.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return null;
  }

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleClickUpdate = (event) => {
    let jsonErrors = {} as any;
    if (
      tipoDocumentoAdicionalValor != 'RG' &&
      tipoDocumentoAdicionalValor != 'CPF'
    ) {
      jsonErrors.documentoAdicional = 'TIPO DEVE SER RG OU CPF';
    }
    console.log(number);
    if (isNaN(parseFloat(number))) {
      jsonErrors.numero = 'Numero invalido';
    }
    console.log(
      moment(dataExpedicaoDocumentoValor, moment.ISO_8601, true).isValid(),
      dataExpedicaoDocumentoValor,
      'VALIDO?',
    );
    if (!moment(dataExpedicaoDocumentoValor).isValid()) {
      jsonErrors.dataExpedicaoDocumento = 'Data inválida';
    }
    if (!moment(dataNascimentoValor).isValid()) {
      jsonErrors.dataNascimento = 'Data inválida';
    }
    setErrors(jsonErrors);
    let dataExpedicaoDocumentoValorTrue = moment(
      dataExpedicaoDocumentoValor,
    ).format('YYYY-MM-DD');
    let dataNascimentoValorTrue = moment(dataNascimentoValor).format(
      'YYYY-MM-DD',
    );

    console.log(JSON.stringify(jsonErrors), 'ssss');

    if (JSON.stringify(jsonErrors) != '{}') {
      return;
    }
    stopMe = false;
    api
      .post(`update`, {
        nomeMaeValor,
        documentoAdicionalValor,
        nacionalidadeValor,
        documentExpeditionStateValor,
        ruaValor,
        estadoValor,
        dataExpedicaoDocumentoValorTrue,
        documentoIdentificacaoValor,
        tipoDocumentoAdicionalValor,
        tipoRuaValor,
        dataNascimentoValorTrue,
        phoneCountryCode,
        phoneNumber,
        areaCode,
        emailValor,
        city,
        neighborhood,
        nomeCompletoValor,
        additionalAttribute,
        number,
        nomePaiValor,
        zip,
      })
      .then((response) => {
        if (!response.data) {
          fetchStatus();
        }
      })
      .catch((error) => {
        fetchStatus();
        console.log(error);
      });
  };

  useEffect(() => {
    if (simulationResultStep) {
      const urlStep = `#simulation-step-${simulationResultStep.toLowerCase().replace(' ', '-')}`;

      if (simulationResultStep !== 'Loader') {
        clickTracking(urlStep);
      }
    }
  }, [simulationResultStep]);

  useEffect(() => {
    const data = new Date();
    const currentData = `${data.getFullYear()}-${("0" + (data.getMonth() + 1)).slice(-2)}-${("0" + data.getDate()).slice(-2)}`;
    const currentTime = new Date(`${currentData} ${data.getHours()}:${data.getMinutes()}`);
    const openingTime = new Date(`${currentData} 06:00`);
    const closingTime = new Date(`${currentData} 23:00`);

    if (!adhesionContractData && !routeParams?.step) {
        fetchTerm();
    } else if (adhesionContractData || routeParams?.step === 'adhesion-contract') {
      handleProposalUserData({ responseProposalData: adhesionContractData });
    }

    // if (
    //   currentTime.getTime() >= openingTime.getTime() &&
    //   currentTime.getTime() <= closingTime.getTime()
    // ) {

    // } else {
    //   openPageWeAreClosed();
    // }
    //waitingHandler();
    //setInterval(() => fetchStatus(), 32500);
  }, []);

  const clearData = () => {
    setSimulationResultModalTitle(null);
    setProposalUserData(null);
    setPdf(null);
    setDocument(null);
    setBiometry(null);
    setContract(null);
    setStatusMessage(null);
    setSimulatedData(null);
    setErrorMessage(null);
  };

  function clearMessages() {
    setStatusMessageAlert(null);
    setStatusMessage(null);
    setErrorMessage(null);
  }

  function checkIfNotError(responseData: any, statusCodeSuccess: number): any {
    if (!responseData) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Não foi possível acesso a API.'
      });

      return false;
    } else if (responseData.data.statusCode !== statusCodeSuccess) {
      if (responseData.data?.dataApi?.details) {
        const errorDetails = responseData.data.dataApi.details.map((e: any) => (
          <div key={e.message}>
            {e.message}
            <br />
          </div>
        ));

        setStatusMessageAlert({
          status: 'error',
          message: errorDetails
        });

        return false;
      } else if (!!responseData.data.dataApi.message) {
        setStatusMessageAlert({
          status: 'error',
          message: responseData.data.dataApi.message,
        });

        return false;
      } else if (!!responseData.data.statusMessage) {
        setStatusMessageAlert({
          status: 'error',
          message: responseData.data.statusMessage,
        });

        return false;
      }
    }

    return true;
  }

  const biometryHandler = () => {
    setPdf(null);
    setDocument(null);
    setContract(null);
    setStatusMessage(null);
    setSimulatedData(null);
    setErrorMessage(null);
    setBiometry('ok');
  };

  async function sendSMSWithProposalLink() {
    await api.post(`log`, {
      id: bankProposalId,
      phone,
    });
  }

  const waitingHandler = () => {
    setPdf(null);
    setDocument(null);
    setContract(null);
    setStatusMessage(null);
    setSimulatedData(null);
    setErrorMessage(null);
    setBiometry(null);
    setErrors({});
    setWaiting('ok');
  };

  const toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };

  const getBase64 = async (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL;
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object

        baseURL = reader.result;
        setSelectedFile(baseURL);
        resolve(baseURL);
      };
    });
  };

  function Clicksign(i) {

    function n(n) {
      var t;
      (e[(t = n).name || t] || []).forEach(function (t) {
        t(n.data);
      });
    }
    var o,
      r,
      t = window.location.protocol + '//' + window.location.host,
      e = {},
      u = function (t) {
        n(t.data);
      };
    return {
      endpoint: 'https://app.clicksign.com',
      origin: t,
      mount: function (t) {
        var n = '/sign/' + i,
          e = '?embedded=true&origin=' + this.origin,
          e = this.endpoint + n + e;
        return (
          (r = window.document.getElementById(t)),
          (o = window.document.createElement('iframe')).setAttribute('src', e),
          o.setAttribute('style', 'width: 100%; height: 100%;'),
          o.setAttribute('allow', 'camera'),
          window.addEventListener('message', u),
          r.appendChild(o)
        );
      },
      unmount: function () {
        return (
          o &&
            (r.removeChild(o),
            (o = r = null),
            window.removeEventListener('message', n)),
          !0
        );
      },
      on: function (t, n) {
        return e[t] || (e[t] = []), e[t].push(n);
      },
      trigger: n,
    };
  }

  // ETAPA 1-  TERMO IN100
  const fetchTerm = () => {
    clickTracking('#simulation-click');

    if (name && cpf && phone) {
      if (visible) {
        clearMessages();

        setAuthorizationTerm(false);

        setStatusMessage(
          'Estamos verificando alguns detalhes, aguarde.',
        );

        openPageLoader();

        api
          .post('/digital-term', {
            value: parseInt(value),
            installment: parseInt(installments.split('x')[0]),
            cpf,
            name,
            phone,
            email,
            reason,
            simulatorForWhatsApp,
          })
          .then((response) => {
            clearMessages();
            let errorMessage: string;

            if (response.data?.digitalTerm?.message) {
              if (response.data?.digitalTerm?.details) {
                setErrorMessage(response.data.digitalTerm.details[0].message);
              } else if (response.data?.digitalTerm?.message) {
                setErrorMessage(response.data.digitalTerm.message);
              }
            } else {
              setUserId(response.data.userId);
              setProposalId(response.data.proposalId);

              if (response.data.expiredIn100 === false) {
                fetchSimulation(response.data.proposalId);
              } else {
                setPdf(
                'data:application/pdf;base64,' + response.data.digitalTerm.documentBase64,
                );

                openPageTermIN100();
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage('Ops, algo ocorreu, tente novamente mais tarde.');
            // setPdf(null);
          });
      }
    }
  };

  const handleResendSMSTokenTerm = () => {
    if (name && cpf && phone) {
      if (visible) {
        clearMessages();
        setButtonTextResendSMSTokenTerm('Enviando o token, aguarde...');

        api
          .get(`token-digital-term?cpf=${cpf}&fullName=${name}&phone=${phone}`)
          .then((response) => {
            clearMessages();
            setButtonTextResendSMSTokenTerm('Enviar novamente');

            if (response.data.sended === true) {
              setStatusMessageAlert({
                status: 'info',
                message: 'Token enviado por SMS para o seu telefone.',
              });
            } else {
              if (response.data?.dataApi?.details) {
                setStatusMessageAlert({
                  status: 'error',
                  message: response.data.dataApi.details[0].message,
                });
              } else if (response.data?.dataApi?.message) {
                setStatusMessageAlert({
                  status: 'error',
                  message: response.data.dataApi.message,
                });
              } else {
                setStatusMessageAlert({
                  status: 'error',
                  message: 'Não foi possível enviar o token para o seu número, clique em "Enviar novamente" para reenviar.',
                });
              }
            }
          })
          .catch((error) => {
            setStatusMessageAlert({
              status: 'error',
              message: 'Não foi possível enviar o token para o seu número, clique em "Enviar novamente" para reenviar.',
            });
          });
      }
    }
  };

  const eraseStatus = () => {
    clearInterval(StatusInterval);
  };

  // ETAPA 2 - ACEITE DO IN100
  async function handleAccept() {
    if (!authorizationTerm) {
      handleResendSMSTokenTerm();
      setAuthorizationTerm(true);
    } else {
      if (tokenSMSDigitalTerm === '') {
        setStatusMessageAlert({
          status: 'error',
          message: 'Digite o token enviado para seu telefone.',
        });
        return;
      }

      setStatusMessage('Verificando token, só um momento.');
      setButtonTextResendSMSTokenTerm('Verificando...');
      setStatusMessageAlert({
        status: 'info',
        message: 'Verificando token, só um momento.',
      });

      const digitaTermAccept = await api
        .post(`digital-term-accept`, {
          userId,
          cpf,
          fullName: name,
          token: tokenSMSDigitalTerm,
          proposalId,
        })
        .catch((error) => {
          setButtonTextResendSMSTokenTerm('Enviar novamente');

          return false;
        });

      if (checkIfNotError(digitaTermAccept, 200)) {
        fetchSimulation(proposalId);
      } else {
        setButtonTextResendSMSTokenTerm('Enviar novamente');
      }
    }
  }

  //ETAPA 3 - SIMULAÇÃO DE VALORES
  const fetchSimulation = (proposalId: string) => {
    clearMessages();
    setStatusMessage('Buscando a melhor opção para você. Aguarde um instante.');
    openPageLoader();

    api
      .post('/request', {
        value: parseInt(value),
        installments: parseInt(installments.split('x')[0]),
        cpf,
        proposalId,
      })
      .then((response) => {
        if (response.data?.dataApi?.id) {
          setSimulatedData(response.data.dataApi);
          setBankProposalId(response.data.dataApi.id);
          setHasPaymentInformation(response.data.dataApi.hasPaymentInformation);

          openPageSimulation();
        } else {
          if (response.data?.dataApi?.details) {
            clearMessages();
            setErrorMessage(response.data.dataApi.details.map((e: any) => (
              <div key={e.message}>
                {e.message}
                <br />
              </div>
            )));
          } else if (response.data?.dataApi?.message) {
            clearMessages();
            setErrorMessage(response.data.dataApi.message);
          } else {
            clearMessages();
            setErrorMessage(response.data);
          }

          openPageLoader();
        }
      })
      .catch(() => setSimulatedData(null));
  };

  useEffect(() => {
    if (bankProposalId && simulationResultStep === 'Adhesion Contract' && (adhesionContractData || routeParams?.step === 'adhesion-contract')) {
      mountClickSignAndVerifySignature(proposalDataAdhesionContractDataLink);
    }
  }, [bankProposalId])

  //ETAPA 4 - PROPOSTA
  const handleContract = () => {
    const finalAvailableLimit = simulatedData.limit.finalAvailableLimit - 500;
    const requestedValueMore1000 = simulatedData.finalAvailableLimit + 1000;

    // if (requestedValueMore1000 < finalAvailableLimit) {
    //   openPageBidOffer();
    // } else {
      sendSMSWithProposalLink();
      openPageComplementaryData();
    // }
  };

  const handleProposalUserData = async ({
    responseProposalData = null,
  } : {
    responseProposalData: any | null;
  }
  ) => {
        setDigitalSignatureUrl(responseProposalData.data.dataApi.digitalSignatureUrl);
        openPageAdhesionContract();
    return;
    if (simulatorForWhatsApp === true) {
      const dataProposal = {
        proposalId: proposalId,
        bankProposalId: bankProposalId,
        documentKey: responseProposalData.data.list.document_key,
        requestSignatureKey: responseProposalData.data.list.request_signature_key,
      }

      setCryptedAdhesionContractDataLink(CryptoAES.encrypt(JSON.stringify(dataProposal), 'TaNoBolso$$$$1234567890').toString());
      openPageAdhesionContract();
    } else {
      openPageAdhesionContract();

      if (adhesionContractData) {
        const dataProposal = JSON.parse((CryptoAES.decrypt(adhesionContractData, 'TaNoBolso$$$$1234567890')).toString(CryptoENC));

        responseProposalData = {
            data: {
              documentBase64: '',
              list: {
                request_signature_key: dataProposal.requestSignatureKey,
                document_key: dataProposal.documentKey,
              }
            }
          };

        setProposalDataAdhesionContractDataLink(responseProposalData);
        setProposalId(dataProposal.proposalId);
        setBankProposalId(dataProposal.bankProposalId);
      } else if (routeParams?.step === 'adhesion-contract') {
        const { data: dataProposal } = await api.get(`/proposals/${proposalId}`);

        responseProposalData = {
          data: {
            documentBase64: '',
            list: {
              request_signature_key: dataProposal.click_sign_signature_key,
              document_key: dataProposal.click_sign_document_key,
            }
          }
        };

        setProposalDataAdhesionContractDataLink(responseProposalData);
        setBankProposalId(dataProposal.bank_proposal_id);
      } else {
        mountClickSignAndVerifySignature(responseProposalData);
      }
    }
  }

  function mountClickSignAndVerifySignature(responseProposalData: any) {
    // Carrega o widget embedded com a request_signature_key
    var widget = Clicksign(responseProposalData.data.list.request_signature_key);

    // Define o endpoint https://sandbox.clicksign.com ou https://app.clicksign.com
    widget.endpoint = process.env.REACT_APP_CLICKSIGN_URL;

    // Define a URL de origem (parametro necessário para utilizar através de WebView)
    widget.origin = process.env.REACT_APP_CLICKSIGN_ORIGIN;

    // Monta o widget no div
    widget.mount('container');

    widget.on('loaded', function (ev) { console.log('loaded!'); });
    widget.on('signed', async function (ev) {
      // widget.unmount();
      await verifySignatureClickSign(responseProposalData.data.list.document_key);
    });
    widget.on('resized', function(height) {
      console.log('resized!');
      // document.getElementById('container').style.height = height+'px';
    });
  }

  async function verifySignatureClickSign(documentKey: string) {
    await new Promise(res => setTimeout(res, 5000));

    await api
      .get(`verify-signature?id=${documentKey}&proposalId=${proposalId}`)
      .then(async (response) => {
        if (!response?.data?.dataApi?.document) {
          // var fileItem = new Blob([response.data.data], {type: 'application/pdf'});
          let fileItem = response.data.data;

          sendDocument(fileItem);
          setIsSelected(true);
        } else {
          await verifySignatureClickSign(documentKey);
        }
      });
  }

  // ETAPA 5 - ENVIO DO CCB

  // const changeHandler = (event) => {
  //   console.log(event.target.files[0], "ARQUIVO");
  //   setSelectedFile(event.target.files[0]);
  //   getBase64(event.target.files[0])
  //     .then((result) => {
  //       let file = result;
  //       sendDocument();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setIsSelected(true);
  // };

  const comprovanteResidenciaHandler = (event) => {
    console.log(event.target.files[0], 'ARQUIVO');
    setSelectedFile(event.target.files[0]);
    getBase64(event.target.files[0])
      .then((result) => {
        let file = result;
        handleTakePhoto(file, 'COMPROVANTE_RESIDENCIA');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendDocument = async (file) => {
    clearData();
    const base64String = btoa(
      new Uint8Array(file).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
      }, ''),
    );

    clearMessages();
    setStatusMessage('Enviando o contrato, aguarde um momento');
    openPageLoader();

    let fileItem = base64String as any;
    // console.log(fileItem)
    await api
      .post(`send-ccb`, {
        id: bankProposalId,
        proposalId,
        document: fileItem,
      })
      .then((response) => {
        clearMessages();

        if (!response.data.dataApi) {
          openPageCompletedRequest();
        }
      })
      .catch((error) => {
        //fetchSimulation()
        console.log(error);
        clearData();

        setDocument('ok');
      });
  };

  // ETAPA 6 e 7
  async function handleTakePhoto(dataUri: any, docType: string) {
    clearMessages();
    setStatusMessage('Enviando, aguarde um momento...');

    let type = '';

    if (docType === 'document') {
      type = 'RG';
    } else if (docType === 'COMPROVANTE_RESIDENCIA') {
      type = 'COMPROVANTE_RESIDENCIA';
    } else if (docType === 'BIOMETRIA_2') {
      type = 'BIOMETRIA_2';
    } else {
      type = 'BIOMETRIA_FACIAL';
    }
    stopMe = false;

    const response = await api
        .post(`send-document`, {
          id: bankProposalId,
          file: dataUri,
          type,
        })
        .then((response) => {
          console.log(response);
          if (!response.data || !response) {
            return true;
            // if (!biometry) {
            //   biometryHandler();
            // } else {
            //   waitingHandler();
            //   createStatus(1);
            //   setInterval(() => fetchStatus(), 32500);
            // }
          } else {
            console.log('then-erro', response.data);

            return response.data;
          }
        })
        .catch((error) => {
          //fetchSimulation()
          console.log('erro', error);

          return error;
        });

      clearMessages();

      return response;
  }

  // ETAPA 10
  const fetchStatus = () => {
    console.log(stopMe, '???');

    if (stopMe) {
      console.log(stopMe, 'XXXXXXXXXXXXXXXXXXXXxx');
      return false;
    }
    api
      .get(`status?id=${bankProposalId}`)
      .then((response) => {
        if (response.data.status == 'EFFECTIVENESS') {
          setEffect(true);
          return;
        }
        if (response.data.status == 'APPROVED') {
          setAproved(true);
          return;
        }
        if (response.data.status == 'REPROVED') {
          setReproved(true);
          return;
        }
        if (response.data.status && response.data.status !== 'WITH_PENDING') {
          return;
        }
        setPendencia(null);
        setHideScreen(true);
        setDocumentoAdicional(null);
        setComprovantesResidencia(null);
        setNacionalidade(null);
        setNomeMae(null);
        setOrgaoExpedidorDocumento(null);
        setOrgaoExpedidorDocumento(null);
        settipoDocumentoAdicional(null);
        settipoDocumentoAdicionalValor(null);
        setphoneCountryCode(null);
        setDDI(null);
        setDDD(null);
        setAreaCode(null);
        setCity(null);
        setNumber(null);
        setNeighborhood(null);
        setPhoneNumber(null);
        setTelefone(null);
        setEmailErro(null);
        setEmailValor(null);
        setAdditionalAttribute(null);
        setCep(null);
        setCidade(null);
        setNumero(null);
        setBairro(null);
        setcomplemento(null);
        setErrors({});

        response.data.forEach(function (value) {
          if (value.name === 'estado') {
            setestado(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'comprovantesResidencia') {
            setComprovantesResidencia(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'dataExpedicaoDocumento') {
            setdataExpedicaoDocumento(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'tipoRua') {
            settipoRua(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'documentoIdentificacao') {
            setdocumentoIdentificacao(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'documentoAdicional') {
            setDocumentoAdicional(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'rua') {
            setrua(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'tipoDocumentoAdicional') {
            settipoDocumentoAdicional(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'ddi') {
            setDDI(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'nacionalidade') {
            setNacionalidade(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'nomeMae') {
            setNomeMae(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'nomePai') {
            setNomePai(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'dataNascimento') {
            setDataNascimento(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'nomeCompleto') {
            setNomeCompleto(JSON.stringify(value));
            setPendencia(true);
          }

          if (value.name === 'orgaoExpedidorDocumento') {
            setOrgaoExpedidorDocumento(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'documentoBiometria') {
            setDocumentoBiometria(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'cidade') {
            setCidade(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'telefone') {
            setTelefone(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'numero') {
            setNumero(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'ddd') {
            setDDD(JSON.stringify(value));
            setPendencia('true');
          }
          if (value.name === 'bairro') {
            setBairro(JSON.stringify(value));
            setPendencia(true);
          }

          if (value.name === 'cep') {
            setCep(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'complemento') {
            setcomplemento(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'email') {
            setEmailErro(JSON.stringify(value));
            setPendencia(true);
          }
          if (value.name === 'ddd') {
            setDDD(JSON.stringify(value));
            setPendencia(true);
          }
        });
        setProposalData(JSON.stringify(response));
        stopMe = true;
        setHideScreen(false);
        setPendencia('true');
      })
      .catch((error) => {
        setErrorMessage('error');

        console.log(error);
      });
  };

  // const handleDocument = () => {
  //   clearData();
  //   setStatusMessage('Agora envie uma foto de seu documento de identificação');

  //   api
  //     .post(`proposal`, {
  //       id: simulatedData.id,
  //     })
  //     .then((response) => {
  //       if (!response.data) {
  //         setContract('data:application/pdf;base64,');
  //       }
  //     })
  //     .catch((error) => {
  //       clearData();

  //       setContract('data:application/pdf;base64,');
  //       console.log(error);
  //     });
  // };

  const contractData = () => (
    <>
      <div className="modal-body">
        <div id="container" style={{ height: '70vh', backgroundColor: '#FFF' }}></div>
        {/* <span>
          Baixe o arquivo e assine digitalmente, depois clique em enviar
          documento
        </span>
        <Document file={contract} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={1} />
        </Document> */}
      </div>

      <div className="modal-footer"></div>
    </>
  );

  const convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const showDocumentoAdicional = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Documento adicional
        </span>
        <input
          onChange={(e: any) => setDocumentoAdicionalValor(e.target.value)}
          placeholder="Documento adicional"
        />
      </div>
    </>
  );

  const showDDI = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          DDI
        </span>
        <InputMask
          mask="+999"
          onChange={(e: any) => setphoneCountryCode(e.target.value)}
          placeholder="DDI"
        />
        <span style={{ color: 'red' }}>{errors['ddi']}</span>
      </div>
    </>
  );
  const showComprovantesResidencia = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Envie um comprovante de residencia
        </span>
        <input
          type="file"
          onChange={comprovanteResidenciaHandler}
          ref={hiddenFileInput}
        />
      </div>
    </>
  );

  const showNacionalidade = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme sua nacionalidade
        </span>
        <input
          onChange={(e: any) => setNacionalidadeValor(e.target.value)}
          placeholder="Confirme sua nacionalidade"
        />
      </div>
    </>
  );

  const showDocumentoBiometria = () => (
    <>
      <div>
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Reenvie sua selfie
        </span>
        <Camera
          idealResolution={{ width: 640, height: 480 }}
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri, 'BIOMETRIA_2');
          }}
        />
      </div>
    </>
  );

  const showNomeMae = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme o nome de sua mãe
        </span>
        <input
          onChange={(e: any) => setNomeMaeValor(e.target.value)}
          placeholder="Confirme o nome de sua mãe"
        />
      </div>
    </>
  );

  const showNomeCompleto = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme seu nome completo
        </span>
        <input
          onChange={(e: any) => setNomeCompletoValor(e.target.value)}
          placeholder="Confirme seu nome complet"
        />
      </div>
    </>
  );
  const showDDD = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme seu DDD
        </span>
        <InputMask
          mask="999"
          onChange={(e: any) => setAreaCode(e.target.value)}
          placeholder="Confirme seu DDD"
        />
        <span style={{ color: 'red' }}>{errors['ddd']}</span>
      </div>
    </>
  );

  const showEmail = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme seu Email
        </span>
        <input
          onChange={(e: any) => setEmailValor(e.target.value)}
          placeholder="Confirme seu Email"
        />
        <span style={{ color: 'red' }}>{errors['email']}</span>
      </div>
    </>
  );

  const showComplemento = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme o complemento de seu endereço
        </span>
        <input
          onChange={(e: any) => setAdditionalAttribute(e.target.value)}
          placeholder="Confirme o complemento de seu endereço"
        />
      </div>
    </>
  );

  const showCidade = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme sua cidade
        </span>
        <input
          onChange={(e: any) => setCity(e.target.value)}
          placeholder="Confirme sua cidade"
        />
        <span style={{ color: 'red' }}>{errors['cidade']}</span>
      </div>
    </>
  );

  const showNumero = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme o numero de seu endereço
        </span>
        <input
          onChange={(e: any) => setNumber(e.target.value)}
          placeholder="Confirme o numero de seu endereço"
        />
        <span style={{ color: 'red' }}>{errors['numero']}</span>
      </div>
    </>
  );

  const showTelefone = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme seu telefone
        </span>
        <input
          onChange={(e: any) => setPhoneNumber(e.target.value)}
          placeholder="Confirme seu telefone"
        />
        <span style={{ color: 'red' }}>{errors['telefone ']}</span>
      </div>
    </>
  );
  const showCep = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme seu cep
        </span>
        <input
          onChange={(e: any) => setZip(e.target.value)}
          placeholder="Confirme seu cep"
        />
      </div>
    </>
  );
  const showBairro = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme seu Bairro
        </span>
        <input
          onChange={(e: any) => setNeighborhood(e.target.value)}
          placeholder="Confirme seu Bairro"
        />
      </div>
    </>
  );

  const showNomePai = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme o nome de seu pai
        </span>
        <input
          onChange={(e: any) => setNomePaiValor(e.target.value)}
          placeholder="Confirme o nome de seu pai"
        />
      </div>
    </>
  );
  const showOrgaoExpedidorDocumento = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme o orgão expedidor de seu documento
        </span>
        <input
          onChange={(e: any) => setDocumentExpeditionStateValor(e.target.value)}
          placeholder="Confirme o orgão expedidor de seu documento"
        />
        <span style={{ color: 'red' }}>
          {errors['orgaoExpedidorDocumento ']}
        </span>
      </div>
    </>
  );
  const showDataNascimento = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Informe sua data de nascimento
        </span>
        <InputMask
          mask="99/99/9999"
          onChange={(e: any) => setDataNascimentoValor(e.target.value)}
          placeholder="Data de nascimento"
        />
        <span style={{ color: 'red' }}>{errors['dataNascimento']}</span>
      </div>
    </>
  );
  const showEstado = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme seu estado
        </span>
        <input
          onChange={(e: any) => setestadoValor(e.target.value)}
          placeholder="Confirme seu estado"
        />
        <span style={{ color: 'red' }}>{errors['estado']}</span>
      </div>
    </>
  );

  const showdataExpedicaoDocumento = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme a data de expedição de seu documento
        </span>
        <InputMask
          mask="99/99/9999"
          onChange={(e: any) => setdataExpedicaoDocumentoValor(e.target.value)}
          placeholder="Confirme a data de expedição de seu documento"
        />
        <span style={{ color: 'red' }}>{errors['dataExpedicaoDocumento']}</span>
      </div>
    </>
  );

  const showdocumentoIdentificacao = () => (
    <>
      <div className="modal-body">
        <span>Agora tire uma selfie</span>
        <Camera
          idealResolution={{ width: 640, height: 480 }}
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri, 'document');
          }}
        />
      </div>
    </>
  );

  const showrua = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme sua rua
        </span>
        <input
          onChange={(e: any) => setruaValor(e.target.value)}
          placeholder="Confirme sua rua"
        />
      </div>
    </>
  );

  const showtiporua = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme o tipo de rua
        </span>
        <input
          onChange={(e: any) => settipoRuaValor(e.target.value)}
          placeholder="Confirme o tipo de rua"
        />
        <span style={{ color: 'red' }}>{errors['tipoRua']}</span>
      </div>
    </>
  );
  const showtipoDocumentoAdicional = () => (
    <>
      <div className="row col-lg-12">
        <span style={{ textAlign: 'left' }} className="col-lg-4">
          Confirme o Tipo de documento Adicional
        </span>
        <input
          onChange={(e: any) => settipoDocumentoAdicionalValor(e.target.value)}
          placeholder="Confirme o Tipo de documento Adicional"
        />
        <span style={{ color: 'red' }}>{errors['documentoAdicional']}</span>
      </div>
    </>
  );
  const BiometryData = () => (
    <>
      <div className="modal-body">
        <span>Agora tire uma selfie</span>
        <Camera
          idealResolution={{ width: 640, height: 480 }}
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri, 'photo');
          }}
        />
      </div>

      {/* <div className="modal-footer">
        <span style={{ width: '100%' }}>
    <Button onClick={handleClick} className="button-accept button-document">Enviar documento</Button>
    <input
        type="file"
        ref={hiddenFileInput}
        onChange={changeHandler}
        style={{display: 'none'}} />
        </span>
      </div> */}
    </>
  );
  const successData = () => (
    <div className="modal-body">
      <div>
        <h2>Parabéns! Solicitação concluida!</h2>
        Aguarde, estamos verificando se existem pendencias{' '}
        <span className="loaderLogo"></span>
      </div>
    </div>
  );

  const effetcData = () => (
    <div className="modal-body">
      <div>
        <h2>
          Aguarde que estamos averbando o seu empréstimo. Assim que concluído,
          você será informado! :)
        </h2>
      </div>
    </div>
  );

  const reprovedData = () => (
    <div className="modal-body">
      <div>
        <h2>Infelizmente não poderemos lhe atender agora. =(</h2>
      </div>
    </div>
  );

  const aprovedData = () => (
    <div className="modal-body">
      <div>
        <h2>
          Aguarde que estamos averbando o seu empréstimo. Assim que concluído,
          você será informado! :)
        </h2>
      </div>
    </div>
  );
  const statusData = () => (
    <div className="modal-body">
      <div>
        {/* <h2>Aguardando</h2> */}
        <div className="">
          {comprovantesResidencia && showComprovantesResidencia()}
          {ddi && showDDI()}
          {ddd && showDDD()}

          {nacionalidade && showNacionalidade()}
          {nomeCompleto && showNomeCompleto()}
          {dataNascimento && showDataNascimento()}

          {nomeMae && showNomeMae()}
          {nomePai && showNomePai()}

          {documentoAdicional && showDocumentoAdicional()}
          {dataExpedicaoDocumento && showdataExpedicaoDocumento()}
          {tipoDocumentoAdicional && showtipoDocumentoAdicional()}
          {orgaoExpedidorDocumento && showOrgaoExpedidorDocumento()}
          {telefone && showTelefone()}
          {emailErro && showEmail()}
          {cep && showCep()}
          {estado && showEstado()}
          {cidade && showCidade()}
          {bairro && showBairro()}
          {tipoRua && showtiporua()}
          {rua && showrua()}
          {numero && showNumero()}
          {complemento && showComplemento()}

          {documentoIdentificacao && showdocumentoIdentificacao()}

          {documentoBiometria && showDocumentoBiometria()}
          {!hasReproved && !haspendencia && !hasEffect && successData()}
          {!hasReproved && hasEffect && !hasAproved && effetcData()}
          {hasReproved && reprovedData()}
          {!hasReproved && hasAproved && aprovedData()}
          {haspendencia && (
            <>
              <Button
                onClick={handleClickUpdate}
                className="button-accept button-document"
              >
                Enviar
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );

  function pageLoader() {
    return (
      <>
        <div className="modal-body">
          {errorMessage && errorMessage !== 'error' ? (
            <div className="alert alert-danger" role="alert">{errorMessage}</div>
          ) : (
            <>
              <div className="alert alert-primary" role="alert">{statusMessage}</div>
              <span className="loaderLogo"></span>
            </>
          )}
        </div>
        <div className="modal-footer"></div>
      </>
    )
  }

  function pageWeAreClosed() {
    return (
      <>
        <div className="modal-body">
          <h3>Que pena, estamos fora do nosso horário de funcionamento, tente amanhã</h3>
        </div>
        <div className="modal-footer"></div>
      </>
    )
  }

  useEffect(() => {
    if (tokenSMSDigitalTerm.length === 6) {
      handleAccept();
    }
  }, [tokenSMSDigitalTerm])

    function toggleAccordionIn100(index: number) {
        if (selectedAccordionIn100 === index) {
            return setSelectedAccordionIn100(null);
        }

        setSelectedAccordionIn100(index);
    }

  function pageTerm() {
    return (
      <>
        <div className="modal-body">
          <div className="container">
            {statusMessageAlert && (
              <div className={statusMessageAlert.status === 'error' ? 'alert alert-danger' : 'alert alert-primary'} role="alert">{statusMessageAlert.message}</div>
            )}
            {!authorizationTerm ? (
              <>
                <h4 style={{marginBottom: '15px'}}>
                  Precisamos de sua permissão para consultar sua margem disponível.<br /><br />
                  Tem dúvidas sobre a consulta? Leia o termo abaixo ou faça o <a href="#" className="linkDownloadTermIn100" onClick={downloadTermIn100}>download</a>.
                </h4>
                <Accordion id={1} title="Termo IN100" selected={selectedAccordionIn100} toggleAccordion={() => toggleAccordionIn100(1)}>
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.log}>
                        <Page pageNumber={1} />
                    </Document>
                </Accordion>
              </>
            ) : (
              <>
                <h4 style={{margin: '15px 0'}}>
                  O token de validação vai ser enviado para o telefone {formatPhoneNumber(phone)}.<br /><br />
                  Digite abaixo para autorizar e continuar.
                </h4>

                <div className="form-row justify-content-center" style={{marginBottom: '15px'}}>
                  <div className="col-auto">
                    <OtpInput
                      inputStyle="otpInput"
                      value={tokenSMSDigitalTerm}
                      onChange={(value: any) => setTokenSMSDigitalTerm(value)}
                      numInputs={6}
                      isInputNum={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <p>Não recebeu o token?</p>
                  </div>
                  <div className="col-md-12">
                    <button onClick={handleResendSMSTokenTerm} type="button" className="btn btn-danger btn-sm" disabled={buttonTextResendSMSTokenTerm !== 'Enviar novamente' ? true : false}>{buttonTextResendSMSTokenTerm}</button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <div className="form-row justify-content-center">
            <div className="form-group col-md-12">
              {!authorizationTerm && (
                <button type="button" className="btn btn-primary" onClick={handleAccept}>{!authorizationTerm ? 'Concordar' : 'Autorizar'}</button>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  function pageSimulation() {
    const regexMoney = /\d(?=(\d{3})+,)/g;
    const financedAmount = String(simulatedData.financedAmount.toFixed(2).replace('.', ',').replace(regexMoney, '$&.')).split(',')[0];
    const financedAmountCents = String(simulatedData.financedAmount).substr(-2);
    const availablePaymentCapacity = simulatedData.availablePaymentCapacity.toFixed(2).replace('.', ',').replace(regexMoney, '$&.');
    const operationGrossValue = simulatedData.operationGrossValue.toFixed(2).replace('.', ',').replace(regexMoney, '$&.');
    const installments = simulatedData.installments;
    const iofValue = simulatedData.iof.toFixed(2).replace('.', ',');
    const iofPercent = simulatedData.percentageCompositionCet.iof;
    const monthlyRate = simulatedData.rate.toFixed(2).replace('.', ',');
    const yearlyRate = simulatedData.yearlyRate.toFixed(2).replace('.', ',');
    const monthlyCet = simulatedData.monthlyCet.toFixed(2).replace('.', ',');
    const yearlyCet = simulatedData.yearlyCet.toFixed(2).replace('.', ',');

    return (
      <>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="stepBar">
                  <li className="active" key="simulation"><i className="fa fa-money"><strong>Simulação</strong></i></li>
                  <li key="complementaryData"><i className="fa fa-id-card"><strong>Dados Complementares</strong></i></li>
                  <li key="adhesionContract"><i className="fa fa-file-text"><strong>Contrato de adesão</strong></i></li>
                  <li key="completedRequest"><i className="fa fa-check-circle"><strong>Solicitação concluída</strong></i></li>
                </ul>
              </div>
            </div>

            <h3 style={{marginBottom: '15px'}}>Aqui estão os dados da sua simulação.</h3>

            <div className="row justify-content-center">
              <div className="col-md-3 col-sm-6">
                <div className="pricingTable10">
                  <div className="pricingTable-header">
                      <h3 className="heading">Você vai receber</h3>
                      <span className="price-value">
                          <span className="currency">R$</span>
                          <span>{financedAmount}</span>
                          <span className="cents">,{financedAmountCents}</span>
                      </span>
                  </div>
                  <div className="pricing-content">
                      <ul>
                          <li>Valor da parcela: <span>R$ {availablePaymentCapacity}</span></li>
                          <li>N° de parcelas: <span>{installments}</span></li>
                          <li>Total à pagar: <span>R$ {operationGrossValue}</span></li>
                          <li className="info-simulation">
                            IOF: <span>R$ {iofValue}</span><br />
                            Taxa de juros: <span>{monthlyRate}% a.m.</span> / <span>{yearlyRate}% a.a.</span><br />
                            CET: <span>{monthlyCet}% a.m.</span> / <span>{yearlyCet}% a.a.</span><br />
                          </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>

            <h4>Vamos contratar? São só mais 2 passos.</h4>
          </div>
        </div>
        <div className="modal-footer">
            <span style={{ width: '100%' }}>
                <button type="button" className="btn btn-lg btn-primary" onClick={handleContract}>Sim, vamos lá!</button>
            </span>
        </div>
      </>
    )
  }

  function pageBidOffer() {
    const offer = simulatedData.limit.finalAvailableLimit - 500;

    return (
      <BidOffer
        proposalId={proposalId}
        bankProposalId={bankProposalId}
        offer={offer}
      />
    )
  }

  function pageComplementaryData() {
    return (
      <ComplementaryData
        name={name}
        cpf={cpf}
        phone={phone}
        email={email}
        proposalId={proposalId}
        bankProposalId={bankProposalId}
        hasPaymentInformation={hasPaymentInformation}
        handleProposalUserData={handleProposalUserData}
        getBase64={getBase64}
        faker={faker}
        simulatorForWhatsApp={simulatorForWhatsApp}
      />
    )
  }

  function pageAdhesionContract() {
    const linkForWhatsApp = `${process.env.REACT_APP_CLICKSIGN_ORIGIN}/simulator/adhesion-contract/${proposalId}`;

    function copyLinkForWhatsApp() {
      navigator.clipboard.writeText(linkForWhatsApp);
      alert('Link copiado com sucesso.');
    }

    function handleOpenPageDigitalSignature() {
        window.open(digitalSignatureUrl, '_blank');
    }

    return (
      <>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="stepBar">
                  <li className="active" key="simulation"><i className="fa fa-money"><strong>Simulação</strong></i></li>
                  <li className="active" key="complementaryData"><i className="fa fa-id-card"><strong>Dados Complementares</strong></i></li>
                  <li className="active" key="adhesionContract"><i className="fa fa-file-text"><strong>Contrato de adesão</strong></i></li>
                  <li key="completedRequest"><i className="fa fa-check-circle"><strong>Solicitação concluída</strong></i></li>
                </ul>
              </div>
            </div>
            {simulatorForWhatsApp === false ? (
              <>
                <h3 style={{marginBottom: '15px'}}>
                  Aqui você será direcionado para realizar a assinatura digital. Vamos lá?
                </h3>
              </>
            ) : (
              <>
                <h3 style={{marginBottom: '15px'}}>
                  Abaixo foi gerado o link para você enviar por WhatsApp para o cliente.
                </h3>
                <div className="form-group col-md-12">
                  <input
                    className="form-control"
                    placeholder=""
                    value={linkForWhatsApp}
                  />
                </div>
                <button className="btn btn-primary" onClick={copyLinkForWhatsApp}>Copiar link</button>
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
            <span style={{ width: '100%' }}>
                <button type="button" className="btn btn-lg btn-primary" onClick={handleOpenPageDigitalSignature}>Sim!</button>
                <button type="button" className="btn btn-lg btn-primary" onClick={handleOpenPageDigitalSignature}>Sim!</button>
            </span>
        </div>
      </>
    )
  }

  function pageIdentificationDocument() {
    return (
      <>
        <div className="modal-body">
          <div className="container">
            {errorMessage && (
              <div className="alert alert-danger" role="alert">{errorMessage}</div>
            )}
            {statusMessage && (
              <div className="alert alert-primary" role="alert">{statusMessage}</div>
            )}
            <div className="form-row">
              <div className="col-md-6">
                <img src={imageIdentificationDocument} alt="Documento de identificação" className="img-fluid" />
              </div>
              <div className="form-group col-md-6">
                <label>Foto do documento de identificação (RG OU CNH)</label>
                <input
                  className="form-control"
                  type="file"
                  onChange={(e: any) => setIdentificationDocumentImage(e.target.files[0])}
                />
                <small className="form-text text-muted">O arquivo suportado é JPG.</small>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            onClick={async () => {
              if (identificationDocumentImage) {
                await getBase64(identificationDocumentImage)
                  .then(async (result) => {
                    const documentFile = await handleTakePhoto(result, 'document');

                    clearMessages();

                    if (documentFile === true) {
                      openPageFacialBiometrics();
                    } else {
                      if (documentFile.details) {
                        setErrorMessage(
                          documentFile.details.map((e: any) => (
                            <>
                              {e.message}
                              <br />
                            </>
                          )),
                        );
                      } else {
                        setErrorMessage(documentFile.message);
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                setErrorMessage('Você deve incluir o documento para conseguir enviar.')
              }
            }}
            className="button-accept"
            style={{width: '15rem', textTransform: 'none'}}
          >
            Enviar documento
          </Button>
        </div>
      </>
    )
  }

  function pageFacialBiometrics() {
    return (
      <>
        <div className="modal-body">
          <div className="container">
            {errorMessage && (
              <div className="alert alert-danger" role="alert">{errorMessage}</div>
            )}
            {statusMessage && (
              <div className="alert alert-primary" role="alert">{statusMessage}</div>
            )}
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Câmera</label>
                <Camera
                  isMaxResolution={true}
                  imageType="jpg"
                  idealFacingMode="user"
                  onTakePhotoAnimationDone={(dataUri) => {
                    setFacialBiometricsImage(dataUri);
                  }}
                />
                <small className="form-text text-muted">Centralize bem o seu rosto</small>
              </div>
              <div className="form-group col-md-6">
                  <label>Selfie capturada</label>
                  {facialBiometricsImage && (
                    <img src={facialBiometricsImage} alt="Preview" className="img-fluid" />
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            onClick={async () => {
                if (facialBiometricsImage) {
                  const photoFile = await handleTakePhoto(facialBiometricsImage, 'photo');

                  clearMessages();

                  if (photoFile === true) {
                    openPageCompletedRequest();
                  } else {
                    if (photoFile.details) {
                      setErrorMessage(
                        photoFile.details.map((e: any) => (
                          <>
                            {e.message}
                            <br />
                          </>
                        )),
                      );
                    } else {
                      console.log('teste', photoFile.message);
                      setErrorMessage(photoFile.message);
                    }
                  }
                } else {
                  setErrorMessage('Você deve bater uma foto para conseguir enviar.')
                }
              }
            }
            className="button-accept"
            style={{width: '15rem', textTransform: 'none'}}
          >
            Enviar foto
          </Button>
        </div>
      </>
    )
  }

  function pageCompletedRequest() {
    return (
      <>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="stepBar">
                  <li className="active" key="simulation"><i className="fa fa-money"><strong>Simulação</strong></i></li>
                  <li className="active" key="complementaryData"><i className="fa fa-id-card"><strong>Dados Complementares</strong></i></li>
                  <li className="active" key="adhesionContract"><i className="fa fa-file-text"><strong>Contrato de adesão</strong></i></li>
                  <li className="active" key="completedRequest"><i className="fa fa-check-circle"><strong>Solicitação concluída</strong></i></li>
                </ul>
              </div>
            </div>
            <h3 style={{marginBottom: '30px'}}>
              Parabéns! Sua solicitação foi concluída.
            </h3>
            <h4>Você pode acompanhar o andamento pelo link enviado em seu telefone.</h4>
            <h4 style={{margin: '40px 0'}}>Obrigado!</h4>
            <div>
              <img className="backgroundLogo" src={imageLogo} alt="Ta no bolso" height="80" />
            </div>
          </div>
        </div>
        <div className="modal-footer"></div>
      </>
    )
  }

  function openPageLoader(modalTitle?: string) {
    setSimulationResultModalTitle(null);
    setSimulationResultStep('Loader');
  }

  function openPageWeAreClosed() {
    clearMessages();
    setSimulationResultModalTitle(null);
    setSimulationResultStep('We Are Closed');
  }

  function openPageTermIN100() {
    clearMessages();
    setSimulationResultModalTitle('Termo de autorização');
    setSimulationResultStep('Term IN100');
  }

  function openPageSimulation() {
    clearMessages();
    setSimulationResultModalTitle('Simulação');
    setSimulationResultStep('Simulation');
  }

  function openPageBidOffer() {
    clearMessages();
    setSimulationResultModalTitle('Antes de continuar');
    setSimulationResultStep('Bid Offer');
  }

  function openPageComplementaryData() {
    clearMessages();
    setSimulationResultModalTitle('Dados complementares');
    setSimulationResultStep('Complementary Data');
  }

  function openPageAdhesionContract() {
    clearMessages();
    setSimulationResultModalTitle('Contrato de adesão');
    setSimulationResultStep('Adhesion Contract');
  }

  function openPageIdentificationDocument() {
    clearMessages();
    setSimulationResultModalTitle('Documento de identificação');
    setSimulationResultStep('Identification Document');
  }

  function openPageFacialBiometrics() {
    clearMessages();
    setSimulationResultModalTitle('Biometria facial (selfie)');
    setSimulationResultStep('Facial Biometrics');
  }

  function openPageCompletedRequest() {
    clearMessages();
    setSimulationResultModalTitle('Solicitação concluída');
    setSimulationResultStep('Completed Request');
  }

  return (
    <div
      className={`modal simulation-result ${visible ? 'visible' : ''}`}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title-logo">
              <img src={imageLogo} alt="Ta no bolso" height="30" />
            </div>
            <h5 className="modal-title">{simulationResultModalTitle}</h5>
            <h5
              className="btn-close"
              onClick={() => {
                clearData();
                handleClose();
                clickTracking(`#simulation-close`);
              }}
            >
              Fechar
            </h5>
          </div>

          {simulationResultStep === 'Loader' && pageLoader()}
          {simulationResultStep === 'We Are Closed' && pageWeAreClosed()}
          {simulationResultStep === 'Term IN100' && pageTerm()}
          {simulationResultStep === 'Simulation' && pageSimulation()}
          {simulationResultStep === 'Bid Offer' && pageBidOffer()}
          {simulationResultStep === 'Complementary Data' && pageComplementaryData()}
          {simulationResultStep === 'Adhesion Contract' && pageAdhesionContract()}
          {simulationResultStep === 'Identification Document' && pageIdentificationDocument()}
          {simulationResultStep === 'Facial Biometrics' && pageFacialBiometrics()}
          {simulationResultStep === 'Completed Request' && pageCompletedRequest()}

          {waiting && !hideScreen && statusData()}


        </div>
      </div>
    </div>
  );
};

export default SimulationResult;
