import React, { useState } from 'react';
import { PartnerType } from '../index';

import Accordion from '../../../components/Accordion';

import './styles.scss';

type FaqTypes = {
  partnerPage: boolean;
  partnerInfo?: PartnerType;
};

function Faq({ partnerInfo, partnerPage = false }: FaqTypes) {
  const [selectedAccordionFaq, setSelectedAccordionFaq] = useState<number>(1);

  function toggleAccordionFaq(index: number) {
    if (selectedAccordionFaq === index) {
      return setSelectedAccordionFaq(null);
    }

    setSelectedAccordionFaq(index);
  }

  return (
    <section className="new-faq" id="faq">
      <div className={`container ${partnerPage ? 'partner-fgts' : ''}`}>
        <h5>TIRE SUAS DÚVIDAS</h5>
        <h2>Perguntas Frequentes</h2>

        <div>
          <Accordion
            id={1}
            title="O que é o Saldo FGTS?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(1)}
          >
            O Fundo de Garantia do Tempo de Serviço, denominado FGTS, pode ser
            entendido como reserva financeira, e, uma conta bancária que tem a
            função de poupança.
            <br />
            Todo mês, os empregadores depositam o valor correspondente a 8% do
            salário do trabalhador nessa poupança.
            <br />O saque deste saldo é permitido em algumas situações.
          </Accordion>
          <Accordion
            id={2}
            title="Quais são as três principais maneiras de resgatar o meu FGTS?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(2)}
          >
            1. Saque-rescisão: essa é a forma mais conhecida, onde o trabalhador
            pode sacar o valor total se for demitido sem justa causa, ao se
            aposentar, no caso de doenças graves, compra de imóveis e outros.
            <br />
            2. Saque-aniversário: essa é uma nova forma onde o governo libera
            parte do saldo do trabalhador todo ano, no mês do seu aniversário.
            <br />
            3. Antecipação do saque aniversário: uma forma mais nova ainda, que
            permite que você antecipe diversos saque-aniversário. O saldo no
            FGTS será usado como garantia, e assim o juros acabam sendo mais
            baratos do que em um empréstimo pessoal.
          </Accordion>
          <Accordion
            id={3}
            title="Como pagar as parcelas de antecipação do saque-aniversário?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(3)}
          >
            Ao realizar a antecipação do saque aniversário você autoriza o
            bloqueio de parte do saldo atrelado aos saques aniversário.
            <br />
            Assim, você não precisa se preocupar com o pagamento da parcela, ela
            será feita automaticamente pela Caixa Econômica Federal.
          </Accordion>
          {partnerInfo?.name === 'modal' ||
          partnerInfo?.name === 'boxpag' ||
          partnerInfo?.name === 'vestbank' ||
          partnerInfo?.name === 'rainbow' ||
          partnerInfo?.name === 'winky' ||
          partnerInfo?.name === '7pay' ||
          partnerInfo?.name === 'oxebank' ||
          partnerInfo?.name === 'truckdigital' ||
          partnerInfo?.name === 'jpbank' ||
          partnerInfo?.name === 'quantabank' ||
          partnerInfo?.name === 'pawsbank' ||
          partnerInfo?.name === 'bancoatitude' ||
          partnerInfo?.name === 'bm2bank' ||
          partnerInfo?.name === 'smartpetro' ? (
            <Accordion
              id={4}
              title="Qual é o saldo mínimo para a antecipação do saque-aniversário?"
              selected={selectedAccordionFaq}
              toggleAccordion={() => toggleAccordionFaq(4)}
            >
              O valor mínimo do saldo resgatável deve ser igual ou superior a R$
              200,00 (duzentos reais) atualmente para o saque aniversário.
            </Accordion>
          ) : (
            <Accordion
              id={4}
              title="Qual é o saldo mínimo para a antecipação do saque-aniversário?"
              selected={selectedAccordionFaq}
              toggleAccordion={() => toggleAccordionFaq(4)}
            >
              O valor mínimo do saldo resgatável deve ser igual ou superior a R$
              400,00 (quatrocentos reais) atualmente para o saque aniversário.
            </Accordion>
          )}
          <Accordion
            id={5}
            title="Aderindo a esta modalidade, deixo de receber a multa de 40% em um desligamento involuntário?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(5)}
          >
            Se o desligamento for involuntário, independentemente do sistema
            optado, a multa continua a ser devida em caso de demissão sem justa
            causa.
          </Accordion>
          <Accordion
            id={6}
            title="Como pagar as parcelas do empréstimo?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(6)}
          >
            As parcelas são debitadas uma vez ao ano, automaticamente da sua
            conta FGTS em que o saldo foi usado como garantia de crédito.
            <br />
            Anualmente, quando o seu saque-aniversário for depositado, o valor
            da parcela mais juros serão descontados.
          </Accordion>
          <Accordion
            id={7}
            title="Negativados do SPC/SERASA podem antecipar o saque-aniversário do FGTS?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(7)}
          >
            Sim! Mesmo com restrições no SPC/SERASA, é possível contratar essa
            modalidade de crédito.
            <br />
            Inclusive, dados os baixos juros desta operação de crédito, a Tá No
            Bolso recomenda inclusive para quitação de outras dívidas e ficar
            com o nome limpo no mercado.
            <br />
            <br />* Desde que você esteja com sua situação regular frente a
            Receita Federal
          </Accordion>
          <Accordion
            id={8}
            title="Qual o tempo de espera para solicitar a antecipação?"
            selected={selectedAccordionFaq}
            toggleAccordion={() => toggleAccordionFaq(8)}
          >
            Assim que optar pelo saque-aniversário, você já pode imediatamente
            fazer a solicitação de antecipação das suas parcelas.
          </Accordion>
        </div>
      </div>
    </section>
  );
}

export default Faq;
