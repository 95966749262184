import React, { useRef, useState } from 'react'
import { DocumentDetectorDocumentSide } from '../../../enums/document-detector-document-side.enum';
import { DocumentDetectorDocumentType } from '../../../enums/document-detector-document-type.enum';
import { blobToBase64 } from '../../../../../utils/helpers';
import { getTokenSdkCaf } from '../../../../../utils/token-sdk-caf';
import { api } from '../../../../../services/api';

const { DocumentDetectorSdk } = window['@combateafraude/document-detector'];
const { PassiveFaceLivenessSdk } =
  window['@combateafraude/passive-face-liveness'];
const documentSdk = new DocumentDetectorSdk({
  token: getTokenSdkCaf(),
});
const selfieSdk = new PassiveFaceLivenessSdk({
  token: getTokenSdkCaf(),
});

const SdkPage = ({ statusMessageAlert, setStatusMessageAlert, cpf, handleProcessFinalization, handleNext }) => {
  const [sdkStepNumber, setSdkStepNumber] = useState<number>(1);
  const [sdkDocument, setSdkDocument] = useState<DocumentDetectorDocumentType>(
    DocumentDetectorDocumentType.RG,
  );
  const documentContainerRef = useRef(null);

  const openSdkDocumentDetector = async (
    documentType: DocumentDetectorDocumentType,
    documentSide: DocumentDetectorDocumentSide,
  ) => {
    await documentSdk.initialize();

    const stages = [
      { mode: 'automatic', attempts: 3, duration: 60 },
      { mode: 'manual', attempts: 3, duration: 60 },
      { mode: 'upload', attempts: 0, duration: 0 },
    ];

    const result = await documentSdk.capture(
      documentContainerRef.current,
      stages,
      {
        documentType,
        documentSide,
      },
    );

    await documentSdk.close();
    await documentSdk.dispose();

    if (result?.blob) {
      setStatusMessageAlert({
        status: 'info',
        message: 'Aguarde enquanto enviamos seu documento.',
      });

      const base64File = await blobToBase64(result.blob);

      const { data } = await api.put(
        '/four-all/wallet-manager/wallets/bank-accounts/documents',
        {
          cpf,
          name:
            documentSide === DocumentDetectorDocumentSide.FRONT
              ? 'FRONT_DOCUMENT'
              : 'BACK_DOCUMENT',
          fileType: documentType.toUpperCase(),
          file: base64File,
        },
      );

      if (data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: data.message,
        });
        return
      }
      setStatusMessageAlert(null);
      setSdkStepNumber((step) => step + 1);
    }
  };

  const openSdkSelfie = async (personId: string) => {
    await selfieSdk.initialize();

    const stages = [
      { mode: 'automatic', attempts: 3, duration: 60 },
      { mode: 'manual', attempts: 0, duration: 0 },
    ];

    const result = await selfieSdk.capture(
      documentContainerRef.current,
      stages,
      {
        personData: {
          personId,
        },
      },
    );

    await selfieSdk.close();
    await selfieSdk.dispose();

    if (result?.blob) {
      setStatusMessageAlert({
        status: 'info',
        message: 'Aguarde enquanto enviamos sua foto.',
      });

      const base64File = (await blobToBase64(result.blob)).replace(
        '/9j/4QEqRXh',
        '/9j/4AAQSkZ',
      );

      const { data } = await api.put(
        '/four-all/wallet-manager/wallets/bank-accounts/documents',
        {
          cpf: personId,
          name: 'SELFIE',
          fileType: DocumentDetectorDocumentType.RG.toUpperCase(),
          file: base64File,
        },
      );

      if (data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: data.message,
        });
        return
      }

      setStatusMessageAlert(null);
      setSdkStepNumber((step) => step + 1);
      handleProcessFinalization();
    }
  };

  return (
    <>
      <div className="modal-body customer-data data-fields">
        <div className="container">
          <div className="row">
            <div ref={documentContainerRef}></div>
          </div>
          <form onSubmit={handleNext} className="form-sdk-data">
            {sdkStepNumber === 1 && (
              <>
                <div className="form-sdk-row">
                  <div className="sdk first-page">
                    <h3>
                      Agora precisaremos da foto de um documento válido e
                      a sua selfie.
                    </h3>
                    <h4>
                      Por gentileza informar o documento que será
                      utilizado no processo:
                    </h4>
                    <div className="sdk-radio-container">
                      <label>
                        RG
                        <input
                          type="radio"
                          name="sdk-document"
                          value={DocumentDetectorDocumentType.RG}
                          checked={
                            sdkDocument ===
                            DocumentDetectorDocumentType.RG
                          }
                          onChange={(e) =>
                            setSdkDocument(
                              e.target
                                .value as unknown as DocumentDetectorDocumentType,
                            )
                          }
                        />
                      </label>
                      <label>
                        CNH
                        <input
                          type="radio"
                          name="sdk-document"
                          value={DocumentDetectorDocumentType.CNH}
                          checked={
                            sdkDocument ===
                            DocumentDetectorDocumentType.CNH
                          }
                          onChange={(e) =>
                            setSdkDocument(
                              e.target
                                .value as unknown as DocumentDetectorDocumentType,
                            )
                          }
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {sdkStepNumber === 2 && (
              <>
                <div className="form-sdk-row">
                  <div className="sdk second-page">
                    <h4>
                      Agora precisamos que você fotografe a frente do seu
                      documento:
                    </h4>
                    <button
                      onClick={() =>
                        openSdkDocumentDetector(
                          sdkDocument,
                          DocumentDetectorDocumentSide.FRONT,
                        )
                      }
                    >
                      Abrir câmera
                    </button>
                  </div>
                </div>
              </>
            )}
            {sdkStepNumber === 3 && (
              <>
                <div className="form-sdk-row">
                  <div className="sdk third-page">
                    <h4>
                      Agora precisamos que você fotografe o verso do seu
                      documento:
                    </h4>
                    <button
                      onClick={() =>
                        openSdkDocumentDetector(
                          sdkDocument,
                          DocumentDetectorDocumentSide.BACK,
                        )
                      }
                    >
                      Abrir câmera
                    </button>
                  </div>
                </div>
              </>
            )}
            {sdkStepNumber === 4 && (
              <>
                <div className="form-sdk-row">
                  <div className="sdk fourth-page">
                    <h4>
                      Agora precisamos que você tire uma selfie sua:
                    </h4>
                    <button onClick={() => openSdkSelfie(cpf)}>
                      Abrir câmera
                    </button>
                  </div>
                </div>
              </>
            )}
            {sdkStepNumber === 5 && (
              <>
                <div id="sdk-finalization-page" className="form-sdk-row">
                  {statusMessageAlert && (
                    <div
                      className={
                        statusMessageAlert.status === 'error'
                          ? 'alert alert-danger alert-text-center'
                          : 'alert alert-primary alert-text-center'
                      }
                      role="alert"
                    >
                      {statusMessageAlert.message}
                    </div>
                  )}
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      {sdkStepNumber !== 5 && statusMessageAlert && (
        <div
          className={
            statusMessageAlert.status === 'error'
              ? 'alert alert-danger alert-text-center'
              : 'alert alert-primary alert-text-center'
          }
          role="alert"
        >
          {statusMessageAlert.message}
        </div>
      )}
      <div className="modal-footer data-fields-buttons">
        {sdkStepNumber !== 1 ? (
          ''
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setSdkStepNumber(sdkStepNumber + 1)}
          >
            Prosseguir
          </button>
        )}
      </div>
    </>
  )
}

export default SdkPage
