import React, { FormEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import Layout from '../../../components/Admin/Layout';
import { useAuth } from '../../../hooks/useAuth';
import { api } from '../../../services/api';

import './styles.scss';

type StatusAlertType = {
  status: string;
  message: string;
};

export default function MyData() {
  const { user, handleNewPassword } = useAuth();
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [haveError, setHaveError] = useState<boolean>(false);
  const [id, setId] = useState<string | undefined>(user!.id);
  const [name, setName] = useState<string | undefined>(user!.name);
  const [phone, setPhone] = useState<string | undefined>(user!.phone);
  const [cpf, setCpf] = useState<string | undefined>(user!.cpf);
  const [email, setEmail] = useState<string | undefined>(user!.email);
  const [birthDate, setBirthDate] = useState<string | undefined>(
    user!.birthDate,
  );
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

  useEffect(() => {
    setName(user!.name);
    setId(user!.id);
    setPhone(user!.phone);
    setEmail(user!.email);
    setCpf(user!.cpf);
    setBirthDate(convertDateEnToBr(user!.birthDate!));
  }, [user]);

  function convertDateBrToEn(date: string) {
    const arrayDate = date.split('/');

    return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
  }

  function convertDateEnToBr(date: string) {
    if (!date) {
      return;
    }

    const arrayDate = date.split('-');

    return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
  }

  const onSubmitUserData = async (event: FormEvent) => {
    event.preventDefault();

    const unmaskedCpf = cpf!.replace(/[^\d]+/g, '');
    const unmaskedPhone = phone!.replace(/[^\d]+/g, '');
    const { data } = await api.patch(`/users/${user!.id}`, {
      name,
      cpf: unmaskedCpf,
      email,
      phone: unmaskedPhone,
      birthDate: new Date(convertDateBrToEn(birthDate!)),
    });
  };

  const createNewPassword = async (event: FormEvent) => {
    event.preventDefault();

    setStatusMessageAlert(null);

    if (newPassword === '' || confirmNewPassword === '') {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha os campos acima para continuar.',
      });

      return;
    }

    if (newPassword !== confirmNewPassword) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Os campos de nova senha e confirmar nova senha não conferem.',
      });

      return;
    }

    try {
      const response = await handleNewPassword(
        id!,
        newPassword,
        confirmNewPassword,
      )
        .then()
        .catch();
      if (response) {
        setStatusMessageAlert({
          status: 'info',
          message: response.message as string,
        });
      }
    } catch (error) {
      const { data } = error.response;

      if (typeof data.message === 'string') {
        setStatusMessageAlert({
          status: 'error',
          message: data.message,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: data.message.join('. '),
        });
      }
    }
  };

  return (
    <Layout pageBreadcrumb="Meus dados" pageTitle="Meus dados">
      <div className="container">
        <form onSubmit={onSubmitUserData}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Nome completo*</label>
              <input
                className={`form-control ${haveError && !name && 'is-invalid'}`}
                onChange={(e: any) => setName(e.target.value)}
                value={name}
              />
              <div className="invalid-feedback">
                {haveError && !name && 'Por favor digite seu nome.'}
              </div>
            </div>
            <div className="form-group col-md-6">
              <label>CPF *</label>
              <InputMask
                mask="999.999.999-99"
                className={`form-control ${haveError && !cpf && 'is-invalid'}`}
                autoComplete="off"
                placeholder=""
                value={cpf}
                onChange={(e: any) =>
                  setCpf(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
              <div className="invalid-feedback">
                {haveError && !cpf && 'Por favor digite seu CPF.'}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Telefone *</label>
              <InputMask
                mask="(99) 99999-9999"
                className={`form-control ${
                  haveError && !phone && 'is-invalid'
                }`}
                autoComplete="off"
                placeholder=""
                value={phone}
                onChange={(e: any) =>
                  setPhone(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
              <div className="invalid-feedback">
                {haveError && !phone && 'Por favor digite seu telefone.'}
              </div>
            </div>
            <div className="form-group col-md-6">
              <label>E-mail *</label>
              <input
                className={`form-control ${
                  haveError && !email && 'is-invalid'
                }`}
                onChange={(e: any) => setEmail(e.target.value)}
                value={email}
              />
              <div className="invalid-feedback">
                {haveError && !email && 'Por favor digite seu e-mail.'}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Data de nascimento *</label>
              <InputMask
                mask="99/99/9999"
                className={`form-control ${
                  haveError && !birthDate && 'is-invalid'
                }`}
                autoComplete="off"
                placeholder=""
                value={birthDate}
                onChange={(e: any) => setBirthDate(e.target.value)}
              />
              <div className="invalid-feedback">
                {haveError &&
                  !birthDate &&
                  'Por favor digite sua data de nascimento.'}
              </div>
            </div>
            <div className="form-group col-md-6"></div>
          </div>
          <button
            type="submit"
            className="btn btn-block btn-primary"
            style={{ marginTop: '15px' }}
          >
            Salvar
          </button>
        </form>
        <form onSubmit={createNewPassword} style={{ marginTop: '15px' }}>
          <h3>Alterar senha</h3>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Nova senha *</label>
              <input
                type="password"
                className="form-control"
                onChange={(e: any) => setNewPassword(e.target.value)}
                value={newPassword}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Confirmar nova senha *</label>
              <input
                type="password"
                className="form-control"
                onChange={(e: any) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
              />
            </div>
          </div>
          {statusMessageAlert && (
            <div
              className={
                statusMessageAlert.status === 'error'
                  ? 'alert alert-danger alert-text-center'
                  : 'alert alert-primary alert-text-center'
              }
              role="alert"
            >
              {statusMessageAlert.message}
            </div>
          )}
          <button
            type="submit"
            className="btn btn-block btn-primary"
            style={{ marginTop: '15px' }}
          >
            Salvar
          </button>
        </form>
      </div>
    </Layout>
  );
}
