import React from "react"
import imageLogo from '../../../../../assets/logo/logo_header.svg';

const ConclusionPage = () => {

  return (
    <div className="concluded-message-container">
      <h3>Parabéns! Proposta registrada!</h3>
      <h4>
        Em instantes você receberá uma mensagem de WhatsApp, em seu
        telefone cadastrado, com o link para confirmações de biometria.
        Caso não o receba, não se preocupe que será reenviado
        automaticamente.
      </h4>
      <h4>Obrigado!</h4>
      <div>
        <img
          className="backgroundLogo"
          src={imageLogo}
          alt="Ta no bolso"
        />
      </div>
    </div>
  )
}

export default ConclusionPage
