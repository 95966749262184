import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import imageLogo from '../../assets/logo/logo_header.svg';

export default function PageNotFound() {

    return (
        <div className='outter-container'>
            <div className="page-not-found-container">
                <h1>A página acessada não existe.</h1>
                <p>Por gentileza, visite a nossa<Link to="/">página inicial.</Link></p>
                <img
                    className="backgroundLogo"
                    src={imageLogo}
                    alt="Ta no bolso"
                />
            </div>
        </div>
    );
}