import React from 'react';

import Home from './Home';
import About from './About';
import Benefits from './Benefits';
import SimulateNow from './SimulateNow';
import Faq from './Faq';

import './styles.scss';

function Portabilidade() {
    return (
        <>
            <Home />
            <About />
            <Benefits />
            <SimulateNow />
            <Faq />
        </>
    )
}

export default Portabilidade;
