import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';

import './styles.scss';

function PrivacyPolicy() {
    useEffect(() => {
        scroller.scrollTo(
            'privacy-policy',
            {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -80,
            }
        );
    }, []);

    return (
        <div className="container privacy-policy" id='privacy-policy'>
            <div className="row">
                <div className="col-12">
                    <h1>Política de privacidade</h1>
                    <section className='header'>
                        <p>
                            Este documento, tem por finalidade estabelecer as regras sobre a obtenção, uso e armazenamento dos dados e informações coletadas dos USUÁRIOS,
                            além do registro de suas atividades de acordo com as leis em vigor no Brasil.
                        </p>
                        <p>Quando o USUÁRIO aceita essa Política de Privacidade confere sua livre e expressa concordância com os termos aqui estipulados.</p>
                    </section>

                    <section>
                        <h3>1. Coleta de dados e informações</h3>
                        <p><strong>1.1.</strong> Os dados são coletados quando os USUÁRIOS interagirem com as funcionalidades existentes na Plataforma da Tá no Bolso fornecendo as informações voluntariamente, como na criação de cadastro e na contratação de serviços.</p>
                        <p><strong>1.2.</strong> As informações que a Plataforma da Tá no Bolso poderá coletar incluem, mas não se limitam a:</p>
                        <ul>
                            <li><strong>a)</strong> CPF;</li>
                            <li><strong>b)</strong> RG;</li>
                            <li><strong>c)</strong> Data de Nascimento;</li>
                            <li><strong>d)</strong> E-mail;</li>
                            <li><strong>e)</strong> Número do telefone celular;</li>
                            <li><strong>f)</strong> Endereço;</li>
                            <li><strong>g)</strong> Criação de senha;</li>
                            <li><strong>h)</strong> Registro da Biometria facial;</li>
                            <li><strong>i)</strong> Cópia do RG ou CNH;</li>
                            <li><strong>j)</strong> Dados da conta bancária.</li>
                        </ul>
                        <p>
                            <strong>1.3.</strong> A Plataforma poderá fazer o uso de cookies. O usuário pode configurar o seu navegador de Internet caso deseje bloqueá-los.
                            Caso os cookies seja bloqueados, essa ação poderá limitar algumas funcionalidades da plataforma.
                        </p>
                        <p><strong>1.4.</strong> Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação pelo USUÁRIO, no entanto, respeitarão sempre a legislação vigente, os termos desta política e as opções do USUÁRIO a respeito de sua coleta e armazenamento.</p>
                        <p><strong>1.5.</strong> os dados ou informações prestadas pelos usuários são de responsabilidade dos mesmos bem como mantê-las atualizadas.</p>
                        <p><strong>1.6.</strong> A Plataforma da Tá no Bolso não se destina a menores de 18 (dezesseis) anos de idade. Caso o USUÁRIO não possua a idade mínima exigida, ele não deve acessar e utilizar nossa Plataforma.</p>
                    </section>

                    <section>
                        <h3>2. Uso dos dados</h3>
                        <p><strong>2.1.</strong> Os dados coletados poderão ser utilizados para as seguintes finalidades:</p>
                        <ul>
                            <li><strong>a)</strong> Identificar e autenticar os USUÁRIOS adequadamente;</li>
                            <li><strong>b)</strong> Administrar, prestar os serviços e cumprir as obrigações decorrentes do pedido de contratação dos serviços disponibilizados;</li>
                            <li><strong>c)</strong> Atender adequadamente às suas solicitações e dúvidas;</li>
                            <li><strong>d)</strong> Manter os cadastros atualizados para fins de contato por telefone, correio eletrônico, SMS ou por outros meios de comunicação;</li>
                            <li><strong>e)</strong> Aperfeiçoar seu uso e experiência na Plataforma da Tá no Bolso;</li>
                            <li><strong>f)</strong> Efetuar estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades do comportamento dos USUÁRIOS ao utilizarem a Plataforma Tá no Bolso, realizando tais operações de forma anonimizada;</li>
                            <li><strong>g)</strong> Informar sobre novidades, produtos, serviços, funcionalidades, conteúdos, notícias e demais eventos relevantes e de relacionamento com os USUÁRIOS;</li>
                            <li><strong>h)</strong> Resguardar direitos e obrigações relacionadas ao uso da Plataforma</li>
                            <li><strong>i)</strong> Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa;</li>
                            <li><strong>j)</strong> Compartilhar e ceder os dados coletados para parceiros, contanto que para a finalidade específica de enriquecer sua base de dados e evitar a ocorrência de fraudes e riscos associados;</li>
                            <li><strong>k)</strong> Compartilhar e ceder os dados coletados para as instituições financeiras responsáveis pelo produto/serviço contratado pelo USUÁRIO na Plataforma.</li>
                        </ul>
                        <p><strong>2.2.</strong> A base de dados formada por meio da coleta de dados na Plataforma Tá no Bolso. é de propriedade e responsabilidade da Tá no Bolso, e não será comercializada e/ou alugada para terceiros, podendo ser compartilhada apenas com seus parceiros de negócios.</p>
                        <p><strong>2.3.</strong> O USUÁRIO é corresponsável pelo sigilo de suas informações pessoais e financeiras. O compartilhamento de senhas e dados de acesso configura violação à esta Política de Privacidade.</p>
                        <p><strong>2.4.</strong> Desde já o USUÁRIO está ciente que a Plataforma poderá realizar o enriquecimento da sua base de dados, adicionando informações oriundas de outras fontes legítimas, o qual manifesta consentimento expresso ao concordar com os termos da presente Política de Privacidade.</p>
                        <p><strong>2.5.</strong> Internamente os dados de nossos USUÁRIOS são acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, legalidade, necessidade e relevância para os objetivos da Tá no Bolso, além do compromisso de confidencialidade e preservação da privacidade nos termos desta Política de Privacidade.</p>
                    </section>

                    <section>
                        <h3>3. Registro de atividades</h3>
                        <p><strong>3.1.</strong> A Tá no Bolso poderá registrar todos os acessos efetuadas pelos USUÁRIOS na Plataforma da Tá no Bolso, por meio de logs, incluindo:</p>
                        <ul>
                            <li><strong>a)</strong> Endereço IP e Portal Lógica de Origem dos USUÁRIOS;</li>
                            <li><strong>b)</strong> Ações efetuadas pelos USUÁRIOS na Plataforma;</li>
                            <li><strong>c)</strong> Telas acessadas pelos USUÁRIOS;</li>
                            <li><strong>d)</strong> Datas e horários de cada atividade efetuada, bem como, o acesso do USUÁRIO à Plataforma;</li>
                            <li><strong>e)</strong> Dados sobre o dispositivo utilizado pelo USUÁRIO, em especial: versão de sistema operacional, geolocalização e outros aplicativos instalados, se necessário;</li>
                            <li><strong>f)</strong> Session ID dos USUÁRIOS;</li>
                            <li><strong>g)</strong> Histórico de transações e contratações realizadas;</li>
                            <li><strong>h)</strong> Comportamentos identificados na interação com a Plataforma.</li>
                        </ul>
                        <p><strong>3.2.</strong> Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação dos USUÁRIOS, sempre respeitando os termos desta política e as opções destes a respeito de sua coleta, de seu uso e de seu armazenamento.</p>
                    </section>

                    <section>
                        <h3>4. Armazenamento de dados e informações</h3>
                        <p><strong>4.1.</strong> Os dados coletados estarão armazenados em ambiente seguro e controlado. A Tá no Bolso se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de acessos não autorizados ocasionados por falhas, vírus ou quaisquer outras técnicas de invasão do banco de dados da Plataforma, salvo nos casos em que tiver dolo ou culpa, observado o estado da técnica disponível, uma vez que nenhum sistema de segurança pode ser considerado inviolável.</p>
                        <p><strong>4.2.</strong> Os dados obtidos do USUÁRIO poderão ser armazenados em servidor próprio da Tá no Bolso ou de terceiro contratado para esse fim, sejam eles alocados no Brasil ou no exterior, podendo ainda ser armazenados por meios de tecnologia de cloud computing e/ou outras que surjam futuramente, visando sempre a melhoria e aperfeiçoamento das atividades da Tá no Bolso.</p>
                        <p><strong>4.3.</strong> O USUÁRIO poderá solicitar a exibição, retificação ou ratificação dos dados pessoais que lhe dizem respeito, por meio das ferramentas de atendimento disponibilizadas na Plataforma, bem como a exclusão de todos os dados pessoais coletados e registrados pela Plataforma.</p>
                        <p><strong>4.4.</strong> A Plataforma da Tá no Bolso poderá, para fins de auditoria e preservação de direitos, permanecer com o histórico de registro dos dados do USUÁRIO por até 5 (cinco) anos contados a partir do cancelamento de eventual Conta de Acesso. Este prazo poderá ser estendido nas hipóteses que a lei ou norma regulatória assim estabelecer, para atendimento à ordem judicial ou para preservação de direitos.</p>
                    </section>

                    <section>
                        <h3>5. Disposições gerais</h3>
                        <p><strong>5.1.</strong> O teor desta Política de Privacidade poderá ser atualizado ou modificado a qualquer momento, conforme a finalidade da Plataforma Tá no Bolso, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo ao USUÁRIO verificá-la sempre que efetuar o acesso à Plataforma.</p>
                        <p><strong>5.2.</strong> Os USUÁRIOS deverão entrar em contato em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade, por meio dos canais de atendimento apontados a seguir cujo horário de funcionamento é de segunda a sexta, das 08:00 às 19:00 horas, exceto feriados nacionais</p>
                        <ul>
                            <li><strong>a)</strong> Email: contato@tanobolso.com.br;</li>
                        </ul>
                        <p><strong>5.3.</strong> Caso empresas terceirizadas realizem o processamento de quaisquer dados coletados pela Plataforma Tá no Bolso, deverão respeitar as condições aqui estipuladas, obrigatoriamente.</p>
                        <p><strong>5.4.</strong> Caso alguma disposição desta Política de Privacidade seja considerada ilegal ou ilegítima por autoridade da localidade em que o USUÁRIO resida ou da sua conexão à internet, as demais condições permanecerão em pleno vigor e efeito.</p>
                    </section>

                    <section>
                        <h3>6. Lei aplicável e jurisdição</h3>
                        <p><strong>6.1.</strong> Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca do USUÁRIO para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</p>
                    </section>

                    <section>
                        <h3>7. Glossário</h3>
                        <p><strong>7.1.</strong> Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:</p>
                        <ul>
                            <li><strong>Cloud Computing:</strong> Ou computação em nuvem, é tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de rede de informação comum (p. ex. a Internet), com objetivo de aumentar a disponibilidade dos serviços sustentados.</li>
                            <li><strong>IP:</strong> Abreviatura de Internet Protocol. É um conjunto de números que identifica a conexão de acesso à Internet utilizada pelos USUÁRIOS para acessar a Plataforma Tá no Bolso.</li>
                            <li><strong>Logs ou registros de acesso:</strong> Registros de atividades dos USUÁRIOS efetuadas na Plataforma da Tá no Bolso.</li>
                            <li><strong>Session ID:</strong> Identificação da sessão do visitante no processo de compra de produtos ou quando é efetuado o acesso à área restrita.</li>
                            <li><strong>USUÁRIO:</strong> Pessoa Física que acessa ou interage com as atividades oferecidas pela plataforma.</li>
                        </ul>
                    </section>

                    <section className='footer'>
                        <h5><strong>Atualização:</strong> 20 de julho de 2021.</h5>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
