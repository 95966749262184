import React, { useState, useEffect } from 'react';

import { api } from '../../../services/api';
import { useAuth } from '../../../hooks/useAuth';
import Layout from '../../../components/Admin/Layout';
import Estimate from './Estimate';
import Release from '../../Fgts/Home/Simulator/Release';
import Balance from '../../Fgts/Home/Simulator/Balance';
import Simulation from '../../Fgts/Home/Simulator/Simulation';
import CustomerData from '../../Fgts/Home/Simulator/CustomerData';
import Concluded from '../../Fgts/Home/Simulator/Concluded';

import './styles.scss';

export default function Fgts() {
  const productName: string = 'Saque aniversário FGTS';
  const [partnerId, setPartnerId] = useState<number | null>(null);
  const [proposalId, setProposalId] = useState<string | null>(null);
  const [page, setPage] = useState<string>('Estimate');
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [simulationId, setSimulationId] = useState<string>('');
  const [simulationData, setSimulationData] = useState<{}>({});
  const { user: { cpf, name, phone, email } } = useAuth();

  useEffect(() => {
    setPage('Estimate');
  }, []);

  function changePage(pageName: string) {
    setPage(pageName);
  }

  function changeLoadingModal(loading: boolean) {
    setLoadingModal(loading);
  }

  function goPageSimulationFgts(data: any) {
    setSimulationId(data.id);
    setSimulationData({
      ...data,
      cpf,
      name,
      phone,
      email,
    });
    setPage('Simulation');
  }

  return (
    <Layout pageBreadcrumb="Saque-Aniversário FGTS" pageTitle="Saque-Aniversário FGTS">
      {page === 'Estimate' && <Estimate changePage={changePage} productName={productName} setProposalId={setProposalId} />}
      {page === 'Release' && <Release changePage={changePage} changeLoadingModal={changeLoadingModal} />}
      {page === 'Balance' && <Balance proposalId={proposalId} loading={loadingModal} goPageSimulationFgts={goPageSimulationFgts} changeLoadingModal={changeLoadingModal} cpf={cpf} />}
      {page === 'Simulation' && <Simulation proposalId={proposalId} loading={loadingModal} simulationData={simulationData} changePage={changePage} changeLoadingModal={changeLoadingModal} />}
      {page === 'CustomerData' && <CustomerData proposalId={proposalId} simulationId={simulationId} loading={loadingModal} simulationData={simulationData} changePage={changePage} changeLoadingModal={changeLoadingModal} />}
      {page === 'Concluded' && <Concluded changePage={changePage} changeLoadingModal={changeLoadingModal} />}
    </Layout>
  )
}
