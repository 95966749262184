import React, { useState } from 'react';

import Accordion from '../../../components/Accordion';

import classes from './styles.module.scss';

function Faq() {
    const [selectedAccordionFaq, setSelectedAccordionFaq] = useState<number>(1);

    function toggleAccordionFaq(index: number) {
        if (selectedAccordionFaq === index) {
            return setSelectedAccordionFaq(null);
        }

        setSelectedAccordionFaq(index);
    }

    return (
        <section className={classes.faq} id="faq">
            <div className="container">
                <h5>TIRE SUAS DÚVIDAS</h5>
                <h2>Perguntas Frequentes</h2>

                <div>
                    <Accordion id={1} title="Quais são os prazos máximos para pagamento do Empréstimo Consignado?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(1)}>
                        O Empréstimo Consignado pode ser pago em até 84 meses.
                    </Accordion>
                    <Accordion id={2}  title="O que é Margem Consignável?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(2)}>
                        É o valor máximo que pode ser debitado como pagamento de uma parcela do crédito consignado ou com as despesas do cartão de crédito consignado contratado. Esse valor não pode ultrapassar 35% por mês (30% para o empréstimo consignado e 5% para o cartão de crédito consignado).
                    </Accordion>
                    <Accordion id={3}  title="O que é Cartão de Crédito Consignado?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(3)}>
                        O Cartão de Crédito Consignado é aquele em que o valor mínimo da fatura e é descontado diretamente em folha ou benefício do INSS. Ficando mais prático para você e garante os pagamentos em dia.
                    </Accordion>
                    <Accordion id={4} title="Qual é a taxa de juros do Cartão de Crédito e Empréstimo Consignado?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(4)}>
                        A nossa taxa de juros para aposentados e pensionista é a partir de 1,09%. Vale lembrar que as taxas de juros do consignado são as mais baratas de todos os empréstimos pessoal.
                    </Accordion>
                    <Accordion id={5} title="O que é CET?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(5)}>
                    Custo Efetivo Total (CET) é a taxa que corresponde aos juros do seu empréstimo somado todos os encargos e despesas incidentes em uma operação de crédito.<br /><br />
                    EX: Valor a ser depositado em sua conta: R$10.000,00 com parcela de R$ 202,51 a uma taxa mês de 1,27% em 84 meses.<br /><br />
                    CET = Taxa de Juros + ENCARGOS (IOF + Taxa de Serviço) = 1,36% a.m.
                    </Accordion>
                    <Accordion id={6} title="Como fazer um Empréstimo ou Cartão de Crédito Consignado online?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(6)}>
                        O Agibank aparece em seus documentos porque são os credores das operações de crédito, na qualidade de credor da sua dívida.<br />
                        Os empréstimos concedidos através da Tá no Bolso são realizados pelo Agibank que é Instituição Financeira parceira. Para mais informações, acesse o site dos bancos parceiros ou entre em contato:<br /><br />

                        Central de Relacionamento<br />
                        Capitais: 3004 2221<br />
                        Demais Localidades: 0800 602 0022<br /><br />

                        SAC: 0800 730 0999<br />
                        Ouvidoria: 0800 601 2202
                    </Accordion>
                </div>
            </div>
        </section>
    )
}

export default Faq;
