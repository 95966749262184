import React, { FormEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import Layout from '../../../components/Admin/Layout';
import { api } from '../../../services/api';

import './styles.scss';

type StatusAlertType = {
  status: string;
  message: string;
};

type Integration = {
  integrationName: string;
  integrationUuid: string;
};

type Partner = {
  friendlyName: string;
  uuid: string;
};

export default function CreatePartner() {
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [partnerName, setPartnerName] = useState<string>('');
  const [partnerFriendlyName, setPartnerFriendlyName] = useState<string>('');
  const [cnpj, setCnpj] = useState<string>('');
  const [corporateName, setCorporateName] = useState<string>('');
  const [openingDate, setOpeningDate] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [tnbConsultantCanCreateProposal, setTnbConsultantCanCreateProposal] =
    useState<boolean>(false);
  const [partnerLogo, setPartnerLogo] = useState<string | Blob>('');
  const [partnerComission, setPartnerComission] = useState<string>('');
  const [activePartner] = useState<boolean>(true);
  const [integrationUuid, setIntegrationUuid] = useState<string>('');
  const [ownWhatsAppNumber, setOnwWhatsAppNumber] = useState<string>('');
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [integrationName, setIntegrationName] = useState<string>('');
  const [retailerCode, setRetailerCode] = useState<string>('');
  const [huggyQuestion, setHuggyQuestion] = useState<boolean>(false);
  const [huggyChannelUuid, setHuggyChanelUuid] = useState<string>('');
  const [
    huggyFlowIdProposalFgtsPayContinue,
    setHuggyFlowIdProposalFgtsPayContinue,
  ] = useState<string>('');
  const [
    huggyFlowIdProposalFormalizationLink,
    setHuggyFlowIdProposalFormalizationLink,
  ] = useState<string>('');
  const [hasDefaultIntegration, setHasDefaultIntegration] =
    useState<boolean>(false);
  const [hasParentIntegration, setHasParentIntegration] =
    useState<boolean>(false);
  const [parentIntegrations, setParentIntegrations] = useState<Partner[]>([]);
  const [parentPartnerUuid, setParentPartnerUuid] = useState<string>('');
  const [parentPartnerName, setParentPartnerName] = useState<string>('');

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setPartnerName('Parceiro - Teste');
      setCorporateName('Parceiro Teste Ltda');
      setEmail('contato@tanobolso.com.br');
      setPhoneNumber('47992374203');
      setOnwWhatsAppNumber('48998263717');
      setPartnerFriendlyName('Parceiro Teste');
      setCnpj('09004750000108');
      setOpeningDate('10-10-2010');
      setPartnerComission('2');
      setRetailerCode('23');
    }
  }, []);

  async function getIntegrations() {
    const { data } = await api.get(
      '/integration-product/product/name/Saque aniversário FGTS/integrations',
    );
    setIntegrations(data);

    const integrationDefault = data.filter(
      (integration: { default: boolean }) => integration.default === true,
    );

    setIntegrationName(integrationDefault[0].integration.friendlyName);

    if (integrationDefault) {
      setIntegrationUuid(integrationDefault[0].integration.uuid);
    }
  }

  async function getParentIntegration() {
    const { data } = await api.get('/partners');
    if (data) {
      setParentIntegrations(data.partners);
    }
  }

  useEffect(() => {
    getIntegrations();
    getParentIntegration();
  }, []);

  const changeHuggyInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    isChecked ? setHuggyQuestion(true) : setHuggyQuestion(false);
  };

  const changeConsultantInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    isChecked
      ? setTnbConsultantCanCreateProposal(true)
      : setTnbConsultantCanCreateProposal(false);
  };

  const changeDefaultIntegration = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;

    isChecked
      ? setHasDefaultIntegration(true)
      : setHasDefaultIntegration(false);
  };

  const changeParentIntegration = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;

    isChecked ? setHasParentIntegration(true) : setHasParentIntegration(false);
  };

  const createNewPartner = async (event: FormEvent) => {
    event.preventDefault();
    setStatusMessageAlert(null);

    if (
      partnerName === '' ||
      corporateName === '' ||
      partnerFriendlyName === '' ||
      partnerLogo === '' ||
      cnpj === '' ||
      openingDate === '' ||
      email === '' ||
      phoneNumber === ''
    ) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha os campos acima para continuar.',
      });

      return;
    }

    if (huggyQuestion) {
      if (huggyChannelUuid === '') {
        setStatusMessageAlert({
          status: 'error',
          message: 'Informe o Uuid do canal da Huggy.',
        });

        return;
      }

      if (huggyFlowIdProposalFormalizationLink === '') {
        setStatusMessageAlert({
          status: 'error',
          message: 'Informe o id do flow link de formalização da huggy.',
        });

        return;
      }

      if (huggyFlowIdProposalFgtsPayContinue === '') {
        setStatusMessageAlert({
          status: 'error',
          message: 'Informe o id do flow fgts pay continue a compra da huggy.',
        });

        return;
      }
    }

    try {
      const formData = new FormData();

      if (hasDefaultIntegration) {
        formData.append('defaultIntegration[uuid]', integrationUuid);

        if (integrationName === 'Zipdin' && retailerCode.length > 0)
          formData.append(
            'integrationsProperties[zipdin][storeId]',
            retailerCode,
          );
      }

      if (parentPartnerUuid)
        formData.append('parentPartner[uuid]', parentPartnerUuid);

      formData.append('name', partnerName);
      formData.append('friendlyName', partnerFriendlyName);
      formData.append('cnpj', cnpj);
      formData.append('corporateName', corporateName);
      formData.append('openingDate', openingDate);
      formData.append('email', email);
      formData.append('ownWhatsAppNumber', `55${ownWhatsAppNumber}`);
      formData.append(
        'tnbConsultantCanCreateProposal',
        tnbConsultantCanCreateProposal.toString(),
      );

      if (huggyQuestion) {
        formData.append(
          'integrationsProperties[huggy][channelUuid]',
          huggyChannelUuid,
        );
        formData.append(
          'integrationsProperties[huggy][flowIds][proposalFormalizationLink]',
          huggyFlowIdProposalFormalizationLink,
        );
        formData.append(
          'integrationsProperties[huggy][flowIds][proposalFgtsPayContinue]',
          huggyFlowIdProposalFgtsPayContinue,
        );
      }

      formData.append('active', activePartner.toString());
      formData.append('phone', phoneNumber);
      formData.append('uploadType', 'partners');
      formData.append('logo', partnerLogo);

      const response = await api.post(`/partners`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === 'success') {
        setStatusMessageAlert({
          status: 'info',
          message: 'Parceiro cadastrado com sucesso',
        });
      } else if (response.data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: response.data.message,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu. Tente novamente.',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSelectIntegration = (e: any) => {
    const integrationUuid = e.target.value;
    const index = e.nativeEvent.target.selectedIndex;
    setIntegrationUuid(integrationUuid);
    setIntegrationName(e.nativeEvent.target[index].text);
  };

  const onChangeSelectParentIntegration = (e: any) => {
    const integrationUuid = e.target.value;
    const index = e.nativeEvent.target.selectedIndex;
    setParentPartnerUuid(integrationUuid);
    setParentPartnerName(e.nativeEvent.target[index].text);
  };

  const companyAndTradingName = (e: any) => {
    setPartnerFriendlyName(e.target.value);
    const formattedPartnerName = e.target.value
      .toLowerCase()
      .split(' ')
      .join('')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    setPartnerName(formattedPartnerName);
  };

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      setPartnerLogo(e.target.files[0]);
    }
  };

  return (
    <Layout pageBreadcrumb="Meus dados" pageTitle="Meus dados">
      <div className="container">
        <form
          onSubmit={createNewPartner}
          style={{ marginTop: '15px' }}
          encType="multipart/form"
        >
          <h3>Cadastrar Parceiro</h3>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label>Nome *</label>
              <input
                type="text"
                className="form-control"
                onChange={companyAndTradingName}
                value={partnerFriendlyName}
              />
            </div>
            <div className="form-group col-md-4">
              <label>Razão social *</label>
              <input
                type="text"
                className="form-control"
                onChange={(e: any) => setCorporateName(e.target.value)}
                value={corporateName}
              />
            </div>
            <div className="form-group col-md-4">
              <label>Número de WhatsApp</label>
              <InputMask
                mask="(99) 99999-9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={ownWhatsAppNumber}
                onChange={(e: any) =>
                  setOnwWhatsAppNumber(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
            </div>
            <div className="form-group col-md-4">
              <label>CNPJ *</label>
              <InputMask
                mask="99.999.999/9999-99"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={cnpj}
                onChange={(e: any) =>
                  setCnpj(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
            </div>
            <div className="form-group col-md-4">
              <label>Data de abertura *</label>
              <InputMask
                mask="99/99/9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={openingDate}
                onChange={(e: any) =>
                  setOpeningDate(e.target.value.replaceAll('/', '-'))
                }
              />
            </div>
            <div className="form-group col-md-4">
              <label>E-mail *</label>
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label>Telefone *</label>
              <InputMask
                mask="(99) 99999-9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={phoneNumber}
                onChange={(e: any) =>
                  setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
            </div>
            <div className="form-group col-md-4">
              <label>Comissão - %</label>
              <input
                type="text"
                className="form-control"
                onChange={(e: any) => setPartnerComission(e.target.value)}
                value={partnerComission}
              />
            </div>
            <div className="form-group col-md-4">
              <div className="auth-container">
                <label>Consultor TNB pode criar propostas?</label>
                <input
                  type="checkbox"
                  name="proposal-creation-permission"
                  onChange={(e) => changeConsultantInfo(e)}
                />
              </div>
            </div>
            <div className="form-group col-md-4">
              <div className="auth-container">
                <label>Tem WhatsApp próprio na Huggy?</label>
                <input
                  type="checkbox"
                  name="proposal-creation-permission"
                  onChange={(e) => changeHuggyInfo(e)}
                />
              </div>
              {huggyQuestion ? (
                <>
                  <div className="info-container">
                    <label>Informe o Uuid do canal da Huggy</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e: any) => setHuggyChanelUuid(e.target.value)}
                      value={huggyChannelUuid}
                    />
                  </div>
                  <div className="info-container">Flows ids</div>
                  <div className="info-container">
                    <label>Link de formalização</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e: any) =>
                        setHuggyFlowIdProposalFormalizationLink(e.target.value)
                      }
                      value={huggyFlowIdProposalFormalizationLink}
                    />
                  </div>
                  <div className="info-container">
                    <label>FGTS Pay continue a compra</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e: any) =>
                        setHuggyFlowIdProposalFgtsPayContinue(e.target.value)
                      }
                      value={huggyFlowIdProposalFgtsPayContinue}
                    />
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="form-group col-md-4">
              <div className="auth-container">
                <label>Parceiro possui integração padrão?</label>
                <input
                  type="checkbox"
                  name="proposal-creation-permission"
                  onChange={(e) => changeDefaultIntegration(e)}
                />
              </div>
              {hasDefaultIntegration ? (
                <div className="info-container">
                  <div className="select-container">
                    <label>Integração padrão *</label>
                    <select
                      value={integrationUuid}
                      onChange={onChangeSelectIntegration}
                    >
                      {integrations.map((option: any) => (
                        <option
                          key={option.integration.uuid}
                          value={option.integration.uuid}
                        >
                          {option.integration.friendlyName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                ''
              )}
              {integrationName === 'Zipdin' && hasDefaultIntegration ? (
                <div className="info-container">
                  <label>Informe o código do lojista</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e: any) => setRetailerCode(e.target.value)}
                    value={retailerCode}
                  />
                  <span style={{ fontSize: 10 }}>
                    Caso for lojista Pay, se não deixar em branco.
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="form-group col-md-4">
              <div className="auth-container">
                <label>Parceiro possui parceiro pai?</label>
                <input
                  type="checkbox"
                  name="proposal-creation-permission"
                  onChange={(e) => changeParentIntegration(e)}
                />
              </div>
              {hasParentIntegration ? (
                <div className="info-container">
                  <div className="select-container">
                    <label>Parceiro pai *</label>
                    <select
                      value={parentPartnerUuid}
                      onChange={onChangeSelectParentIntegration}
                    >
                      {parentIntegrations.map((option: any) => (
                        <option key={option.uuid} value={option.uuid}>
                          {option.friendlyName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="form-group col-md-4 logo-upload-container">
              <label>Logo *</label>
              <input
                type="file"
                className="input-file"
                onChange={(e: any) => handleFileChange(e)}
              />
            </div>
          </div>
          {statusMessageAlert && (
            <div
              className={
                statusMessageAlert.status === 'error'
                  ? 'alert alert-danger alert-text-center'
                  : 'alert alert-primary alert-text-center'
              }
              role="alert"
            >
              {statusMessageAlert.message}
            </div>
          )}
          <button
            type="submit"
            className="btn btn-block btn-primary"
            style={{ marginTop: '15px' }}
          >
            Salvar
          </button>
        </form>
      </div>
    </Layout>
  );
}
