import React from 'react';
import './style.scss';

const Button = (props: any) => {
  const {className, ...rest} = props
  return (
    <a
      href="javascript:void(0);"
      className={`button-tnb btn-setting btn-hvr-setting-main scroll text-white btn-hvr ${props.selected && 'selected'} ${className ? className : ''}`}
      {...rest}
    >
      {props.children}
      <span className="btn-hvr-setting btn-hvr-black">
          {!false &&<>
        <span className="btn-hvr-setting-inner">
          <span className="btn-hvr-effect"></span>
          <span className="btn-hvr-effect"></span>
          <span className="btn-hvr-effect"></span>
          <span className="btn-hvr-effect"></span>
        </span>
          </>
          }
      </span>
    </a>
  );
}

export default Button;
