import classes from './styles.module.scss';

function SimulateNow() {
    return (
        <section className={classes.simulateNow}>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <h3>Solicite hoje mesmo o refinanciamento do seu Crédito Consignado</h3>
                    </div>
                    <div className="col-md-5">
                        <button type="button" className="btn btn-lg btn-primary">SIMULE AGORA</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SimulateNow;
