import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';

import './styles.scss';

type SimulationType = {
  proposalId: string;
  loading: boolean;
  simulationData: any;
  changePage: (pageName: string) => void;
  changeLoadingModal: (loading: boolean) => void;
};

type StatusAlertType = {
  status: string;
  message: string;
};

export default function Simulation({
  proposalId,
  loading,
  simulationData,
  changePage,
  changeLoadingModal,
}: SimulationType) {
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const regexMoney = /\d(?=(\d{3})+,)/g;
  const amountLoanDecimal = String(
    simulationData?.liquidValue
      .toFixed(2)
      .replace('.', ',')
      .replace(regexMoney, '$&.'),
  ).split(',')[0];
  const amountLoanCents = String(simulationData?.liquidValue.toFixed(2)).split(
    '.',
  )[1];
  const amountFinanced = simulationData?.financedAmount
    .toFixed(2)
    .replace('.', ',')
    .replace(regexMoney, '$&.');
  const iofValue = simulationData?.iofValue
    .toFixed(2)
    .replace('.', ',')
    .replace(regexMoney, '$&.');
  const amountWithdrawals = simulationData?.numberOfInstallments;
  const monthlyRate = simulationData?.interestRateMonth
    .toFixed(2)
    .replace('.', ',');
  const yearlyRate = simulationData?.interestRateYear
    .toFixed(2)
    .replace('.', ',');
  const totalInterestAmount = simulationData?.totalInterestAmount
    .toFixed(2)
    .replace('.', ',');
  const installments = simulationData?.installments;
  const monthlyCet = simulationData?.totalEffectiveCostMonth
    .toFixed(2)
    .replace('.', ',');
  const yearlyCet = simulationData?.totalEffectiveCostYear
    .toFixed(2)
    .replace('.', ',');
  const registrationFeeValue = simulationData?.registrationFeeValue
    .toFixed(2)
    .replace('.', ',')
    .replace(regexMoney, '$&.');
  const [totalValueInstallments, setTotalValueInstallments] =
    useState<number>(0);

  useEffect(() => {
    const totalValue = simulationData?.sumValueInstallments;

    setTotalValueInstallments(totalValue);
    changeLoadingModal(false);
  }, []);

  function sumInstallments(installments: any) {
    return installments.reduce((sum, currentState) => {
      return sum + currentState.valorTotalParcela;
    }, 0);
  }

  function handleSimulateAgain() {
    changeLoadingModal(true);
    changePage('Balance');
  }

  function handleGoCustomerDataPage() {
    changeLoadingModal(true);
    changePage('CustomerData');
  }

  return (
    <>
      {!loading && (
        <>
          <div className="modal-body simulation">
            <div className="container">
              <div className="row">
                <div>
                  {statusMessageAlert && (
                    <div
                      className={
                        statusMessageAlert.status === 'error'
                          ? 'alert alert-danger'
                          : 'alert alert-primary'
                      }
                      role="alert"
                    >
                      {statusMessageAlert.message}
                    </div>
                  )}
                </div>
              </div>
              <h3>Abaixo estão as informações da simulação.</h3>
              <div className="row justify-content-center">
                <div className="col-md-4 col-sm-6">
                  <div className="pricingTable10">
                    <div className="pricingTable-header">
                      <h3 className="heading">Você vai receber</h3>
                      <span className="price-value">
                        <span className="currency">R$</span>
                        <span>{amountLoanDecimal}</span>
                        <span className="cents">,{amountLoanCents}</span>
                      </span>
                    </div>
                    <div className="pricing-content">
                      <ul>
                        <li>
                          Valor a ser descontado do seu saldo de FGTS:
                          <br></br>
                          <span>R${' '}
                            {totalValueInstallments
                              .toFixed(2)
                              .replace('.', ',')
                              .replace(regexMoney, '$&.')}</span>
                        </li>
                        <li>
                          Qtd. saques: <span>{amountWithdrawals}</span>
                        </li>
                        <li className="info-simulation">
                          IOF: <span>R$ {iofValue}</span>
                          <br />
                          Taxa de juros: <span>{monthlyRate}% a.m.</span> /{' '}
                          <span>{yearlyRate}% a.a.</span>
                          <br />
                          Valor total de juros: <span>R$ {totalInterestAmount}</span>
                          <br />
                          CET: <span>{monthlyCet}% a.m.</span> /{' '}
                          <span>{yearlyCet}% a.a.</span>
                          <br />
                          Taxa de cadastro:{' '}
                          <span>R$ {registrationFeeValue}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-12">
                  <div className="table-responsive">
                    <table className="table table-light table-sm table-hover">
                      <thead>
                        <tr>
                          <th>N° da parcela</th>
                          <th>Valor</th>
                          <th>Data saque</th>
                        </tr>
                      </thead>
                      <tbody>
                        {installments?.map((parcelData: any) => (
                          <tr key={parcelData.number}>
                            <td>{parcelData.number}</td>
                            <td>
                              R${' '}
                              {parcelData.value
                                .toFixed(2)
                                .replace('.', ',')
                                .replace(regexMoney, '$&.')}
                            </td>
                            <td>
                              {format(parseISO(parcelData.date), 'dd/MM/yyyy')}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <thead>
                        <tr>
                          <th
                            colSpan={3}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                           Valor de garantia <span style={{fontSize: '12px', fontStyle: 'italic'}}>(de acordo com as parcelas acima)</span>: R${' '}
                            {totalValueInstallments
                              .toFixed(2)
                              .replace('.', ',')
                              .replace(regexMoney, '$&.')}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              <h2>Vamos contratar?</h2>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSimulateAgain}
            >
              Simular novamente
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleGoCustomerDataPage}
            >
              Sim, vamos lá!
            </button>
          </div>
        </>
      )}
    </>
  );
}
