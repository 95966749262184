import React, { useEffect } from 'react'
import InputMask from 'react-input-mask';

const DataForSimulationPage = (
  {
    name,
    setName,
    cpf,
    setCpf,
    birthDate,
    setBirthDate,
    cellPhoneNumber,
    setCellPhoneNumber,
    email,
    setEmail,
    setFgtsPayPage,
    handleBalance,
    statusMessageAlert
  }
) => {

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setName('João das Couves');
      setEmail('contato@tanobolso.com.br');
      setCpf('15710912034');     
      setBirthDate('27/01/1991');
    }
  }, []);

  return (
    <>
      <h3 className="simulation-fields-title">
        Preencha os dados abaixo para verificar seu saldo FGTS:
      </h3>
      <div
        className="estimate"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <form className="simulation-form">
          <div className="row fields-row">
            <div className="form-group col-md-6">
              <label>Nome completo *</label>
              <input
                className={`form-control`}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <label>CPF *</label>
              <InputMask
                mask="999.999.999-99"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={cpf}
                onChange={(e: any) => setCpf(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Data de nascimento *</label>
              <InputMask
                mask="99/99/9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={birthDate}
                onChange={(e: any) => setBirthDate(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Celular (com WhatsApp) *</label>
              <InputMask
                mask="(99) 99999-9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={cellPhoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCellPhoneNumber(e.target.value)
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label>E-mail *</label>
              <input
                className={`form-control `}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                value={email}
              />
            </div>
          </div>
          <div className="data-fields-buttons">
            <button
              onClick={() => setFgtsPayPage(1)}
              className="btn btn-block btn-primary"
            >
              Voltar
            </button>
            <button
              onClick={handleBalance}
              className="btn btn-block btn-primary"
            >
              Consultar parcelas
            </button>
          </div>
        </form>
        {statusMessageAlert && (
          <div
            className={
              statusMessageAlert.status === 'error'
                ? 'alert alert-danger alert-text-center'
                : 'alert alert-primary alert-text-center'
            }
            role="alert"
          >
            {statusMessageAlert.message}
          </div>
        )}
      </div>
    </>
  )
}

export default DataForSimulationPage
