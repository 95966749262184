import React from 'react';
import { ImSpinner11 } from 'react-icons/im';

import imageLogo from '../../assets/logo/logo_header.svg';

import './style.scss';

type ModalSimulatorType = {
    visible: boolean;
    handleCloseModal: () => void;
    modalTitle?: string;
    loading: boolean;
    partnerPage: boolean;
    partnerLogo?: string;
    children?: React.ReactNode;
}

const defaultChildren = (
    <>
        <div className="modal-body"></div>
        <div className="modal-footer"></div>
    </>
);

function ModalSimulator({
    visible = false,
    handleCloseModal,
    modalTitle = '',
    loading = false,
    partnerPage = false,
    partnerLogo,
    children = defaultChildren,
}: ModalSimulatorType) {
    return (
        <div
            className={`modal modal-simulator ${visible ? 'visible' : ''} ${partnerPage ? 'partner-page-simulator' : ''}`}
            role="dialog"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title-logo">
                            <img src={!partnerPage ? imageLogo : partnerLogo} alt="Ta no bolso" height="30" />
                        </div>
                        <h5 className="modal-title">{modalTitle}</h5>
                        <h5
                            className="btn-close"
                            onClick={handleCloseModal}
                        >
                            Fechar
                        </h5>
                    </div>
                    {loading && (
                        <>
                            <div className="modal-body loading-div">
                              {partnerPage ? (
                                <ImSpinner11 className="spinner" size={64} color='#000' />
                              ) : (
                                <span className="loaderLogo"></span>
                              )}
                            </div>
                            <div className="modal-footer"></div>
                        </>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ModalSimulator;
