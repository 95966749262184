import { FaPhone, FaRegEnvelopeOpen, FaWhatsapp } from 'react-icons/fa';

import { useLandingPage } from '../../../hooks/useLandingPage';

import PeoplePhoneImg from '../../../assets/peoples/people-phone.svg';

import classes from './styles.module.scss';

function Contact() {
    const { handleWhatsAppButton } = useLandingPage();

    return (
        <section className={classes.contact} id="contact">
            <div className="container">
                <h5>FALE CONOSCO</h5>
                <h2>Canais de Atendimento</h2>
            </div>
            <div className={`row ${classes.row}`}>
                <div className={`col-lg-6 ${classes.colLeft}`}>
                    <img className={classes.peoplePhone} src={PeoplePhoneImg} alt="Pessoa falando no telefone" />
                    <h2>SAC</h2>
                    <h5>Escolha a forma como prefere falar conosco.</h5>
                    <div className={classes.contactInfo}>
                        <span className={classes.icon}><FaPhone /></span>
                        <span><a href="tel:08008870578" target="_blank" rel="noopener noreferrer">0800-887-0578</a></span>
                    </div>
                    <div className={classes.contactInfo}>
                        <span className={classes.icon}><FaRegEnvelopeOpen /></span>
                        <span><a href="mailto:contato@tanobolso.com.br" target="_blank" rel="noopener noreferrer">contato@tanobolso.com.br</a></span>
                    </div>
                    <div className={classes.divButtonWhatsApp}>
                        <button
                            type="button"
                            className="btn btn-lg btn-whatsapp"
                            onClick={() => handleWhatsAppButton({
                                text: ''
                            })}
                        >
                            <FaWhatsapp className={classes.icon} /> Fale pelo WhatsApp
                        </button>
                    </div>
                </div>
                <div className={`col-lg-6 ${classes.colColorBlue}`}>
                    <div className={classes.colRight}>
                        <h2>Ouvidoria</h2>
                        <h5>Ouvidoria geral</h5>
                        <div className={classes.contactInfo}>
                            <span className={classes.icon}><FaPhone /></span>
                            <span><a href="tel:08008870578" target="_blank" rel="noopener noreferrer">0800-887-0578</a></span>
                        </div>
                        <div className={classes.contactInfo}>
                            <span className={classes.icon}><FaRegEnvelopeOpen /></span>
                            <span><a href="mailto:ouvidoria@tanobolso.com.br" target="_blank" rel="noopener noreferrer">ouvidoria@tanobolso.com.br</a></span>
                        </div>
                        <h5>Ouvidoria parceiros</h5>
                        <div className={classes.contactInfo}>
                            <span className={classes.icon}><FaPhone /></span>
                            <span><a href="tel:08008870578" target="_blank" rel="noopener noreferrer">0800-887-0578</a></span>
                        </div>
                        <div className={classes.contactInfo}>
                            <span className={classes.icon}><FaRegEnvelopeOpen /></span>
                            <span><a href="mailto:ouvidoria_parceiros@tanobolso.com.br" target="_blank" rel="noopener noreferrer">ouvidoria_parceiros@tanobolso.com.br</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;
