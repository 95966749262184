import { scroller } from 'react-scroll';

import businessWoman from '../../../assets/peoples/business-woman.svg';

import classes from './styles.module.scss';

function handleSimulateNow() {
    scroller.scrollTo(
        'simulate-card',
        {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -80,
        }
    );
}

function SimulateNow() {
    return (
        <section className={classes.simulateNow}>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className={`col-lg-6 ${classes.colLeft}`}>
                        <h3>Faça uma simulação</h3>
                        <p>
                            É rápido e prático, e você já pode<br />
                            solicitar o seu empréstimo se quiser.
                        </p>
                        <button type="button" className="btn btn-lg btn-primary" onClick={handleSimulateNow}>SIMULE AGORA</button>
                    </div>
                    <div className="col-lg-6">
                        <img src={businessWoman} alt="Mulher de negócios" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SimulateNow;
