import React from 'react';

import Slider from './Slider';
import About from './About';
import Testimonial from './Testimonial';
import Contact from './Contact';
import Blog from './Blog';

function Home() {
    return (
        <>
            <Slider />
            <About />
            <Testimonial />
            <Contact />
            <Blog />
        </>
    )
}

export default Home;
