import React, { useEffect, useState } from 'react';
import { MdMarkEmailRead } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { api } from '../../services/api';

import './styles.scss';

type ParamsType = {
    token: string;
}

function EmailConfirmation() {
    const { token } = useParams<ParamsType>();
    const [textEmailConfirmation, setTextEmailConfirmation] = useState<string>('');

    useEffect(() => {
        getEmailConfirmation(token);
    }, [token]);

    async function getEmailConfirmation(token: string) {
        try {
            const { data } = await api.patch(`/auth/${token}`);

            setTextEmailConfirmation(`
            Seu e-mail acaba de ser confirmado com sucesso!<br />
            Agora você pode acessar a sua conta com seu CPF e senha cadastrados anteriormente.<br />
            Caso tenha alguma dúvida, entre em contato por WhatsApp ou por algum de nossas outras formas de contato.
            `);
        } catch (error) {
            setTextEmailConfirmation(`
            Token de confirmação inválido ou expirado!<br />
            Caso tenha alguma dúvida, entre em contato por WhatsApp ou por algum de nossas outras formas de contato.
            `);
        }
    }

    return (
        <div className="container email-confirmation">
            <div className="row">
                <div className="col-12">
                    <MdMarkEmailRead className='icon-email' />
                    <h1>Confirmação de e-mail</h1>
                    <p dangerouslySetInnerHTML={{ __html: textEmailConfirmation }} />
                </div>
            </div>
        </div>
    )
}

export default EmailConfirmation;
