export enum DocumentDetectorDocumentType {
  ANY = 'any',
  RG = 'rg',
  CNH = 'cnh',
  CRLV = 'crlv',
  RNE = 'rne',
  PASSAPORTE = 'passaporte',
  CTPS = 'ctps',
  OTHER = 'other',
}
