import React from 'react'

const SimulationPage = (
  {
    simulationInfo,
    setFgtsPayPage,
    statusMessageAlert,
    handleSimulateAgain,
    integrationName
  }) => {

  // incluir a integração da 4all e remover o default para página 5
  function handleNextPage() {

    switch (integrationName) {
      case 'zipdin':
        setFgtsPayPage(6)
        break;
      case 'qitech':
        setFgtsPayPage(6)
        break;
      default:
        setFgtsPayPage(5)
        break;
    }

  }

  return (
    <div className="parcels-container">
      {simulationInfo ? (
        <>
          <div className="table-responsive">
            <table
              className="table table-light table-sm table-hover"
            >
              <thead>
                <tr>
                  <th>N° de saques antecipados</th>
                  <th>Valor antecipado</th>
                  <th>Valor liberado ao lojista</th>
                  <th>Valor da compra</th>
                  {integrationName === 'zipdin' ? '' : <th>Valor do troco para o cliente</th>}
                </tr>
              </thead>
              <tbody>
                {simulationInfo ? (
                  <tr>
                    <th>{simulationInfo.installments.length}</th>
                    <th>
                      {
                        simulationInfo.formattedValues
                          .sumValueInstallments
                      }
                    </th>
                    <th>{simulationInfo.formattedValues.liquidValue}</th>
                    <th>
                      {simulationInfo.formattedValues.valueToBeneficiary}
                    </th>
                    <th>
                      {integrationName === 'zipdin' ? '' : simulationInfo.formattedValues.amountReceivable}
                    </th>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="loan-info">
            <ul>
              <li>
                Valor a ser descontado do seu saldo de FGTS:{' '}
                <br></br>
                <span>{simulationInfo.formattedValues.sumValueInstallments}</span>
              </li>
              <li>
                Qtd. saques:{' '}
                <span>{simulationInfo.numberOfInstallments}</span>
              </li>
              <li className="info-simulation">
                IOF: <span>R$ {simulationInfo.iofValue}</span>
                <br />
                Taxa de juros:{' '}
                <span>{simulationInfo.formattedValues.interestRateMonth}</span> /{' '}
                <span>{simulationInfo.formattedValues.interestRateYear}</span>
                <br />
                Valor total de juros: <span>{simulationInfo.formattedValues.totalInterestAmount}</span>
                <br />
                CET: <span>
                  {simulationInfo.formattedValues.totalEffectiveCostMonth}
                </span> / <span>{simulationInfo.formattedValues.totalEffectiveCostYear}</span>
                <br />
                Taxa de cadastro:{' '}
                <span>{simulationInfo.formattedValues.registrationFeeValue}</span>
              </li>
            </ul>
          </div>
          <button
            type="button"
            onClick={() => handleNextPage()}
          >
            Prosseguir
          </button>
          {statusMessageAlert && (
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{ marginTop: '20px', maxWidth: '400px' }}
                  className={
                    statusMessageAlert.status === 'error'
                      ? 'alert alert-danger alert-text-center'
                      : 'alert alert-primary alert-text-center'
                  }
                  role="alert"
                >
                  {statusMessageAlert.message}
                </div>
              </div>
            </>
          )}
        </>
      ) : statusMessageAlert ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ marginTop: '20px', maxWidth: '400px' }}
            className={
              statusMessageAlert.status === 'error'
                ? 'alert alert-danger alert-text-center'
                : 'alert alert-primary alert-text-center'
            }
            role="alert"
          >
            {statusMessageAlert.message}
          </div>
        </div>
      ) : (
        ''
      )}
      {statusMessageAlert?.status === 'error' ? (
        <button type="button" onClick={() => handleSimulateAgain()}>
          Tentar novamente
        </button>
      ) : (
        ''
      )}
    </div>
  )
}

export default SimulationPage
