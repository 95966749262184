import React from 'react';
import Layout from '../../../components/Admin/Layout';
import { FaPhone, FaRegEnvelopeOpen, FaWhatsapp } from 'react-icons/fa';

import { useLandingPage } from '../../../hooks/useLandingPage';

import PeoplePhoneImg from '../../../assets/peoples/people-phone.svg';

import './styles.scss';

export default function ServiceChannel() {
  const { handleWhatsAppButton } = useLandingPage();
  const whatsAppPhone = process.env.REACT_APP_WHATSAPP_PHONE;

  return (
    <Layout pageBreadcrumb="Canais de atendimento" pageTitle="Canais de atendimento">
      <div className="service-channel">
        <div className='people-container'>
          <img src={PeoplePhoneImg} alt="Pessoa no telefone celular" />
        </div>
        <div className="box">
          <h2>Escolha a forma que você deseja falar conosco.</h2>
          <div className="infos">
            <div className="contact-info">
              <span className="icon"><FaPhone /></span>
              <span>0800-887-0578</span>
            </div>
            <div className="contact-info">
              <span className="icon"><FaPhone /></span>
              <span>21 96577-3075</span>
            </div>
            <div className="contact-info">
              <span className="icon"><FaRegEnvelopeOpen /></span>
              <span><a href="mailto:contato@tanobolso.com.br" target="_blank" rel="noopener noreferrer">contato@tanobolso.com.br</a></span>
            </div>
            <div className="div-button-whatsapp">
              <button
                type="button"
                className="btn btn-lg btn-whatsapp"
                onClick={() => handleWhatsAppButton({
                  phone: whatsAppPhone,
                  text: ''
                })}
              >
                <FaWhatsapp className="icon" /> Fale pelo WhatsApp
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
