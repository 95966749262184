import React, { useState } from 'react';

import Nav from '../../../components/Admin/Nav';
import Topbar from '../../../components/Admin/Topbar';
import { useAuth } from '../../../hooks/useAuth';

import './styles.scss';

type Props = {
    pageTitle?: string;
    pageBreadcrumb?: string;
    children?: React.ReactNode;
};

export default function Layout({ pageTitle, pageBreadcrumb, children }: Props) {
    const { activeMenu } = useAuth();

    return (
        <div className="containerL">
            <Nav />
            <div className={`main ${activeMenu ? 'active' : ''}`}>
                <Topbar breadcrumb={pageBreadcrumb} title={pageTitle} />
                <div className="content">{children}</div>
                <div className="cardBox" style={{display: 'none'}}>
                    <div className="card">
                        <div>
                            <div className="numbers">1,042</div>
                            <div className="cardName">Card</div>
                        </div>
                        <div className="iconBox">
                            <i className="fa fa-eye" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <div className="numbers">80</div>
                            <div className="cardName">Card</div>
                        </div>
                        <div className="iconBox">
                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <div className="numbers">208</div>
                            <div className="cardName">Card</div>
                        </div>
                        <div className="iconBox">
                            <i className="fa fa-comment" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <div className="numbers">6,042</div>
                            <div className="cardName">Card</div>
                        </div>
                        <div className="iconBox">
                            <i className="fa fa-usd" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
