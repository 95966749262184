import React from 'react'
import { scroller } from 'react-scroll';

import './styles.scss';

type SimulateNowTypes = {
  partnerPage: boolean;
};

function handleSimulateNow() {
    scroller.scrollTo(
        'simulate-card',
        {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -80,
        }
    );
}

function SimulateNow({ partnerPage = false }: SimulateNowTypes) {
    return (
        <section className={`new-simulate-now ${partnerPage ? 'partner-fgts' : ''}`}>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <h3>Solicite hoje mesmo a antecipação do saque aniversário do seu FGTS</h3>
                    </div>
                    <div className="col-md-5">
                        <button type="button" className="btn btn-lg btn-primary" onClick={handleSimulateNow}>SIMULE AGORA</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SimulateNow;
