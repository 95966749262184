import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import '../../styles/global.scss';

import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/Button';

import logoImg from '../../assets/logo/logo_header.svg';

type StatusAlertType = {
  status: string;
  message: string | HTMLElement;
}

export default function ResetPassword() {
  const [statusMessageAlert, setStatusMessageAlert] = useState<StatusAlertType | null>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const { token } = useParams<{ token?: string }>();

  const { loading, handleResetPassword } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === "true") {}
  }, []);

  async function handleChangePassword() {
    setStatusMessageAlert(null);

    if (password === '' || passwordConfirmation === '') {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha o e-mail para continuar.',
      });

      return;
    } else if (password !== passwordConfirmation) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Senhas não conferem, favor verificar.',
      });

      return;
    }

    try {
      const response = await handleResetPassword(token, password, passwordConfirmation).then().catch();

      if (response) {
        setStatusMessageAlert({
          status: 'info',
          message: response.message as string,
        });
      }
    } catch (error) {
      const { data } = error.response;

      if (typeof data.message === "string") {
        setStatusMessageAlert({
          status: 'error',
          message: data.message,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: data.message.join('. '),
        });
      }
    }
  }

  return (
    <div id="page-auth">
      <aside>
        <strong>Alteração de senha</strong>
        <p>Aqui você altera sua senha.</p>
      </aside>
      <main>
        <div className="main-content">
          <div className="logoTnb"><img src={logoImg} alt="Ta no bolso" /></div>
          <form onSubmit={() => {}}>
            <h2>Alterar sua senha</h2>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Senha*</label>
                <input
                  name="password"
                  type="password"
                  className={`form-control`}
                  onChange={(e: any) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Confirmação de senha*</label>
                <input
                  name="password"
                  type="password"
                  className={`form-control`}
                  onChange={(e: any) => setPasswordConfirmation(e.target.value)}
                  value={passwordConfirmation}
                />
              </div>
            </div>
            {statusMessageAlert && (
                <div className={statusMessageAlert.status === 'error' ? 'alert alert-danger alert-text-center' : 'alert alert-primary alert-text-center'} role="alert">{statusMessageAlert.message}</div>
            )}
            <Button type="button" onClick={handleChangePassword}>
              {!loading ? 'Salvar' : 'Salvando...'}
            </Button>
            <p><Link to="/signin">Voltar ao login.</Link></p>
          </form>
        </div>
      </main>
    </div>
  );
}
