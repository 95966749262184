import React, { useEffect } from 'react';
import imageLogo from '../../../../../assets/logo/logo_header.svg';
import './styles.scss';

type ConcludedType = {
  changePage: (pageName: string) => void;
  changeLoadingModal: (loading: boolean) => void;
  concludedMessage?: string;
  partnerLogo?: string;
  integrationName?: string;
};

export default function Concluded({
  changePage,
  changeLoadingModal,
  concludedMessage,
  partnerLogo,
  integrationName,
}: ConcludedType) {
  useEffect(() => {
    changeLoadingModal(false);
  }, []);

  const integrationLogo = `${process.env.REACT_APP_API_URL}/img/partner/${integrationName}-logo.png`

  return (
    <>
      <div className="modal-body concluded">
        <div className="container info-concluded">
          <h3>Parabéns! Sua solicitação foi concluída.</h3>
          <p>{concludedMessage}</p>
          <h4>Obrigado!</h4>
          <div className="logo-container-concluded">
            <img
              className="backgroundLogo"
              src={imageLogo}
              alt="Ta no bolso"
              height="80"
            />
            {integrationName === 'zipdin' ?
              <img
                className="backgroundLogo"
                src={integrationLogo}
                alt="Logo parceiro"
                height="80"
              />
              : ''}
          </div>
        </div>
      </div>
      <div className="modal-footer"></div>
    </>
  );
}
