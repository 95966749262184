import Carousel from 'react-elastic-carousel';
import { FaRegCalendarAlt, FaUserAlt, FaTimes } from 'react-icons/fa';

import './styles.scss';

import divisionGreenYellow from '../../../assets/shapes/division_green_yellow.svg';
import beneficioEducFinImg from '../../../assets/blog/beneficio-educ-fin.jpg';
import seisDicasImg from '../../../assets/blog/blog-6-dicas.jpg';
import educFinPraticaImg from '../../../assets/blog/blog-educ-fin-pratica-modal.jpg';
import educFinImg from '../../../assets/blog/blog-educ-fin.jpg';

function Blog() {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
        { width: 850, itemsToShow: 2 },
        { width: 1150, itemsToShow: 2, itemsToScroll: 1 },
        { width: 1450, itemsToShow: 2 },
        { width: 1750, itemsToShow: 2 },
    ];
    const testimonials = [
        {
            title: 'Conheça os benefícios da educação financeira',
            info: {
                date: '25 de Maio',
                author: 'Maurício Filippon',
            },
            text: `
            A educação financeira promove benefícios para as tomadas de decisão, que acabam refletindo no meio em que a pessoa vive e, por consequência, na sociedade como um todo, uma vez que possibilita um equilíbrio da economia.<br /><br />
            Nos casos em que a população possui um nível adequado de educação financeira, as economias são mais estáveis e podem ofertar taxas de juros reduzidas, crédito mais abundante e o capital é utilizado de forma mais produtiva, ou seja, ganham todos. Do contrário, sem estes conhecimentos, as pessoas se tornam vítimas de taxas de juros altas, crédito caro e escasso, economias instáveis e, por consequência, estão constantemente endividadas.<br /><br />
            Pode-se traçar um paralelo da saúde financeira com a saúde física e mental. Em tese, ambas dependem uma da outra. É notório que quem vive endividado, esgotado mental e fisicamente, tem maior propensão a contrair doenças e ter uma qualidade de vida não muito boa. Isto levará a pessoa a ter menos condições de trabalhar, produzir e buscar alternativas para resolver os problemas. Certamente gastará mais com tratamentos e medicamentos e sua saúde financeira tende a piorar cada vez mais.<br /><br />
            Em contrapartida, a pessoa que consegue manter seu planejamento financeiro em equilíbrio, gastando menos do que ganha, construindo sua reserva, buscando novas fontes de renda e planejando seu futuro, certamente estará com sua saúde física e mental muito melhores, bem mais longe de doenças que possam impactar seu trabalho e fonte de renda, sem a necessidade de tratamentos ou medicamentos, evitando gastos desnecessários e não afetando seu planejamento financeiro.<br /><br />
            Em resumo, adotar a educação financeira como hábito e implementar um planejamento financeiro em sua vida certamente só trará benefícios ao seu dia a dia, seus relacionamentos pessoal e profissional, sua família, seu futuro.<br /><br />
            E então, o que acha de hoje mesmo procurar aprender um pouco mais sobre educação financeira e implementar práticas simples, mas extremamente valiosas ao seu cotidiano? Clique aqui para ler nosso artigo sobre “Educação Financeira na prática!”. Você certamente se surpreenderá com os resultados imediatos resultantes da adoção de um simples planejamento financeiro ou orçamento mensal. Seus hábitos de consumo certamente sofrerão uma mudança e, em muito pouco tempo, você verá sua situação financeira mudar completamente.
            `,
            img: beneficioEducFinImg,
            imgAlt: 'Imagem',
        },
        {
            title: '6 dicas para economizar no dia a dia',
            info: {
                date: '25 de Maio',
                author: 'Maurício Filippon',
            },
            text: `
            O termo economizar é sinônimo de poupar, ou seja, fazer sobrar algo de valor. Em termos financeiros e populares, fazer sobrar algum dinheiro no final do mês.<br /><br />
            Nos tempos atuais, está cada vez mais difícil tal feito no Brasil. Incertezas e preocupações, nos rondam por todo o lado, entes queridos precisando de ajuda, inflação percebida a olhos nus, instabilidade política do país, essa pandemia que não acaba mais e assim por diante.<br /><br />
            Mas isso tudo não é desculpa e não podemos deixar a peteca cair.<br /><br />
            Saúde financeira é um dos principais pilares, se não o principal, para que possamos vencer os desafios que parecem não ter fim.<br /><br />
            Acreditamos que tudo começa com consciência, um mínimo de planejamento, além dedisciplina e acompanhamento no dia a dia.<br /><br />
            Com isso em mente, podemos então partir para a ação, e a Tá No Bolso preparou para você 6 dicas ou etapas para você melhorar sua condição financeira:<br /><br /><br />
            1 - Entenda a sua situação financeira:<br /><br />
            Muitas vezes, não nos sentimos confortáveis de fazermos este levantamento quando nossa saúde financeira não é das melhores, mas a verdade nua e crua é o melhor indicador neste primeiro momento.<br /><br />
            Busque uma ferramenta, que para este item pode ser um aplicativo para o celular, uma planilha ou até mesmo o velho papel e caneta se você se sentir mais confortável. O objetivo aqui é entender melhor a relação entre a sua renda total e as suas despesas.<br /><br />
            Faça duas colunas considerando entradas e despesas mensais. Não se esqueça de colocar os tributos em forma de rateio (se o pagamento for anual) e tarifas bancárias, amortização de dívidas e etc.)<br /><br />
            Ao final deste levantamento, verifique se o valor de sua renda é compatível com as sduas despesas e, a partir deste levantamento, podemos seguir para as dicas seguintes.<br /><br /><br />
            2 - Corte o que é supérfluo:<br /><br />
            Não conheço orçamento pessoal ou familiar que não tenha itens desnecessários ou dispensáveis. Aquela assinatura que você não usa háa meses, o boleto da academia que você não frequenta mais... N, não insinuamos aqui que você não deva cuidar da sua saúde, mas é melhor priorizar dar umas caminhadas ou exercícios ao ar livre então neste momento até que sua saúde financeira esteja em dia. cCaso contrário, você ficará sem saúde financeira e sem saúde física, afinal, se você estiver na academia e preocupado com as contas, de nada adianta. Prioridade e foco é o que este momento pede.<br /><br />
            Pequenos vícios e indulgências diárias também entram aqui, como aquela carteira de cigarro ou a cervejinha “merecida” no final do dia. Afinal, nossa jornada diária é tão dura, que não podemos esquecer de nós, não é? Pensamento errado! Coloque suas finanças minimamente em dia e comece a tratar seus vícios e pequenos prazeres diários como micro conquistas, ou seja, deixa para se dar ao prazer após alguma meta atingida, crie o seu próprio jogo, e de tabela, você ainda vai contar com uma melhora na sua saúde física.<br /><br />
            Lembre-se: , aqui, quanto menos contas, melhor!<br /><br /><br />
            3 - Controle suas despesas variáveis:<br /><br />
            Aqui vamos tratar dos desperdícios propriamente ditos. Contas variáveis como idas ao mercado, conta de luz, água, gás e telefonia, podem ser consideravelmente reduzidas se você tomar mais cuidados no dia a dia. Por exemplo: Sse sua conta de luz é alta, além de se esvair em gritos em casa para que todos não desperdicem, você pode também avaliar se o investimento em lâmpadas de tecnologia LED, e aparelhos de ar-condicionados com tecnologia inverter podem ser um caminho de investir para obter um retorno futuro na conta mensal.<br /><br />
            Se o seu aquecimento de água for a gás, você pode combinar um banho em sériea com a sua turma em casa. Planos integrados e combinados de telefonia também são um ótimo caminho para consolidar serviços necessários gastando menos. Estes são apenas alguns exemplos.<br /><br />
            Colocar àa venda também bens e aparelhos que não mais necessitamos, também é uma ótima maneira de fazermos um dinheirinho eventual. Hoje em dia, plataformas como OLX e Mercado Livre, dentre outras, nos permitem, sem muitos esforços e custas, oferecer praticamente qualquer item na Internet e aproximarmos de possíveis interessados. Tenha em mente sempre a expressão de que “Menos é mais”! Melhor o dinheirinho no bolso a qualquer tipo de bugiganga sem uso e ocupando espaço em nossos lares.<br /><br /><br />
            4 - Planeje-se:<br /><br />
            Ok, gastos desnecessários cortados, contas bem controladas, agora podemos começar a pensar em nos planejarmos melhor.<br /><br />
            Fazer lista de mercado, pesquisa de preços, definir datas certas para compras, comprar roupas antes da estação, negociar descontos, e parar de se aturar em qualquer promoção que vemos pela frente. Se não for necessário, por mais barato que seja, vale mais o seu dinheiro no bolso a qualquer compra impulsiva!<br /><br />
            Faça aqui um orçamento, defina o valor limite para cada item de sua lista de despesas, assim será mais fácil de você acompanhar tudo mês a mês e dificilmente as coisas sairão do controle novamente.<br /><br /><br />
            5 - Tome um empréstimo:<br /><br />
            Sim, acredite, você pode melhorar a sua saúde financeira tomando um empréstimo, seja para quitar dívidas em andamento com um juros menor, seja para investir em algo que possa te dar retorno financeiro adicional, é o que iremos ver na próxima dica.<br /><br /><br />
            6 - Organize melhor seu tempo:<br /><br />
            Você já deve ter ouvido a expressão” “Tempo é Dinheiro!” Ee ela é a mais pura verdade. Após um melhor controle financeiro, trate de organizar melhor o seu tempo. Em época de conhecimento democratizado através da Internet, negócios online, e plataformas de oportunidades, você pode usar seu tempo livre para aprende mais e e gerar receitas adicionais., Iisso pode ser inclusive muito divertido, lembre- se que poupar não é sinôonimo de viver sempre apertado e com o mínimo possível, e que mal tem em você pensar em aumentar a sua renda também. Lembre-se sempre que, que toda a vez que você está perdendo tempo em infinitas timelines, você poderia estar monetizando o seu tempo se estivesser do outro lado. Mesmo aposentadas ou funcionários públicos com dedicação integral, são potenciais empreendedores nesta nova economia digital.<br /><br />
            Mas, tenha sempre em mente que o objetivo neste primeiro momento é sairmos de um orçamento que está no vermelho, para um orçamento mensal minimamente equilibrado, onde o valor da sua renda total ao menos se equivalha ao valor total das suas despesas. Com o equilíbrio atingido, poderemos pensar então em investimentos, tomar um crédito saudável para aquele televisor ou aquela viajem que você tanto almeja, bem como ajudar as pessoas ao seu redor, mas ajude-se a você primeiro.<br /><br />
            Nós da Táa no Bolso acreditamos que todos somos realmente são capazes de melhorarmos suanossa saúde financeira de diversas maneiras, basta querer, começar e ir escolhendo os caminhos que façam mais sentido para você.
            `,
            img: seisDicasImg,
            imgAlt: 'Imagem',
        },
    ];

    return (
        <section className="blog-page-home" id="blog">
            <div className="modal fade" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close">
                                <FaTimes />
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2>Teste</h2>
                            <div className="blog-item-infos">
                                <div>
                                    <FaRegCalendarAlt className="icon" />
                                    <span>122112</span>
                                </div>
                                <div>
                                    <FaUserAlt className="icon" />
                                    <span>121212</span>
                                </div>
                            </div>
                            <p>dasdassdadasda</p>
                        </div>
                        <img className="divisionGreenYellow" src={divisionGreenYellow} alt="Divisão" />
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <h5>SAIBA MAIS</h5>
                <h2>Blog da Tá no Bolso</h2>
                <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={5000}>
                    {testimonials.map((item, index) => (
                        <div key={index} className="blog-item">
                            <img src={item.img} alt={item.imgAlt} />
                            <div className="blog-item-content">
                                <h3>{item.title}</h3>
                                <div className="blog-item-infos">
                                    <div>
                                        <FaRegCalendarAlt className="icon" />
                                        <span>{item.info.date}</span>
                                    </div>
                                    <div>
                                        <FaUserAlt className="icon" />
                                        <span>{item.info.author}</span>
                                    </div>
                                </div>
                                <p>{item.text.substr(0, 100)}...</p>
                                <button type="button" className="btn btn-lg btn-primary" onClick={() => {}}>Ler mais</button>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </section>
    )
}

export default Blog;
