import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = 'http://tanobolso.com.br:3000'
// const BASE_URL = 'https://api.tanobolso.com.br'

class Api {
    
    public ax;
    constructor () {
        this.ax = axios.create({
            baseURL: BASE_URL
        })
    }

    post(url: string, body?: any) {
        return this.ax.post(url, body)
    }

    put(url: string, body?: any) {
        return this.ax.put(url, body)
    }

    get(url: string) {
        return this.ax.get(url)
    }
}

let api = new Api()


export {api}