import React from 'react';
import './styles.scss';
import logoImg from '../../../../assets/logo/logo_header.svg';

let logo: string = logoImg;

const Header = () => {
  return (
    <header className="header-container">
      <a href='/fgts-pay'>
        <img src={logo} alt="tá no BOLSO logo" title="tá no BOLSO" />
      </a>
      {/* <a href="/fgts-pay/signin">Acesse sua conta</a> */}
    </header>
  );
};

export default Header
