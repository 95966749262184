import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { api } from '../../../../services/api';
import Layout from '../../../../components/Admin/Layout';

export type ParamsType = {
  proposalId: string;
};

const DigitalAuthorization = () => {
  const { proposalId } = useParams<ParamsType>();
  const [linkDigitalAuthorization, setLinkDigitalAuthorization] =
    useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    requestDigitalAuthorization();
  }, []);

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const requestDigitalAuthorization = async () => {
    setLoading(true);

    const { data } = await api.post(`/mercantil/request-digital-authorization-proposal-fgts`, {
      proposalId
    });

    if (!!data.dataApi.linkEncurtado) {
      setLinkDigitalAuthorization(data.dataApi.linkEncurtado);
      setLoading(false);
    } else {
      await delay(15000);
      await requestDigitalAuthorization();
    }
  }

  return (
    <Layout pageBreadcrumb="Autorização digital" pageTitle="Link">
      <div>
        <div className="form-row">
          {loading ? (
            <p>Gerando link, isso pode demorar alguns segundos, aguarde...</p>
          ) : (
            <>
              <div className="form-group col-md-12">
                <input
                  className="form-control"
                  placeholder=""
                  value={linkDigitalAuthorization}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DigitalAuthorization;
