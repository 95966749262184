
import { api } from '../../../services/api';

export async function handleFindOrRegister(
  cpf: string, cellPhoneNumber: string, formatDate: (arg0: string) => string,
  birthDate: string, firstRenderProposalUuid: string, name: string, email: string
) {
  const formattedCpf = cpf.replace(/[^0-9]/g, '');
  const formattedCellPhoneNumber = cellPhoneNumber
    .replace(/[^0-9]/g, '')
    .substring(2, 11);
  const cellphoneCode = cellPhoneNumber.substring(1, 3);
  const formattedBirthDate = formatDate(birthDate);

  const data = {
    proposalUuid: firstRenderProposalUuid,
    documentNumber: formattedCpf,
    civilName: name,
    email,
    phone: formattedCellPhoneNumber,
    areaCode: cellphoneCode,
    birthDate: formattedBirthDate,
  };

  const response = await api.post(
    '/four-all/customer/find-or-register',
    data,
  );
  
  return response;
}

export async function handleRegistrationFinish(
  proposalUuid: string, firstRenderProposalUuid: string,
  fourAllRegistrationUuid: string
) {
  const { data } = await api.post(
    `/four-all/customer/registration-finish`,
    {
      proposalUuid: proposalUuid ? proposalUuid : firstRenderProposalUuid,
      fourAllRegistrationUuid,
      agreements: [
        {
          version: '1.0.0',
          type: 'Politica de privacidade',
          location: 'http://tanobolso.com.br/termos',
        },
      ],
    },
  );

  return data;
}

export async function handleDictEntries(
  cpf: string, pixType: string, formattedPixValue: string
) {
  const formattedCpf = cpf.replace(/[^0-9]/g, '');
  const queryParams = {
    document: '23132522805', //formattedCpf,
    keyType: 'EVP', //pixType,
    keyValue: '5b8cc393-4784-4deb-b2a3-e6b567b61cea' //formattedPixValue,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const { data } = await api.get(
    `/four-all/pix/dict/entries?${queryString}`,
  );

  return data;
}

export async function handleBankAccountsNatural(
  postalCode: string, formatDate: (arg0: string) => string, birthDate: string, documentIssueDate: string, documentExpirationDate: string,
  cpf: string, documentType: string, cellPhoneNumber: string, name: string, civilStatus: string, nationality: string, incomeValue: string,
  email: string, motherName: string, address: string, streetNumber: string, neighborhood: string, city: string, uf: string, documentNumber: string,
  documentIssuingAgency: string, documentState: string,
) {
  const formattedZipCode = postalCode.replace(/[^0-9]/g, '');
  const formattedBirthDate = formatDate(birthDate);
  const formattedDocumentDate = formatDate(documentIssueDate);
  const formattedDocumentExpirationDate = formatDate(documentExpirationDate)
  const formattedCpf = cpf.replace(/[^0-9]/g, '');
  const formattedDocumentType = documentType.toUpperCase()
  const formattedCellPhoneNumber = '55' + cellPhoneNumber
    .replace(/[^0-9]/g, '')

  const data = {
    name,
    federalRevenueSituation: 'REGULAR',
    maritalStatus: civilStatus,
    educationStatus: 'GRADUATED',
    birth: {
      date: formattedBirthDate,
      nationality: nationality,
    },
    occupation: {
      cbo: '2521',
      income: incomeValue,
    },
    contacts: {
      email: email,
      telephone: formattedCellPhoneNumber,
    },
    filiation: {
      motherName: motherName,
    },
    address: {
      street: address,
      number: streetNumber,
      complement: 'none',
      neighborhood: neighborhood,
      city: city,
      state: uf,
      zipcode: formattedZipCode,
    },
    documents: [
      {
        type: 'CPF',
        number: formattedCpf
      },
      formattedDocumentType === 'CNH' ?
        {
          type: formattedDocumentType,
          number: documentNumber,
          issuedAt: formattedDocumentDate,
          issuer: documentIssuingAgency,
          state: documentState,
          expiresAt: formattedDocumentExpirationDate
        }
        :
        {
          type: formattedDocumentType,
          number: documentNumber,
          issuedAt: formattedDocumentDate,
          issuer: documentIssuingAgency,
          state: documentState,
        }
    ],
  };

  const response = await api.post(
    '/four-all/wallet-manager/wallets/bank-accounts/natural',
    data,
  );

  return response;
}

export async function verifyTemporaryAccountCreation(
  proposalUuid: string
) {
  const queryParams = {
    proposalUuid,
    type: 'PIX',
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const { data } = await api.get(`/fgts/pay/subaccount?${queryString}`,);

  return data;
}
