import React from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
import {
  TiSocialFacebook,
  TiSocialInstagram,
  TiSocialLinkedin,
} from 'react-icons/ti';
import CookieConsent from 'react-cookie-consent';

import logoImg from '../../assets/logo/logo.svg';
import logoChristmasImg from '../../assets/doodles/logo-christmas.png';
import moneyWomanImg from '../../assets/peoples/money-woman.svg';
import logoCorresponding110Img from '../../assets/other_logos/correspondentes_fbb_110_negativo.png';
import logoCorresponding120Img from '../../assets/other_logos/correspondentes_fbb_120_negativo.png';

import './styles.scss';

function Footer({ fgtsPartner = '' }) {
  const location = useLocation();
  const { partnerParam: partnerParams }: any = useParams();
  const partnerParam = partnerParams ? partnerParams : fgtsPartner;
  const month = new Date().getMonth() + 1;
  let logo = logoImg;

  switch (month) {
    case 9: {
      break;
    }
    case 10: {
      break;
    }
    case 11: {
      break;
    }
    case 12: {
      logo = logoChristmasImg;
      break;
    }
  }

  function handleSimulateNow() {
    scroller.scrollTo('simulate-card', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -80,
    });
  }

  if (
    partnerParam !== 'credmoney'
  ) {
    return (
      <footer className={`${partnerParam ? 'partner-fgts' : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-6">
              <div className="footer-logo-social">
                <img
                  className="footer-logo"
                  src={logo}
                  alt="Logo tá no BOLSO"
                />

                <div className="social-icons">

                </div>
              </div>

              <p className="info-contact">
                Atendimento das 9h às 18h de segunda a sexta, exceto feriados.
                <br />
                <a
                  href="tel:08008870578"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  0800-887-0578
                </a>
                <br />
                <a
                  href="mailto:contato@tanobolso.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contato@tanobolso.com.br
                </a>
              </p>
            </div>
            <div className="col-xs-12 col-lg-6 div-button-people">
              {location.pathname !== '/' && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={handleSimulateNow}
                >
                  SIMULE AGORA
                </button>
              )}
            </div>
          </div>
          <div className="row extra-info">
            <div className="col-xs-12">
              <p>
                OS SERVIÇOS DE CORRESPONDÊNCIA BANCÁRIA SÃO PRESTADOS PELA{' '}
                <strong>TÁ NO BOLSO</strong>. A <strong>TÁ NO BOLSO</strong>{' '}
                PERTENCE À ROCKET TECNOLOGIA, INTELIGÊNCIA DE DADOS E GESTAO DE
                NEGÓCIOS S.A., CNPJ 41.037.801/0001-09. A EMPRESA NÃO É UMA
                INSTITUIÇÃO FINANCEIRA E NÃO REALIZA OPERAÇÕES DE CRÉDITO
                DIRETAMENTE. É UMA PLATAFORMA DIGITAL QUE ATUA COMO
                CORRESPONDENTE BANCÁRIO PARA FACILITAR O PROCESSO DA CONTRATAÇÃO
                DE EMPRÉSTIMOS DE PESSOAS COMO VOCÊ.
              </p>
              <p>
                AS TAXAS DE JUROS E PRAZO DE PAGAMENTO PRATICADOS NOS
                EMPRÉSTIMOS OBSERVAM AS DETERMINAÇÕES DE CADA CONVÊNIO, BEM COMO
                A POLÍTICA DE CRÉDITO DA INSTITUIÇÃO FINANCEIRA A SER UTILIZADA.
              </p>
            </div>
          </div>

          <div className="row link-terms">
            <div className="col-12">
              <Link to="/privacy-policy">Política de privacidade</Link>
              <Link to="/terms-of-use">Termos e condições de uso</Link>
            </div>
          </div>
        </div>
        <CookieConsent
          disableStyles={true}
          buttonClasses="cookie-consent-button"
          containerClasses="cookie-consent-container"
          contentClasses="cookie-consent-content"
          buttonText="Continuar e fechar"
          cookieName="TNB-cookie-consent"
        >
          Utilizamos cookies para melhorar sua experiência em nosso site. Ao
          continuar navegando, você concorda com nossa{' '}
          <a target={'_blank'} href="/privacy-policy">
            Política de Privacidade
          </a>{' '}
        </CookieConsent>
      </footer>
    );
  }

  if (partnerParam === 'credmoney') {
    return (
      <footer className={`${partnerParam ? 'partner-fgts' : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-6" style={{ padding: 0 }}>
              <p className="info-contact" style={{ marginTop: 0 }}>
                Atendimento das 9h às 18h de segunda a sexta, exceto feriados.
                <br />
                <a
                  href="tel:08008870578"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: 'bolder', fontSize: '14px' }}
                >
                  22 99796 37 33
                </a>
                <br />
                <a
                  href="mailto:contato@tanobolso.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: 'bolder', cursor: 'pointer', fontSize: '14px' }}
                >
                  sac@credmoneybrasil.com.br
                </a>
              </p>
              <div className="footer-logo-social">
                <img
                  className="footer-logo"
                  src={logo}
                  alt="Logo tá no BOLSO"
                />
              </div>
            </div>
            <div className="col-xs-12 col-lg-6 div-button-people">
              {location.pathname !== '/' && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={handleSimulateNow}
                >
                  SIMULE AGORA
                </button>
              )}
            </div>
          </div>
          <div className="row extra-info">
            <div className="col-xs-12">
              <p>
                OS SERVIÇOS DE CORRESPONDÊNCIA BANCÁRIA SÃO PRESTADOS PELA <strong>CRED MONEY</strong>. A <strong>CRED MONEY</strong>  PERTENCE À <strong>CAMPOS SOLUCOES E NEGOCIOS LTDA - CNPJ 24.262.805/0001-71</strong>.
              </p>

              <p>
                A EMPRESA NÃO É UMA INSTITUIÇÃO FINANCEIRA E NÃO REALIZA OPERAÇÕES DE CRÉDITO DIRETAMENTE. É UMA PLATAFORMA DIGITAL QUE ATUA COMO CORRESPONDENTE BANCÁRIO PARA FACILITAR O PROCESSO DA CONTRATAÇÃO DE EMPRÉSTIMOS DE PESSOAS COMO VOCÊ.
              </p>
              <p>
                AS TAXAS DE JUROS E PRAZO DE PAGAMENTO PRATICADOS NOS EMPRÉSTIMOS OBSERVAM AS DETERMINAÇÕES DE CADA CONVÊNIO, BEM COMO A POLÍTICA DE CRÉDITO DA INSTITUIÇÃO FINANCEIRA A SER UTILIZADA.
              </p>
            </div>
          </div>
        </div>
        <CookieConsent
          disableStyles={true}
          buttonClasses="cookie-consent-button"
          containerClasses="cookie-consent-container"
          contentClasses="cookie-consent-content"
          buttonText="Continuar e fechar"
          cookieName="TNB-cookie-consent"
        >
          Utilizamos cookies para melhorar sua experiência em nosso site. Ao
          continuar navegando, você concorda com nossa{' '}
          <a target={'_blank'} href="/privacy-policy">
            Política de Privacidade
          </a>{' '}
        </CookieConsent>
      </footer>
    );
  }

  if (!partnerParam) {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-6">
              <div className="footer-logo-social">
                <img
                  className="footer-logo"
                  src={logo}
                  alt="Logo tá no BOLSO"
                />
                <div className="social-icons">
                  <a
                    href="https://www.linkedin.com/company/tanobolso"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialLinkedin />
                  </a>
                  <a
                    href="https://www.facebook.com/tanobolsooficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialFacebook />
                  </a>
                  <a
                    href="https://instagram.com/tanobolsooficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialInstagram />
                  </a>
                </div>
              </div>
              <p className="info-contact">
                Atendimento das 9h às 18h de segunda a sexta, exceto feriados.
                <br />
                <a
                  href="tel:08008870578"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  0800-887-0578
                </a>
                <br />
                <a
                  href="mailto:contato@tanobolso.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contato@tanobolso.com.br
                </a>
              </p>
              <div className="corresponding-logo">
                <img src={logoCorresponding110Img} alt="Correspondente 110" />
                <img src={logoCorresponding120Img} alt="Correspondente 120" />
              </div>
            </div>
            <div className="col-xs-12 col-lg-6 div-button-people">
              {location.pathname !== '/' && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={handleSimulateNow}
                >
                  SIMULE AGORA
                </button>
              )}
              <img
                className="people-money-footer"
                src={moneyWomanImg}
                alt="Mulher feliz com dinheiro"
              />
            </div>
          </div>
          <div className="row extra-info">
            <div className="col-xs-12">
              <p>
                OS SERVIÇOS DE CORRESPONDÊNCIA BANCÁRIA SÃO PRESTADOS PELA{' '}
                <strong>TÁ NO BOLSO</strong>. A <strong>TÁ NO BOLSO</strong>{' '}
                PERTENCE À ROCKET TECNOLOGIA, INTELIGÊNCIA DE DADOS E GESTAO DE
                NEGÓCIOS S.A., CNPJ 41.037.801/0001-09. A EMPRESA NÃO É UMA
                INSTITUIÇÃO FINANCEIRA E NÃO REALIZA OPERAÇÕES DE CRÉDITO
                DIRETAMENTE. É UMA PLATAFORMA DIGITAL QUE ATUA COMO
                CORRESPONDENTE BANCÁRIO PARA FACILITAR O PROCESSO DA CONTRATAÇÃO
                DE EMPRÉSTIMOS DE PESSOAS COMO VOCÊ.
              </p>
              <p>
                AS TAXAS DE JUROS E PRAZO DE PAGAMENTO PRATICADOS NOS
                EMPRÉSTIMOS OBSERVAM AS DETERMINAÇÕES DE CADA CONVÊNIO, BEM COMO
                A POLÍTICA DE CRÉDITO DA INSTITUIÇÃO FINANCEIRA A SER UTILIZADA.
              </p>
            </div>
          </div>

          <div className="row link-terms">
            <div className="col-12">
              <Link to="/privacy-policy">Política de privacidade</Link>
              <Link to="/terms-of-use">Termos e condições de uso</Link>
            </div>
          </div>
        </div>
        <CookieConsent
          disableStyles={true}
          buttonClasses="cookie-consent-button"
          containerClasses="cookie-consent-container"
          contentClasses="cookie-consent-content"
          buttonText="Continuar e fechar"
          cookieName="TNB-cookie-consent"
        >
          Utilizamos cookies para melhorar sua experiência em nosso site. Ao
          continuar navegando, você concorda com nossa{' '}
          <a target={'_blank'} href="/privacy-policy">
            Política de Privacidade
          </a>{' '}
        </CookieConsent>
      </footer>
    );
  }
  return <></>
}

export default Footer;
