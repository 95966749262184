import React, { useState } from "react"

const IntroPage = ({
  orderId,
  isLoading,
  orderValue,
  setFgtsPayPage,
  statusMessageAlert
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const regexMoney = /\d(?=(\d{3})+,)/g;

  const showOrHideModal = () => {
    if (!showModal) {
      setShowModal(true);
      document.body.classList.add('no-scroll');
      window.scrollTo(0, 0);
    }

    if (showModal) {
      setShowModal(false);
      document.body.classList.remove('no-scroll');
    }
  };

  return (
    <>
      <h3>Pagamento utilizando o saldo de FGTS</h3>
      <div className="purchase-info-container">
        <label>Número da compra *</label>
        {orderId && !isLoading ? <h3>{orderId}</h3> : ''}
      </div>
      <div className="purchase-info-container">
        <label>Valor da compra *</label>
        {orderValue && !isLoading ? (
          <h3>
            R${' '}
            {parseFloat(orderValue)
              .toFixed(2)
              .replace('.', ',')
              .replace(regexMoney, '$&.')}
          </h3>
        ) : (
          ''
        )}
      </div>
      <p className="first-page-text">
        Escolha uma das opções abaixo para prosseguir
      </p>
      <div className="button-container">
        <button
          className="simulation-button"
          onClick={() => setFgtsPayPage(3)}
        >
          Já optei antes pelo saque aniversário FGTS
        </button>
        <button
          className="simulation-button"
          onClick={() => setFgtsPayPage(2)}
        >
          Vou optar agora pelo saque aniversário do FGTS
        </button>
        <button className="main-info-button" onClick={showOrHideModal}>
          O que é o saque aniversário do FGTS? Como funciona?
        </button>
      </div>
      {showModal && (
        <div id="fgts-pay">
          <div className="fgtspay-modal-container">
            <div className="close-button-container">
              <button onClick={showOrHideModal}>
                <p>X</p>
              </button>
            </div>
            <h2 className="fgts-info-title">
              Existem 2 formas de sacar seu FGTS:
            </h2>

            <div className="fgts-info-container">
              <p>
                <strong>1-</strong> Saque-Rescisão: Somente saca o saldo
                de FGTS quando for demitido.
              </p>
              <p>
                <strong>2-</strong> Saque-Aniversário: Saca parte do saldo
                de FGTS anualmente no mês do seu aniversário. Quando for
                demitido não saca o saldo de FGTS, só saca a multa de
                rescisão.
              </p>
              <p>
                <strong>
                  Como a Tá no Bolso te ajuda: Antecipamos imediatamente
                  até 10 anos do seu saque-aniversário.
                </strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {statusMessageAlert && (
        <div
          className={
            statusMessageAlert.status === 'error'
              ? 'alert alert-danger alert-text-center-fgts-pay-page-6'
              : 'alert alert-primary alert-text-center-fgts-pay-page-6'
          }
          role="alert"
          id='intro-page-alert'
        >
          {statusMessageAlert.message}
        </div>
      )}
    </>
  )
}

export default IntroPage
