import React from "react";

const ExplanationPage = ({ setFgtsPayPage }) => {

  return (
    <div className="hiring-steps-container">
      <h3>Pague usando seu saldo de FGTS</h3>
      <p className="hiring-steps-text">
        Se você tem saldo de FGTS, você pode pagar usando a antecipação de
        saque-aniversário. Você não fará nenhum desembolso agora. Os anos
        necessários de saque-aniversário serão utilizados anualmente no
        mês do seu aniversário.
      </p>
      <p className="hiring-steps-text">Siga o passo a passo abaixo:</p>
      <div className="circles-container">
        <div className="circle">
          <div className="circle-number">1</div>
          <p>No aplicativo do FGTS, faça a adesão ao saque-aniversário de FGTS</p>
          <a
            href="https://www.youtube.com/watch?v=4Nx7neyBQpk"
            target="_blank"
            rel="noreferrer"
          >
            Clique aqui e assista o vídeo que ensina a aderir ao
            saque-aniversário
          </a>
        </div>
        <div className="arrow">&#8594;</div>
        <div className="circle">
          <div className="circle-number">2</div>
          <p>No aplicativo do FGTS, habilite o banco ZIPDIN para consultar o seu saldo</p>
          <a
            href="https://www.youtube.com/watch?v=Sx_QOoYPBrQ"
            target="_blank"
            rel="noreferrer"
          >
            Clique aqui e assista o vídeo que ensina a habilitar o banco
            para consultar seu saldo
          </a>
        </div>
        <div className="arrow">&#8594;</div>
        <div className="circle">
          <div className="circle-number">3</div>
          <p>Pronto! Agora podemos continuar com sua compra, usaldo o seu saldo de FGTS</p>
          <button type="button" onClick={() => setFgtsPayPage(3)}>
            Continuar para comprar usando o meu saldo de FGTS
          </button>
        </div>
      </div>
    </div>
  )
}

export default ExplanationPage
