import React, { createContext } from 'react';

type WhatsAppButtonType = {
  phone?: string;
  text?: string;
};

type LandingPageContextType = {
  handleWhatsAppButton({ phone, text }: WhatsAppButtonType): void;
};

const LandingPageContext = createContext<LandingPageContextType>(
  {} as LandingPageContextType,
);

function LandingPageProvider({ children }) {
  function handleWhatsAppButton({ phone, text }: WhatsAppButtonType) {
    window.open(
      `https://api.whatsapp.com/send?text=${text!
        .replace(/  +/g, '')
        .replace(/(?:\r\n|\r|\n)/g, '%0a')
        .replace(/ /g, '%20')}&phone=${phone}`
    );
  }

  return (
    <LandingPageContext.Provider value={{ handleWhatsAppButton }}>
      {children}
    </LandingPageContext.Provider>
  );
}

export { LandingPageContext, LandingPageProvider };
