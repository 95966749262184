import { BiCheckCircle } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa';

import { useLandingPage } from '../../../hooks/useLandingPage';

import happyWomanImg from '../../../assets/peoples/happy-woman.svg';

import classes from './styles.module.scss';

function About() {
    const { handleWhatsAppButton } = useLandingPage();

    return (
        <section className={classes.about} id="about">
            <div className="container">
                <h5>SAIBA MAIS</h5>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <h2>O que é a Refinanciamento de Crédito Consignado?</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <p className={classes.subtitle}>
                            O refinanciamento do empréstimo consignado é uma modalidade que permite você, a partir de um empréstimo atual obter mais um crédito sem a necessidade de fazer um novo empréstimo.<br />
                            Essa modalidade ou alternativa, é viável para aquelas pessoas que já quitaram parte do contrato atual ativo e que precisam de um novo empréstimo.<br />
                            Neste caso, do refinanciamento em específico, a divida deverá permanecer no mesmo banco e sem alterar o valor da margem consignável do tomador do crédito.
                        </p>
                    </div>
                </div>
            </div>
            <div className={`row ${classes.row}`}>
                <div className={`col-lg-6 ${classes.colLeft}`}>
                    <img className={classes.people} src={happyWomanImg} alt="Mulher feliz" />
                    <div className={classes.colColorBlue}>
                        <span>
                            <h4>O que é preciso para solicitar o refinancimento?</h4>
                        </span>
                    </div>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li>
                            <span><BiCheckCircle className={classes.icon} /></span>
                            <span>
                                Ter em mãos o seu Extrato de Empréstimos Consignados disponível no site<br />
                                <a href="https://meu.inss.gov.br/" target="_blank">https://meu.inss.gov.br/</a>
                            </span>
                        </li>
                        <li>
                            <span><BiCheckCircle className={classes.icon} /></span>
                            <span>
                                Ter a senha de acesso ao <strong>Portal Meu INSS</strong> em mãos
                            </span>
                        </li>
                        <li>
                            <span><BiCheckCircle className={classes.icon} /></span>
                            <span>
                                Entrar em contato com a Central de Atendimento <strong>Tá no Bolso</strong>
                            </span>
                        </li>
                    </ul>
                    <div className={classes.divButtonWhatsApp}>
                        <button
                            type="button"
                            className="btn btn-lg btn-whatsapp"
                            onClick={() => handleWhatsAppButton({
                                text: 'Refinanciamento do consignado',
                            })}
                        >
                            <FaWhatsapp className={classes.icon} /> Fale pelo WhatsApp
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
