import { BiCheckCircle } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa';

import { useLandingPage } from '../../../hooks/useLandingPage';

import peopleMoneyImg from '../../../assets/peoples/people-money.svg';

import classes from './styles.module.scss';

function About() {
    const { handleWhatsAppButton } = useLandingPage();

    return (
        <section className={classes.about} id="about">
            <div className="container">
                <h5>COMO FUNCIONA</h5>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <h2>Veja como é fácil contratar o Crédito Consignado</h2>
                    </div>
                </div>
            </div>
            <div className={`row ${classes.row}`}>
                <div className={`col-lg-6 ${classes.colLeft}`}>
                    <img className={classes.people} src={peopleMoneyImg} alt="Pessoa com dinheiro" />
                    <div className={classes.colColorBlue}>
                        <span>
                            <h4>Como é feita a contratação do Crédito Consignado?</h4>
                        </span>
                    </div>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li>
                            <span><BiCheckCircle className={classes.icon} /></span>
                            Indique quanto precisa e em qual prazo quer pagar seu empréstimo.
                        </li>
                        <li>
                            <span><BiCheckCircle className={classes.icon} /></span>
                            Envie os documentos e assine o contrato.
                        </li>
                        <li>
                            <span><BiCheckCircle className={classes.icon} /></span>
                            Pronto! O seu empréstimo Tá no Bolso!
                        </li>
                    </ul>
                    <div className={classes.divButtonWhatsApp}>
                        <button
                            type="button"
                            className="btn btn-lg btn-whatsapp"
                            onClick={() => handleWhatsAppButton({
                                text: 'Consignado',
                            })}
                        >
                            <FaWhatsapp className={classes.icon} /> Fale pelo WhatsApp
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
