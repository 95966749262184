import React, { useEffect, useRef, useState } from 'react';
import { MdNotifications } from 'react-icons/md';

import { useAuth } from '../../../hooks/useAuth';
import './styles.scss';
import { api } from '../../../services/api';
import { useHistory } from 'react-router-dom';

type TopbarType = {
  breadcrumb?: string;
  title?: string;
};

interface Notification {
  uuid: string;
  message: string;
  link: string;
  read: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export default function Topbar({ breadcrumb, title }: TopbarType) {
  const { activeMenu, toggleMenu } = useAuth();
  const [showNotificationMenu, setShowNotificationMenu] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const notificationMenu = useRef(null) as any;
  const history = useHistory();

  const closeNotification = (e) => {
    if (showNotificationMenu && !notificationMenu.current?.contains(e.target)) {
      setShowNotificationMenu(false);
    }
  };

  document.addEventListener('mousedown', closeNotification);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    const response = await api.get('/notifications', {
      params: {
        read: false,
      },
    });

    setNotifications(response.data);
  };

  const handleNotification = async (notification: Notification) => {
    try {
      const response = await api.patch(`/notifications/${notification.uuid}`, {
        read: true,
      });

      setShowNotificationMenu(false);

      if (notification.link) {
        history.push({
          pathname: notification.link,
        });

        return;
      }

      await getNotifications();
    } catch (error) {}
  };

  const handleMarkAllRead = async () => {
    try {
      const response = await api.get(`/notifications/mark-all-read`);

      setShowNotificationMenu(false);

      await getNotifications();
    } catch (error) {}
  };

  return (
    <div className="topbar">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className={`toggle ${activeMenu ? 'active' : ''}`}
          onClick={toggleMenu}
        ></div>
        <div className="info-page">
          <div className="breadcrumb">Home / {breadcrumb}</div>
          <div className="title-page">{title}</div>
        </div>
      </div>
      <div ref={notificationMenu}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => setShowNotificationMenu(!!!showNotificationMenu)}
        >
          <MdNotifications color="#000" size={32} title="Notificações" />
          {notifications.length > 0 ? (
            <span className="badge">{notifications.length}</span>
          ) : (
            ''
          )}
        </div>
        <div
          className="notification_dd"
          style={{ display: showNotificationMenu ? 'block' : 'none' }}
        >
          <ul className="notification_ul">
            {notifications.map((notification) => (
              <li
                key={notification.uuid}
                style={{ cursor: 'pointer' }}
                onClick={() => handleNotification(notification)}
              >
                <div className="notify_data">
                  <div className="title">{notification.message}</div>
                  <div className="sub_title" style={{ display: 'none' }}></div>
                </div>
              </li>
            ))}

            <li className="show_all">
              <p className="link" onClick={handleMarkAllRead}>
                Marcar como lidas
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
