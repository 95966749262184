import React, { useEffect, useState, FormEvent } from 'react';
import Camera from 'react-html5-camera-photo';
import InputMask from 'react-input-mask';

import { api } from '../../../../../../services/api';

import Accordion from '../../../../../../../components/Accordion';

import './styles.scss';

import imageIdentificationDocument from '../../../../../../../assets/simulation_result/identification_document.jpg';

const banks = require('../../../../../../../json/banks.json')
const accountTypeArray = require("../../../../../../../json/accountType.json")
const streetTypeArray = require("../../../../../../../json/streetType.json")
const ufArray = require("../../../../../../../json/uf.json");

const ComplementaryData = ({
  name,
  cpf,
  phone,
  email,
  proposalId,
  bankProposalId,
  hasPaymentInformation,
  handleProposalUserData,
  getBase64,
  faker,
  simulatorForWhatsApp,
}: {
  name: string,
  cpf: string,
  phone: string,
  email: string,
  proposalId: string,
  bankProposalId: string,
  hasPaymentInformation: boolean,
  handleProposalUserData: any,
  getBase64: any,
  faker: boolean,
  simulatorForWhatsApp: boolean,
}) => {
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [identificationDocumentImage, setIdentificationDocumentImage] = useState<string>('');
  const [typeIdentificationDocument, setTypeIdentificationDocument] = useState<string>('');
  const [facialBiometricsImage, setFacialBiometricsImage] = useState<string>('');
  const [haveError, setHaveError] = useState<boolean>(false);
  const [statusMessageAlert, setStatusMessageAlert] = useState<any>(null);

  // const [bank, setBank] = useState<string>(banks[0].COMPE);
  const [state, setState] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [postCode, setPostalCode] = useState<string>('');
  // const [streetType, setStreetType] = useState<string>(streetTypeArray[0].id);
  const [streetType, setStreetType] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [bank, setBank] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [branch, setBranch] = useState<string>('');
  const [loaderSearchCep, setLoaderSearchCep] = useState<boolean>(false);
  // const [accountType, setAccountType] = useState<string>(accountTypeArray[0].id);
  const [accountType, setAccountType] = useState<string>('CORRENTE');
  const [additionalAttribute, setAdditionalAttribute] = useState<string>('');
  const [selectedAccordion, setSelectedAccordion] = useState<number>(1);

  useEffect(() => {
    if (faker && !hasPaymentInformation) {
      setBank('001');
      setBranch('2804');
      setAccount('141414');
      setAccountType('CORRENTE');
      setNumber('21');
    }
  }, []);

  async function searchCep(cep: string) {
    setPostalCode(cep);

    const unmaskCep = cep.replace(/[^0-9]/g, "");

    if (unmaskCep.length === 8) {
      setLoaderSearchCep(true);

      const { data: cepData } = await api.post(`request-cep`, {
        cep: unmaskCep,
      });

      if (cepData.logradouro) {
        setStreetType(cepData.tipo_endereco);
        setStreet(cepData.logradouro);
        setCity(cepData.localidade);
        setState(cepData.uf);
        setNeighborhood(cepData.bairro);
      }
      setLoaderSearchCep(false);
    }
  }

    async function includeProposal() {
        setStatusMessageAlert({
            status: 'info',
            message: 'Aguarde, estamos enviando seus dados...'
        });

        try {
            const includeProposalResponse = await api.post(`proposal`, {
                proposalId,
                bankProposalId,
                name,
                cpf,
                phone,
                email,
                bank,
                hasPaymentInformation,
                accountType,
                account: account.replace(/[^0-9]/g, ""),
                branch: branch.replace(/[^0-9]/g, ""),
                state,
                city,
                neighborhood,
                postCode: postCode.replace(/[^0-9]/g, ""),
                streetType,
                street,
                number: number.length === 0 ? 'SN' : number.replace(/[^0-9]/g, ""),
                additionalAttribute,
            });

            if (includeProposalResponse.data?.dataApi?.details) {
                const detailsError = includeProposalResponse.data.dataApi.details.map((e: any) => (
                    <div key={e.message}>
                    {e.message}
                    <br />
                    </div>
                ));

                setStatusMessageAlert({
                    status: 'error',
                    message: detailsError
                });
            } else if (includeProposalResponse.data?.dataApi?.message) {
                setStatusMessageAlert({
                    status: 'error',
                    message: includeProposalResponse.data.dataApi.message,
                });
            } else if (includeProposalResponse.data?.message) {
                setStatusMessageAlert({
                    status: 'error',
                    message: includeProposalResponse.data.message,
                });
            } else if (includeProposalResponse.data?.statusMessage === 'Gateway Time-out') {
                setStatusMessageAlert({
                    status: 'error',
                    message: includeProposalResponse.data.statusMessage,
                });
            } else {
                handleProposalUserData({
                    responseProposalData: includeProposalResponse,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function validationFormByStep(Step: number) {
        switch(stepNumber) {
            case 1: {
                if (
                    state === '' ||
                    city === '' ||
                    neighborhood === '' ||
                    postCode === '' ||
                    streetType === '' ||
                    street === ''
                  ) {
                    setHaveError(true);
                    setStatusMessageAlert({
                      status: 'error',
                      message: 'Você deve preencher todos os campos obrigatórios(*).'
                    });
                    return;
                }
                break;
            }
            case 2: {
                if (
                    bank  === '' ||
                    account === '' ||
                    branch === '' ||
                    accountType === ''
                ) {
                    setHaveError(true);
                    setStatusMessageAlert({
                      status: 'error',
                      message: 'Você deve preencher todos os campos obrigatórios(*).'
                    });
                    return;
                }
                break;
            }
        }

        return true;
    }

  const handleNext = (event: FormEvent) => {
    event.preventDefault();

    setStatusMessageAlert(null);
    setHaveError(false);

    switch(stepNumber) {
        case 1: {
            if (validationFormByStep(stepNumber)) {
                if (hasPaymentInformation === false) {
                    setStepNumber(2);
                } else {
                    includeProposal();
                }
            }
            break;
        }
        case 2: {
            if (validationFormByStep(stepNumber)) {
                includeProposal();
            }
            break;
        }
    }
    return;

    if (
      bankProposalId === '' ||
      name === '' ||
      phone === '' ||
      email === '' ||
      cpf === ''
    ) {}

    if (
      (
        hasPaymentInformation === false &&
        (
          bank  === '' ||
          account === '' ||
          branch === '' ||
          accountType === ''
        )
      ) ||
      state === '' ||
      city === '' ||
      neighborhood === '' ||
      postCode === '' ||
      streetType === '' ||
      street === ''
    ) {
      setHaveError(true);
      setStatusMessageAlert({
        status: 'error',
        message: 'Você deve preencher todos os campos obrigatórios(*).'
      });
      return;
    }

    // ||
    // identificationDocumentImage === '' ||
    // typeIdentificationDocument === '' ||
    // facialBiometricsImage === ''

    setStatusMessageAlert({
      status: 'info',
      message: 'Aguarde, estamos enviando seus dados...'
    });

    api
      .post(`proposal`, {
        proposalId,
        bankProposalId,
        bank,
        state,
        city,
        neighborhood,
        postCode: postCode.replace(/[^0-9]/g, ""),
        streetType,
        street,
        number: number.length === 0 ? 'SN' : number.replace(/[^0-9]/g, ""),
        account: account.replace(/[^0-9]/g, ""),
        branch: branch.replace(/[^0-9]/g, ""),
        accountType,
        additionalAttribute,
        name,
        cpf,
        phone,
        email,
        identificationDocumentImage,
        typeIdentificationDocument,
        facialBiometricsImage
      })
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="modal-body">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="stepBar">
                <li className="active" key="simulation"><i className="fa fa-money"><strong>Simulação</strong></i></li>
                <li className="active" key="complementaryData"><i className="fa fa-id-card"><strong>Dados Complementares</strong></i></li>
                <li key="adhesionContract"><i className="fa fa-file-text"><strong>Contrato de adesão</strong></i></li>
                <li key="completedRequest"><i className="fa fa-check-circle"><strong>Solicitação concluída</strong></i></li>
              </ul>
            </div>
          </div>
          <h3 style={{marginBottom: '15px'}}>Estamos quase lá, precisamos de alguns dados seus.</h3>
          <div className="row">
            <div className="col-md-12">
              {statusMessageAlert && (
                <div className={statusMessageAlert.status === 'error' ? 'alert alert-danger' : 'alert alert-primary'} role="alert">{statusMessageAlert.message}</div>
              )}
            </div>
          </div>
          <form onSubmit={handleNext} className="form-complementary-data">
            {stepNumber === 1 && (
                <>
                    <h3>Endereço</h3>
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>CEP *</label>
                            <div className="input-group">
                                <InputMask
                                    mask="99.999-999"
                                    className={`form-control ${haveError && !postCode && 'is-invalid'}`}
                                    onChange={(e: any) => searchCep(e.target.value)}
                                    autoComplete="off"
                                    placeholder=""
                                    value={postCode}
                                />
                                <div className="input-group-append">
                                    {loaderSearchCep && (
                                    <span className="input-group-text" id="basic-addon2">
                                    <i className="fa fa-circle-o-notch fa-spin"></i>
                                    </span>
                                    )}
                                </div>
                                <div className="invalid-feedback">
                                    {haveError && !postCode && ('Por favor digite o CEP.')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Rua *</label>
                            <select
                                className={`form-control ${haveError && !streetType && 'is-invalid'}`}
                                value={streetType}
                                onChange={(e: any) => setStreetType(e.target.value)}
                            >
                                <option value="">Selecione...</option>
                                {streetTypeArray.map((option) => (
                                    <option value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <div className="invalid-feedback">
                                {haveError && !streetType && ('Por favor selecione o tipo de rua.')}
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>&nbsp;</label>
                            <input
                                className={`form-control ${haveError && !street && 'is-invalid'}`}
                                onChange={(e: any) => setStreet(e.target.value)}
                                placeholder=""
                                value={street}
                            />
                            <div className="invalid-feedback">
                                {haveError && !street && ('Por favor digite o endereço.')}
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Nº</label>
                            <input
                                className={`form-control`}
                                maxLength={6}
                                onChange={(e: any) => setNumber(e.target.value)}
                                placeholder=""
                                value={number}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Complemento</label>
                            <input
                                className={`form-control`}
                                onChange={(e: any) => setAdditionalAttribute(e.target.value)}
                                value={additionalAttribute}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Bairro *</label>
                            <input
                                className={`form-control ${haveError && !neighborhood && 'is-invalid'}`}
                                onChange={(e: any) => setNeighborhood(e.target.value)}
                                placeholder=""
                                value={neighborhood}
                            />
                            <div className="invalid-feedback">
                                {haveError && !neighborhood && ('Por favor digite o bairro.')}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Cidade *</label>
                            <input
                                className={`form-control ${haveError && !city && 'is-invalid'}`}
                                onChange={(e: any) => setCity(e.target.value)}
                                placeholder=""
                                value={city}
                            />
                            <div className="invalid-feedback">
                                {haveError && !city && ('Por favor digite a cidade.')}
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>UF *</label>
                            <select
                                className={`form-control ${haveError && !state && 'is-invalid'}`}
                                value={state}
                                onChange={(e: any) => setState(e.target.value)}
                            >
                                <option value="">Selecione...</option>
                                {ufArray.map((option ) => (
                                    <option value={option.id}>{option.sigla}</option>
                                ))}
                            </select>
                            <div className="invalid-feedback">
                                {haveError && !state && ('Por favor selecione o estado.')}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!hasPaymentInformation && stepNumber === 2 && (
                <>
                    <h3>Dados Bancários</h3>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Banco *</label>
                            <select
                                className={`form-control ${haveError && !bank && 'is-invalid'}`}
                                value={bank}
                                onChange={(e: any) => setBank(e.target.value)}
                            >
                            <option value="">Selecione...</option>
                            {banks.map((option) => (
                                <option value={option.COMPE}>{option.COMPE} - {option.LongName}</option>
                            ))}
                            </select>
                            <div className="invalid-feedback">
                                {haveError && !bank && ('Por favor selecione o banco.')}
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Tipo de conta *</label>
                            <select
                                className={`form-control ${haveError && !accountType && 'is-invalid'}`}
                                value={accountType}
                                onChange={(e: any) => setAccountType(e.target.value)}
                            >
                            {accountTypeArray.map((option) => (
                                <option value={option.id}>{option.name}</option>
                            ))}
                            </select>
                            <div className="invalid-feedback">
                                {haveError && !accountType && ('Por favor selecione o tipo de conta.')}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Agência *</label>
                            <input
                                className={`form-control ${haveError && !branch && 'is-invalid'}`}
                                maxLength={4}
                                onChange={(e: any) => setBranch(e.target.value)}
                                placeholder=""
                                value={branch}
                            />
                            <div className="invalid-feedback">
                                {haveError && !branch && ('Por favor digite a agência.')}
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Conta *</label>
                            <input
                                className={`form-control ${haveError && !account && 'is-invalid'}`}
                                maxLength={10}
                                onChange={(e: any) => setAccount(e.target.value)}
                                placeholder=""
                                value={account}
                            />
                            <div className="invalid-feedback">
                                {haveError && !account && ('Por favor digite a conta.')}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {stepNumber === 3 && (
                <>
                    <h3>Documento de identificação</h3>
                    <div className="form-row">
                        <div className="col-md-3">
                            <img src={imageIdentificationDocument} alt="Documento de identificação" className="img-fluid" />
                        </div>
                        <div className="col-md-9">
                            <div className="form-row">
                                <div className="form-group col-xs-12 col-md-6">
                                    <label>Foto do documento de identificação *</label>
                                    <input
                                        className={`form-control-file ${haveError && !typeIdentificationDocument && 'is-invalid'}`}
                                        type="file"
                                        onChange={async (e: any) => {
                                            const fileBase64 = await getBase64(e.target.files[0]);

                                            setIdentificationDocumentImage(fileBase64);
                                        }}
                                    />
                                    <div className="invalid-feedback">
                                        {haveError && !identificationDocumentImage && ('Por favor insira a foto de seu documento de indentificação.')}
                                    </div>
                                    <small className="form-text text-muted">O arquivo suportado é JPG.</small>
                                </div>
                                <div className="form-group col-xs-12 col-md-6">
                                    <label>Tipo de documento *</label>
                                    <select
                                        className={`form-control ${haveError && !typeIdentificationDocument && 'is-invalid'}`}
                                        value={typeIdentificationDocument}
                                        onChange={(e: any) => setTypeIdentificationDocument(e.target.value)}
                                    >
                                        <option value="" selected>Selecione...</option>
                                        <option value="RG">RG</option>
                                        <option value="CNH">CNH</option>
                                    </select>
                                    <div className="invalid-feedback">
                                        {haveError && !typeIdentificationDocument && ('Por favor selecione o tipo de documento.')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {stepNumber === 4 && (
                <>
                    <h3>Foto do rosto (Selfie)</h3>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            {simulatorForWhatsApp === false ? (
                                <>
                                    <label>Câmera</label>
                                    <Camera
                                        isMaxResolution={true}
                                        imageType="jpg"
                                        idealFacingMode="user"
                                        onTakePhotoAnimationDone={(dataUri) => {
                                            setFacialBiometricsImage(dataUri);
                                        }}
                                    />
                                    <small className="form-text text-muted">Centralize bem o seu rosto</small>
                                </>
                                ) : (
                                <>
                                    <label>Foto do rosto *</label>
                                    <input
                                        className={`form-control-file ${haveError && !facialBiometricsImage && 'is-invalid'}`}
                                        type="file"
                                        onChange={async (e: any) => {
                                            const fileBase64 = await getBase64(e.target.files[0]);

                                            setFacialBiometricsImage(fileBase64);
                                        }}
                                    />
                                    <div className="invalid-feedback">
                                        {haveError && !facialBiometricsImage && ('Por favor insira a uma foto do rosto.')}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            {simulatorForWhatsApp === false && (
                                <>
                                    <label>Foto capturada *</label>
                                    {facialBiometricsImage && (
                                        <img src={facialBiometricsImage} alt="Preview" className="img-fluid" />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
          </form>
        </div>
      </div>
      <div className="modal-footer">
        <span style={{ width: '100%' }}>
            <button type="button" className="btn btn-lg btn-primary" onClick={handleNext}>Avançar</button>
        </span>
      </div>
    </>
  );

}

export default ComplementaryData;
