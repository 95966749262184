import React from 'react'
import './styles.scss'
import Header from '../../components/header'
import PaymentProcess from '../PaymentProcess'

const FgtsPayPage = () => {

  return (
    <>
      <Header />
      <PaymentProcess />
    </>
  )
}

export default FgtsPayPage
