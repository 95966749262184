import React, { FormEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../../../services/api';
import { StatusAlertType } from './types';
import { ClientData } from './types';
import IntroPage from './IntroPage';
import ExplanationPage from './ExplanationPage';
import DataForSimulationPage from './DataForSimulationPage';
import SimulationPage from './SimulationPage';
import TermsPage from './TermsPage';
import ComplementaryData from './ComplementaryData';
import SdkPage from './SdkPage';
import { isValidCpf } from '../../../../utils/helpers';
import ConclusionPage from './ConclusionPage';
import {
  handleFindOrRegister,
  handleRegistrationFinish,
  handleDictEntries,
  handleBankAccountsNatural,
  verifyTemporaryAccountCreation,
} from '../../FourAllRoutes';
import './styles.scss';

const PaymentProcess = () => {
  const slug = useParams<any>();
  const [fgtsPayPage, setFgtsPayPage] = useState<number>(1);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [haveError, setHaveError] = useState<boolean>(false);
  const [occupation, setOccupation] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [streetNumber, setStreetNumber] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [uf, setUf] = useState<string>('');
  const [motherName, setMotherName] = useState<string>('');
  const [nationality, setNationality] = useState<string>('');
  const [civilStatus, setCivilStatus] = useState<string>('');
  const [incomeValue, setIncomeValue] = useState<string>('1000');
  const [documentType, setDocumentType] = useState<string>('RG');
  const [documentNumber, setDocumentNumber] = useState<string>('');
  const [documentState, setDocumentState] = useState<string>('');
  const [documentIssueDate, setDocumentIssueDate] = useState<string>('');
  const [documentIssuingAgency, setDocumentIssuingAgency] =
    useState<string>('');
  const [documentExpirationDate, setDocumentExpirationDate] =
    useState<string>('');
  const [pixType, setPixType] = useState<string>('EVP');
  const [pixValue, setPixValue] = useState<string>(
    '5b8cc393-4784-4deb-b2a3-e6b567b61cea',
  );
  const [formattedPixValue, setFormattedPixValue] = useState<string>('');
  const [bank, setBank] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [tempBankAccountType, setTempBankAccountType] = useState<string>(
    'IndividualCurrentAccount',
  );
  const [tempAccountNumber, setTempAccountNumber] = useState<string>('');
  const [tempBankNumber, setTempBankNumber] = useState<string>('');
  const [tempAgency, setTempAgency] = useState<string>('');
  const [digitAccount, setDigitAccount] = useState<string>('');
  const [agency, setAgency] = useState<string>('');
  const [bankAccountType, setBankAccountType] = useState<string>('');
  const [partnerId, setPartnerId] = useState<string | null>('');
  const [orderId, setOrderId] = useState<string>('');
  const [orderValue, setOrderValue] = useState<string>('');
  const [integrationUuid, setIntegrationUuid] = useState<string>('');
  const [integrationName, setIntegrationName] = useState<string>('');
  const [firstRenderProposalUuid] = useState(slug.proposalUuid);
  const [proposalUuid, setProposalUuid] = useState('');
  const [simulationInfo, setSimulationInfo] = useState<any>({});
  const [fourAllRegistrationUuid, setFourAllRegistrationUuid] =
    useState<string>('');
  const [customerAlreadyExists, setCustomerAlreadyExists] =
    useState<boolean>(false);
  const [proceedVerifyTemporaryAccount, setProceedVerifyTemporaryAccount] =
    useState<boolean>(false);
  const [proceedInclude, setProceedInclude] = useState<boolean>(false);
  const [includeFirstTry, setIncludeFirstTry] = useState<boolean>(true);

  const getProposalInfo = async () => {
    setIsLoading(true);

    if (firstRenderProposalUuid) {
      const { data } = await api.get(`/proposals/${firstRenderProposalUuid}`);
      if (data) {
        setIsLoading(false);
        setOrderId(data.fgtsPayOrderId);
        setOrderValue(data.fgtsPayOrderValue);
        setCpf(data.clientCpf);
        setIntegrationUuid(data.integration.uuid);
        setPartnerId(data.partner.uuid);
        if (data.integration) {
          setIntegrationName(data.integration.name);
        }
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: 'Proposta não encontrada.',
        });
        return;
      }
    }
  };

  useEffect(() => {
    getProposalInfo();
  }, []);

  function fillCustomerInfo(clientData: ClientData) {
    setCustomerAlreadyExists(true);

    if (clientData.proposalCustomerData) {
      setIncomeValue(clientData.proposalCustomerData.incomeValue!);
      setMotherName(clientData.proposalCustomerData.motherName!);
      setNationality(clientData.proposalCustomerData.nationality!);
      setCivilStatus(clientData.proposalCustomerData.civilStatus!);
      setOccupation(clientData.proposalCustomerData.occupation!);
    }

    if (clientData.proposalIdentificationDocumentData) {
      setDocumentType(clientData.proposalIdentificationDocumentData.docType!);
      setDocumentNumber(
        clientData.proposalIdentificationDocumentData.docNumber!,
      );
      setDocumentState(clientData.proposalIdentificationDocumentData.docUf!);
      setDocumentIssueDate(
        clientData.proposalIdentificationDocumentData.docDate!,
      );
      setDocumentIssuingAgency(
        clientData.proposalIdentificationDocumentData.docIssuer!,
      );
    }

    if (clientData.proposalAddressData) {
      setPostalCode(clientData.proposalAddressData.zipCode);
      setAddress(clientData.proposalAddressData.address);
      setStreetNumber(clientData.proposalAddressData.addressNumber);
      setNeighborhood(clientData.proposalAddressData.neighborhood);
      setCity(clientData.proposalAddressData.city);
      setUf(clientData.proposalAddressData.uf);
    }

    if (clientData.proposalBankData) {
      setBankAccountType(clientData.proposalBankData.bankAccountType);
      setBank(clientData.proposalBankData.bankNumber);
      setDigitAccount(clientData.proposalBankData.digitAccount);
      setAgency(clientData.proposalBankData.agency);
    }
  }

  function setProceedStatesToFalse() {
    setProceedVerifyTemporaryAccount(false);
    setProceedInclude(false);
    setIncludeFirstTry(false);
    return;
  }

  function handleGenericErrorStatus() {
    setStatusMessageAlert({
      status: 'error',
      message: 'Ops, algo ocorreu. Tente novamente.',
    });
  }

  function handleMandatoryFieldsError() {
    setHaveError(true);
    setStatusMessageAlert({
      status: 'error',
      message: 'Você deve preencher todos os campos obrigatórios(*).',
    });
  }

  async function requestBalanceFgts() {
    const formattedEmail = email.trim();
    const formattedcellPhone = cellPhoneNumber.replace(/[^0-9]/g, '');
    const formattedCpf = cpf.replace(/[^0-9]/g, '');
    const formattedOrderValue = orderValue.replace(',', '.');

    const { data } = await api.post('/fgts/balance', {
      cpf: formattedCpf,
      name,
      birthDate,
      email: formattedEmail,
      cellPhone: formattedcellPhone,
      integrationUuid,
      partnerUuid: partnerId,
      proposalUuid: proposalUuid ? proposalUuid : firstRenderProposalUuid,
      fgtsPay: {
        orderId,
        orderValue: formattedOrderValue,
      },
    });
    return data;
  }

  async function handleBalance(event: FormEvent) {
    event.preventDefault();
    setStatusMessageAlert(null);

    if (
      name === '' ||
      cpf === '' ||
      email === '' ||
      birthDate === '' ||
      cellPhoneNumber === ''
    ) {
      handleMandatoryFieldsError();
      return;
    }

    if (!isValidCpf(cpf)) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Número de CPF inválido.',
      });
      return;
    }

    setStatusMessageAlert({
      status: 'info',
      message: 'Checando dados...',
    });

    try {
      if (integrationName === 'zipdin') {
        setProposalUuid('');
        try {
          const data = await requestBalanceFgts();

          if (data.status === 'success') {
            setProposalUuid(data.proposalUuid);
            return;
          } else if (data.status === 'error') {
            setStatusMessageAlert({
              status: 'error',
              message: data.message,
            });
            return;
          } else {
            handleGenericErrorStatus();
            return;
          }
        } catch (error) {
          handleGenericErrorStatus();
          return;
        }
      }

      const response = await handleFindOrRegister(
        cpf,
        cellPhoneNumber,
        formatDate,
        birthDate,
        firstRenderProposalUuid,
        name,
        email,
      );

      if (response.data.status === 'success') {
        setStatusMessageAlert({
          status: 'info',
          message: 'Só um momento, estamos consultando as parcelas...',
        });
        setFourAllRegistrationUuid(
          response.data.clientData.fourAllRegistrationUuid,
        );

        const clientData: ClientData = response.data.clientData;

        if (clientData.uuid) {
          fillCustomerInfo(clientData);
        }

        try {
          const data = await requestBalanceFgts();

          if (data.status === 'success') {
            setProposalUuid(data.proposalUuid);
          } else if (data.status === 'error') {
            setStatusMessageAlert({
              status: 'error',
              message: data.message,
            });
            return;
          } else {
            handleGenericErrorStatus();
            return;
          }
        } catch (error) {
          handleGenericErrorStatus();
          return;
        }
      } else if (response.data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: response.data.message,
        });
        return;
      } else {
        handleGenericErrorStatus();
        return;
      }
    } catch (error) {
      handleGenericErrorStatus();
      return;
    }
  }

  async function handleSimulateFgts() {
    changeLoadingModal(true);

    try {
      const data = {
        proposalUuid,
      };
      const response = await api.post('/fgts/simulate', data);

      if (response.data.status === 'success') {
        setSimulationInfo(response.data);
        setFgtsPayPage(4);
        setStatusMessageAlert(null);
      } else if (response.data.status === 'error') {
        changeLoadingModal(false);
        setStatusMessageAlert({
          status: 'error',
          message: response.data.message,
        });
        return;
      } else {
        changeLoadingModal(false);
        handleGenericErrorStatus();
        return;
      }
    } catch (error) {
      handleGenericErrorStatus();
      return;
    }
  }

  useEffect(() => {
    if (proposalUuid !== '') {
      handleSimulateFgts();
    }
  }, [proposalUuid]);

  function handleSimulateAgain() {
    setStatusMessageAlert({
      status: 'info',
      message: 'Só um momento, estamos consultando as parcelas...',
    });
    handleSimulateFgts();
  }

  useEffect(() => {
    if (proceedInclude) {
      includeProposal();
    }
  }, [proceedInclude]);

  async function includeProposal() {
    setStatusMessageAlert({
      status: 'info',
      message: 'Só um momento, estamos finalizando o processo...',
    });
    const formattedIncomeValue = +incomeValue!.replace(/,/i, '.');
    const formattedZipCode = postalCode.replace(/[^0-9]/g, '');
    const formattedCellPhoneNumber = cellPhoneNumber
      .replace(/[^0-9]/g, '')
      .substring(2, 11);
    const cellphoneCode = cellPhoneNumber.substring(1, 3);

    try {
      const data = {
        proposalUuid,
        bankData: {
          tempBankAccountType,
          tempBankNumber,
          tempAccountNumber,
          tempAgency,
          bankAccountType,
          bankNumber: bank,
          agency,
          accountNumber: account,
          digitAccount,
        },
        client: {
          incomeValue: formattedIncomeValue,
          otherIncomeValue: 0,
          occupation,
          addressData: {
            zipCode: formattedZipCode,
            address,
            addressNumber: streetNumber,
            neighborhood,
            city,
            uf,
          },
          motherName,
          gender,
          nationality,
          politicallyExposedCustomer: false,
          civilStatus,
          contacts: {
            email,
            cellPhoneDdd: cellphoneCode,
            cellPhone: formattedCellPhoneNumber,
          },
          identificationDocument: {
            docType: documentType,
            docNumber: documentNumber,
            docUf: documentState,
            docDate: documentIssueDate,
            docIssuer: documentIssuingAgency,
          },
        },
      };

      const response = await api.post('/fgts/include', data);

      if (response.data.status === 'success') {
        setStatusMessageAlert(null);
        setFgtsPayPage(8);
      } else if (response.data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: response.data.message,
        });
        setProceedStatesToFalse();
      } else {
        handleGenericErrorStatus();
        setProceedStatesToFalse();
      }
    } catch (error) {
      handleGenericErrorStatus();
      setProceedStatesToFalse();
    }

    changeLoadingModal(false);
    return;
  }

  async function saveTermsAndConditionsApprovalAndFinishCustomerCreation() {
    setStatusMessageAlert({
      status: 'info',
      message: 'Salvando informação...',
    });

    try {
      const data = await handleRegistrationFinish(
        proposalUuid,
        firstRenderProposalUuid,
        fourAllRegistrationUuid,
      );

      if (data.status === 'success') {
        setStatusMessageAlert(null);
        if (customerAlreadyExists) {
          setFgtsPayPage(6);
          setStepNumber(3);
        } else {
          setFgtsPayPage(6);
        }
      } else if (data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: data.message,
        });
        return;
      } else {
        handleGenericErrorStatus();
        return;
      }
    } catch (error) {
      handleGenericErrorStatus();
      return;
    }
  }

  function validationFormByStep(stepNumber: number) {
    if (fgtsPayPage === 6) {
      switch (stepNumber) {
        case 1: {
          if (
            cpf === '' ||
            email === '' ||
            cellPhoneNumber === '' ||
            // incomeValue === '0' ||
            // incomeValue === '' ||
            // occupation === '' ||
            gender === '' ||
            nationality === '' ||
            civilStatus === '' ||
            motherName === ''
          ) {
            handleMandatoryFieldsError();
            return;
          }
          break;
        }
        case 2: {
          if (
            postalCode === '' ||
            address === '' ||
            streetNumber === '' ||
            neighborhood === '' ||
            city === '' ||
            uf === ''
          ) {
            handleMandatoryFieldsError();
            return;
          }
          break;
        }
        case 3: {
          if (pixValue === '') {
            handleMandatoryFieldsError();
            return;
          }
          break;
        }
        case 4: {
          if (
            documentNumber === '' ||
            documentIssueDate === '' ||
            documentIssuingAgency === '' ||
            documentState === ''
          ) {
            handleMandatoryFieldsError();
            return;
          }
          break;
        }
      }

      return true;
    }
  }

  function changeLoadingModal(loading: boolean) {
    setLoadingModal(loading);
  }

  function handlePixTypeChange(e: { target: { value: string } }) {
    setPixType(e.target.value);
    setPixValue('');
  }

  function handlePixValueChange(e: { target: { value: string } }) {
    const inputValue = e.target.value;
    let formattedValue = inputValue;

    switch (pixType) {
      case 'CPF':
        formattedValue = inputValue.replace(/[^0-9]/g, '');
        break;
      case 'Email':
        formattedValue = inputValue.trim();
        break;
      case 'Cellphone':
        formattedValue = inputValue.replace(/[^0-9]/g, '');
        break;
      case 'EVP':
        formattedValue = inputValue.trim();
        break;
      default:
        break;
    }

    setPixValue(inputValue);
    setFormattedPixValue(formattedValue);
  }

  function handlePrevious(e: FormEvent) {
    e.preventDefault();

    const previousStepNumber = stepNumber - 1;

    setStatusMessageAlert(null);
    setHaveError(false);

    if (previousStepNumber === 0) {
      changeLoadingModal(true);
      setFgtsPayPage(4);
    } else {
      setStepNumber(previousStepNumber);
    }
  }

  async function handleTemporaryAccountCreation() {
    if (proceedVerifyTemporaryAccount) {
      if (customerAlreadyExists) {
        setStatusMessageAlert({
          status: 'info',
          message: 'Só um momento, estamos finalizando o processo...',
        });

        const data = await verifyTemporaryAccountCreation(proposalUuid);
        handleAccountStatus(data);
      } else {
        setStepNumber(4);
      }
    }
  }

  function handleAccountStatus(data: {
    status: string;
    account: React.SetStateAction<string>;
    bankCode: React.SetStateAction<string>;
    agency: React.SetStateAction<string>;
  }) {
    if (data.status !== 'REJECTED' && data.status !== 'ACTIVE') {
      setTimeout(verifyTemporaryAccountCreation, 30000);
    }

    if (data.status === 'REJECTED') {
      setStatusMessageAlert({
        status: 'error',
        message: 'Ocorreu um erro na finalização do processo.',
      });
      return;
    }

    if (data.status === 'ACTIVE') {
      setStatusMessageAlert(null);
      setTempAccountNumber(data.account);
      setTempBankNumber(data.bankCode);
      setTempAgency(data.agency);
      setProceedInclude(true);
    }
  }

  useEffect(() => {
    handleTemporaryAccountCreation();
  }, [proceedVerifyTemporaryAccount]);

  function formatDate(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  async function handleNext(e: FormEvent) {
    e.preventDefault();

    if (stepNumber === 2) {
      if (!validationFormByStep(2)) {
        return;
      }
      if (integrationName === 'zipdin') {
        setStepNumber(4);
        return;
      }
    }

    if (stepNumber === 3) {
      if (!validationFormByStep(3)) {
        return;
      }

      setStatusMessageAlert({
        status: 'info',
        message: 'Validando informação...',
      });

      if (!includeFirstTry) {
        includeProposal();
        return;
      }

      try {
        const data = await handleDictEntries(cpf, pixType, formattedPixValue);

        if (data.status === 'success') {
          setStatusMessageAlert(null);
          setBank(data.bankAccount.bankNumber);
          setBankAccountType(data.bankAccount.bankAccountType);
          setAgency(data.bankAccount.agency);
          setAccount(data.bankAccount.accountNumber);
          setDigitAccount(data.bankAccount.digitAccount);
          setProceedVerifyTemporaryAccount(true);
          return;
        } else if (data.status === 'error') {
          setStatusMessageAlert({
            status: 'error',
            message: data.message,
          });
          return;
        } else {
          handleGenericErrorStatus();
          return;
        }
      } catch (error) {
        handleGenericErrorStatus();
        return;
      }
    }

    if (stepNumber === 4) {
      if (!validationFormByStep(4)) {
        return;
      }

      if (integrationName === 'zipdin') {
        includeProposal();
        return;
      }

      setStatusMessageAlert({
        status: 'info',
        message: 'Salvando informação...',
      });

      try {
        const response = await handleBankAccountsNatural(
          postalCode,
          formatDate,
          birthDate,
          documentIssueDate,
          documentExpirationDate,
          cpf,
          documentType,
          cellPhoneNumber,
          name,
          civilStatus,
          nationality,
          incomeValue,
          email,
          motherName,
          address,
          streetNumber,
          neighborhood,
          city,
          uf,
          documentNumber,
          documentIssuingAgency,
          documentState,
        );

        if (response.data.status === 'success') {
          setStatusMessageAlert(null);
          setFgtsPayPage(7);
        } else if (response.data.status === 'error') {
          setStatusMessageAlert({
            status: 'error',
            message: response.data.message,
          });
          return;
        } else {
          handleGenericErrorStatus();
          return;
        }
      } catch (error) {
        handleGenericErrorStatus();
        return;
      }
    }

    const nextStepNumber = stepNumber + 1;
    setStatusMessageAlert(null);
    setHaveError(false);

    if (validationFormByStep(stepNumber)) {
      if (nextStepNumber === 4) {
        setFgtsPayPage(7);
      } else {
        setStepNumber(nextStepNumber);
      }
    }
  }

  async function handleProcessFinalization() {
    setStatusMessageAlert({
      status: 'info',
      message: 'Buscando dados do parceiro...',
    });

    try {
      const data = await verifyTemporaryAccountCreation(proposalUuid);
      handleAccountStatus(data);
    } catch (error) {
      handleGenericErrorStatus();
      return;
    }
  }

  if (integrationName === 'zipdin') {
    return (
      <section className="fgts-pay-section">
        <div className="fgts-pay-container">
          {fgtsPayPage === 1 && (
            <IntroPage
              orderId={orderId}
              isLoading={isLoading}
              orderValue={orderValue}
              setFgtsPayPage={setFgtsPayPage}
              statusMessageAlert={statusMessageAlert}
            />
          )}
          {fgtsPayPage === 2 && (
            <ExplanationPage setFgtsPayPage={setFgtsPayPage} />
          )}
          {fgtsPayPage === 3 && (
            <DataForSimulationPage
              name={name}
              setName={setName}
              cpf={cpf}
              setCpf={setCpf}
              birthDate={birthDate}
              setBirthDate={setBirthDate}
              cellPhoneNumber={cellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              email={email}
              setEmail={setEmail}
              setFgtsPayPage={setFgtsPayPage}
              handleBalance={handleBalance}
              statusMessageAlert={statusMessageAlert}
            />
          )}
          {fgtsPayPage === 4 && (
            <SimulationPage
              simulationInfo={simulationInfo}
              setFgtsPayPage={setFgtsPayPage}
              statusMessageAlert={statusMessageAlert}
              handleSimulateAgain={handleSimulateAgain}
              integrationName={integrationName}
            />
          )}
          {/* {fgtsPayPage === 5 && (
            <TermsPage
              saveTermsAndConditionsApprovalAndFinishCustomerCreation=
              {saveTermsAndConditionsApprovalAndFinishCustomerCreation}
              statusMessageAlert={statusMessageAlert}
            />
          )} */}
          {fgtsPayPage === 6 && (
            <ComplementaryData
              handleNext={handleNext}
              stepNumber={stepNumber}
              haveError={haveError}
              cpf={cpf}
              setCpf={setCpf}
              email={email}
              setEmail={setEmail}
              cellPhoneNumber={cellPhoneNumber}
              setCellPhoneNumber={setCellPhoneNumber}
              incomeValue={incomeValue}
              setIncomeValue={setIncomeValue}
              occupation={occupation}
              setOccupation={setOccupation}
              nationality={nationality}
              setNationality={setNationality}
              gender={gender}
              setGender={setGender}
              civilStatus={civilStatus}
              setCivilStatus={setCivilStatus}
              motherName={motherName}
              setMotherName={setMotherName}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              address={address}
              setAddress={setAddress}
              streetNumber={streetNumber}
              setStreetNumber={setStreetNumber}
              neighborhood={neighborhood}
              setNeighborhood={setNeighborhood}
              city={city}
              setCity={setCity}
              uf={uf}
              setUf={setUf}
              customerAlreadyExists={customerAlreadyExists}
              pixType={pixType}
              handlePixTypeChange={handlePixTypeChange}
              pixValue={pixValue}
              handlePixValueChange={handlePixValueChange}
              documentType={documentType}
              setDocumentType={setDocumentType}
              documentNumber={documentNumber}
              setDocumentNumber={setDocumentNumber}
              documentIssueDate={documentIssueDate}
              setDocumentIssueDate={setDocumentIssueDate}
              documentIssuingAgency={documentIssuingAgency}
              setDocumentIssuingAgency={setDocumentIssuingAgency}
              documentState={documentState}
              setDocumentState={setDocumentState}
              documentExpirationDate={documentExpirationDate}
              setDocumentExpirationDate={setDocumentExpirationDate}
              statusMessageAlert={statusMessageAlert}
              handlePrevious={handlePrevious}
            />
          )}
          {fgtsPayPage === 8 && <ConclusionPage />}
        </div>
      </section>
    );
  }

  return (
    <section className="fgts-pay-section">
      <div className="fgts-pay-container">
        {fgtsPayPage === 1 && (
          <IntroPage
            orderId={orderId}
            isLoading={isLoading}
            orderValue={orderValue}
            setFgtsPayPage={setFgtsPayPage}
            statusMessageAlert={statusMessageAlert}
          />
        )}
        {fgtsPayPage === 2 && (
          <ExplanationPage setFgtsPayPage={setFgtsPayPage} />
        )}
        {fgtsPayPage === 3 && (
          <DataForSimulationPage
            name={name}
            setName={setName}
            cpf={cpf}
            setCpf={setCpf}
            birthDate={birthDate}
            setBirthDate={setBirthDate}
            cellPhoneNumber={cellPhoneNumber}
            setCellPhoneNumber={setCellPhoneNumber}
            email={email}
            setEmail={setEmail}
            setFgtsPayPage={setFgtsPayPage}
            handleBalance={handleBalance}
            statusMessageAlert={statusMessageAlert}
          />
        )}
        {fgtsPayPage === 4 && (
          <SimulationPage
            simulationInfo={simulationInfo}
            setFgtsPayPage={setFgtsPayPage}
            statusMessageAlert={statusMessageAlert}
            handleSimulateAgain={handleSimulateAgain}
          />
        )}
        {fgtsPayPage === 5 && (
          <TermsPage
            saveTermsAndConditionsApprovalAndFinishCustomerCreation={
              saveTermsAndConditionsApprovalAndFinishCustomerCreation
            }
            statusMessageAlert={statusMessageAlert}
          />
        )}
        {fgtsPayPage === 6 && (
          <ComplementaryData
            handleNext={handleNext}
            stepNumber={stepNumber}
            haveError={haveError}
            cpf={cpf}
            setCpf={setCpf}
            email={email}
            setEmail={setEmail}
            cellPhoneNumber={cellPhoneNumber}
            setCellPhoneNumber={setCellPhoneNumber}
            incomeValue={incomeValue}
            setIncomeValue={setIncomeValue}
            occupation={occupation}
            setOccupation={setOccupation}
            nationality={nationality}
            setNationality={setNationality}
            civilStatus={civilStatus}
            setCivilStatus={setCivilStatus}
            motherName={motherName}
            setMotherName={setMotherName}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            address={address}
            setAddress={setAddress}
            streetNumber={streetNumber}
            setStreetNumber={setStreetNumber}
            neighborhood={neighborhood}
            setNeighborhood={setNeighborhood}
            city={city}
            setCity={setCity}
            uf={uf}
            setUf={setUf}
            customerAlreadyExists={customerAlreadyExists}
            pixType={pixType}
            handlePixTypeChange={handlePixTypeChange}
            pixValue={pixValue}
            handlePixValueChange={handlePixValueChange}
            documentType={documentType}
            setDocumentType={setDocumentType}
            documentNumber={documentNumber}
            setDocumentNumber={setDocumentNumber}
            documentIssueDate={documentIssueDate}
            setDocumentIssueDate={setDocumentIssueDate}
            documentIssuingAgency={documentIssuingAgency}
            setDocumentIssuingAgency={setDocumentIssuingAgency}
            documentState={documentState}
            setDocumentState={setDocumentState}
            documentExpirationDate={documentExpirationDate}
            setDocumentExpirationDate={setDocumentExpirationDate}
            statusMessageAlert={statusMessageAlert}
            handlePrevious={handlePrevious}
          />
        )}
        {fgtsPayPage === 7 && (
          <SdkPage
            statusMessageAlert={statusMessageAlert}
            setStatusMessageAlert={setStatusMessageAlert}
            handleNext={handleNext}
            cpf={cpf}
            handleProcessFinalization={handleProcessFinalization}
          />
        )}
        {fgtsPayPage === 8 && <ConclusionPage />}
      </div>
    </section>
  );
};

export default PaymentProcess;
