import React, { useState } from "react"
import { Link } from "react-router-dom"

const TermsPage = (
  {
    saveTermsAndConditionsApprovalAndFinishCustomerCreation,
    statusMessageAlert
  }
) => {
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  return (
    <>
      <div className="hiring-term-main-page-container">
        <h3>
          Para prosseguirmos com a operação, por gentileza aceite os
          termos e condições
        </h3>
        <div className="hiring-term-link-container">
          <Link to="/terms-and-conditions-fgts-pay" target="_blank">
            Clique aqui para ler os termos e condições
          </Link>
          <div className="hiring-term-checkbox-container">
            <input
              type="checkbox"
              checked={acceptTerms}
              onClick={() => setAcceptTerms(!acceptTerms)}
            ></input>
            <label onClick={() => setAcceptTerms(!acceptTerms)}>
              Aceitar
            </label>
          </div>
        </div>
        {acceptTerms ? (
          <button
            onClick={() =>
              saveTermsAndConditionsApprovalAndFinishCustomerCreation()
            }
          >
            Prosseguir
          </button>
        ) : (
          ''
        )}
        {statusMessageAlert && (
          <div
            className={
              statusMessageAlert.status === 'error'
                ? 'alert alert-danger alert-text-center'
                : 'alert alert-primary alert-text-center'
            }
            role="alert"
          >
            {statusMessageAlert.message}
          </div>
        )}
      </div>
    </>
  )
}

export default TermsPage
