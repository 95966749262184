import { BsAlarm, BsTrophy, BsWallet } from 'react-icons/bs';
import { GiArcheryTarget } from 'react-icons/gi';

import classes from './styles.module.scss';

function Benefits() {
    return (
        <section className={classes.about} id="about">
            <div className="container">
                <h5>EXPERIÊNCIA E SEGURANÇA</h5>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <h2>Vantagens de contratar o Crédito Consignado conosco</h2>
                    </div>
                </div>
                <div className={`row ${classes.iconsList}`}>
                    <div className="col-md-3">
                        <BsTrophy className={classes.icon} />
                        <h6>
                            Taxas<br />
                            competitivas
                        </h6>
                        <p>Crédito mais barato para você</p>
                    </div>
                    <div className="col-md-3">
                        <BsAlarm className={classes.icon} />
                        <h6>
                            Prazos<br />
                            acessíveis
                        </h6>
                        <p>Até 96 meses para pagamento</p>
                    </div>
                    <div className="col-md-3">
                        <GiArcheryTarget className={classes.icon} />
                        <h6>
                            Praticidade
                        </h6>
                        <p>Desconto em folha com parcelas fixas</p>
                    </div>
                    <div className="col-md-3">
                        <BsWallet className={classes.icon} />
                        <h6>
                            Agilidade
                        </h6>
                        <p>Crédito rápido e fácil</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits;
