import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (apiData: unknown[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          'Uuid',
          'Valor',
          'Parcelas',
          'Taxa',
          'Motivo',
          'Id Proposta Banco',
          'Status',
          'Passos',
          'Nome',
          'CPF',
          'Email',
          'Telefone',
          'Profissão',
          'Endereço',
          'Renda',
          'Data de nascimento',
          'Id Contato Huggy',
          'Data de criação',
          'Data de atualização',
          'Usuário',
          'Produto',
          'Integração',
        ],
      ],
      { origin: 'A1' },
    );
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button onClick={() => exportToExcel(apiData, fileName)}>Exportar dados</button>
  );
};
