import crypto from 'crypto';

const replaceSpecialChars = (b64string: string) => {
  b64string = b64string.replace(/=+$/, '');
  b64string = b64string.replace(/\+/g, '-');
  b64string = b64string.replace(/\//g, '_');

  return b64string;
};

export const getTokenSdkCaf = () => {
  // const clientId = '64a560af2ad20c00087bac50';
  // const clientSecret =
  //   'XEaaOKSrP8tNKPI5rM6fSo68gicCVMcwFl4Dxh4g5sIwQFsjxp9gawwaqXJk2YNUMNpD7uFO3CPmdJtQykMPQ';
  // const header = {
  //   alg: 'HS256',
  //   typ: 'JWT',
  // };
  // const encodedHeaders = replaceSpecialChars(btoa(JSON.stringify(header)));
  // const claims = {
  //   iss: clientId,
  //   // exp: 3600,
  //   // peopleId: 'tnb',
  // };
  // const encodedPlayload = replaceSpecialChars(btoa(JSON.stringify(claims)));
  // const signature = crypto.createHmac('sha256', clientSecret);

  // signature.update(encodedHeaders + '.' + encodedPlayload);

  // const encodedSignature = signature.digest('base64');
  // const jwt = `${encodedHeaders}.${encodedPlayload}.${replaceSpecialChars(
  //   encodedSignature,
  // )}`;

  // return jwt;
};
