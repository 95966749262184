import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Layout from '../../../../components/Admin/Layout';
import { api } from '../../../../services/api';
import { ParamsType } from '../AlertEnabledBank';
import { LocationStateType } from '../Create';

import './styles.scss';

export type StatusAlertType = {
  status: string;
  message: string;
};

const Balance = () => {
  let history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationStateType;
  const { proposalUuid } = useParams<ParamsType>();
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [installments, setInstallments] = useState<any[]>([]);
  const [checkedState, setCheckedState] = useState<any[]>([]);
  const [totalValueInstallments, setTotalValueInstallments] =
    useState<number>(0);
  const [totalInstallments, setTotalInstallments] = useState<number>(7);
  const [simulateButton, setSimulateButton] = useState<boolean>(true);
  const regexMoney = /\d(?=(\d{3})+,)/g;

  useEffect(() => {
    requestBalanceFgts();
  }, []);

  function sumInstallments(installments: any) {
    return installments.reduce(
      (sum: number, currentState: { checked: boolean; valor: number }) => {
        if (currentState.checked) {
          return sum + currentState.valor;
        }

        return sum;
      },
      0,
    );
  }

  function clearStates() {
    setInstallments([]);
    setCheckedState([]);
    setTotalValueInstallments(0);
    setTotalInstallments(7);
  }

  async function requestBalanceFgts() {
    clearStates();

    try {
      const { data } = await api.post('/fgts/balance', {
        proposalUuid,
      });

      if (data.status === 'error') {
        if (
          data.message === 'Não foi possível consultar o saldo.' ||
          data.message === '' ||
          data.message === 'Rate limit exceeded'
        ) {
          // setTimeout(requestBalanceFgts, 60000)
          // setStatusMessageAlert(null)
          // setSimulateButton(false)
        }
      }

      if (data.balance === 0) {
        setStatusMessageAlert({
          status: 'error',
          message:
            'Infelizmente não existe saldo suficiente para prosseguir com a operação.',
        });
        setSimulateButton(false);
      }

      if (data.status === 'success') {
        const installmentsData = data.installments.map(
          (parcel: { date: any; value: any }) => {
            return {
              dataVencimento: parcel.date,
              valor: parcel.value,
              checked: true,
            };
          },
        );
        const installmentsFilteredValueEmpty = installmentsData.filter(
          (parcel: { valor: number }) => parcel.valor > 0,
        );
        const totalValue = sumInstallments(installmentsFilteredValueEmpty);

        setInstallments(installmentsFilteredValueEmpty);
        setCheckedState(installmentsFilteredValueEmpty);
        setTotalValueInstallments(totalValue);
        setTotalInstallments(installmentsFilteredValueEmpty.length);
        setSimulateButton(true);
        setStatusMessageAlert(null);
      } else if (data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: data.message,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu, tente novamente.',
        });
      }
    } catch (error) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Ops, algo ocorreu, tente novamente.',
      });
    }
  }

  function onChangeTotalInstallments(parcel: number) {
    setTotalInstallments(parcel);

    const newInstallments = installments.map(
      (parcelData: any, parcelNumber: number) => {
        return {
          dataVencimento: parcelData.dataVencimento,
          valor: parcelData.valor,
          checked: parcelNumber + 1 <= parcel ? true : false,
        };
      },
    );
    const newCheckedState = newInstallments.filter((parcel) => parcel.checked);
    const totalValue = sumInstallments(newInstallments);

    setInstallments(newInstallments);
    setCheckedState(newCheckedState);
    setTotalValueInstallments(totalValue);
  }

  const handleSimulate = () => {
    const data = {
      parcelas: checkedState.length,
      totalValueInstallments,
    };

    history.push({
      pathname: `/admin/fgts/${proposalUuid}/simulation`,
      state: {
        ...locationState,
        ...data,
      },
    });
  };

  return (
    <Layout
      pageBreadcrumb={`Criar proposta - ${locationState.name} (${locationState.cpf}) - [${locationState.integrationName}]`}
      pageTitle="Parcelas"
    >
      <div className="admin balance">
        <div className="row">
          <div>
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger'
                    : 'alert alert-primary'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
          </div>
        </div>
        <h3 style={{ marginBottom: '10px' }}>
          Abaixo estão as parcelas que o cliente poderá antecipar.
        </h3>
        <div className="row">
          <div className="table-responsive">
            <table className="table table-light table-sm table-hover">
              <thead>
                <tr>
                  <th>N° da parcela</th>
                  <th>Valor disponível</th>
                  <th>Data saque</th>
                </tr>
              </thead>
              <tbody>
                {installments?.map((parcelData: any, parcelNumber: number) => (
                  <tr
                    key={parcelNumber}
                    className={!parcelData.checked ? 'unchecked' : ''}
                  >
                    <td>{parcelNumber + 1}</td>
                    <td>
                      R${' '}
                      {parcelData.valor
                        .toFixed(2)
                        .replace('.', ',')
                        .replace(regexMoney, '$&.')}
                    </td>
                    <td>
                      {format(
                        parseISO(parcelData.dataVencimento),
                        'dd/MM/yyyy',
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <thead>
                <tr>
                  <th style={{ textAlign: 'right' }}>Valor selecionado</th>
                  <th colSpan={2} style={{ textAlign: 'left' }}>
                    R${' '}
                    {totalValueInstallments
                      .toFixed(2)
                      .replace('.', ',')
                      .replace(regexMoney, '$&.')}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-12">
            <label style={{ marginTop: '10px' }}>
              Quantas parcelas o cliente deseja antecipar?
            </label>
            <select
              className="form-control"
              value={totalInstallments}
              onChange={(e: any) => onChangeTotalInstallments(e.target.value)}
            >
              {installments?.map((parcelData: any, parcelNumber: number) => (
                <option key={parcelNumber + 1} value={parcelNumber + 1}>
                  {parcelNumber + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        {simulateButton ? (
          <div className="form-row">
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ marginTop: '15px' }}
              onClick={handleSimulate}
            >
              Simular
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </Layout>
  );
};

export default Balance;
