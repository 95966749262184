import React from 'react';
import { useEffect, useState } from 'react';
import { api } from '../../../../../services/api';
import Carousel from 'react-elastic-carousel';
import './styles.scss';

import step1MercantilImg from '../../../../../assets/release-steps/mercantil/1.png';
import step2MercantilImg from '../../../../../assets/release-steps/mercantil/2.png';
import step3MercantilImg from '../../../../../assets/release-steps/mercantil/3.png';
import step4MercantilImg from '../../../../../assets/release-steps/mercantil/4.png';
import step5MercantilImg from '../../../../../assets/release-steps/mercantil/5.png';
import step6MercantilImg from '../../../../../assets/release-steps/mercantil/6.png';
import step7MercantilImg from '../../../../../assets/release-steps/mercantil/7.png';
import step8MercantilImg from '../../../../../assets/release-steps/mercantil/8.png';
import step9MercantilImg from '../../../../../assets/release-steps/mercantil/9.png';
import step10MercantilImg from '../../../../../assets/release-steps/mercantil/10.png';

import step1ZipdinImg from '../../../../../assets/release-steps/zipdin/1.png';
import step2ZipdinImg from '../../../../../assets/release-steps/zipdin/2.png';
import step3ZipdinImg from '../../../../../assets/release-steps/zipdin/3.png';
import step4ZipdinImg from '../../../../../assets/release-steps/zipdin/4.png';
import step5ZipdinImg from '../../../../../assets/release-steps/zipdin/5.png';
import step6ZipdinImg from '../../../../../assets/release-steps/zipdin/6.png';
import step7ZipdinImg from '../../../../../assets/release-steps/zipdin/7.png';
import step8ZipdinImg from '../../../../../assets/release-steps/zipdin/8.png';
import step9ZipdinImg from '../../../../../assets/release-steps/zipdin/9.png';
import step10ZipdinImg from '../../../../../assets/release-steps/zipdin/10.png';



type ReleaseType = {
  changePage: (pageName: string) => void;
  changeLoadingModal: (loading: boolean) => void;
};

export type StepByStepItem = {
  id: number;
  img: string;
};

export default function Release({
  changePage,
  changeLoadingModal,
}: ReleaseType) {
  const [, setIntegrations] = useState<any>([]);
  const [integrationName, setIntegrationName] = useState<string>('');

  const stepByStepBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 2, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 2 },
    { width: 1750, itemsToShow: 2 },
  ];

  const stepByStepItems: StepByStepItem[] = [];

  if (integrationName === 'zipdin') {
    stepByStepItems.push(
      { id: 1, img: step1ZipdinImg },
      { id: 2, img: step2ZipdinImg },
      { id: 3, img: step3ZipdinImg },
      { id: 4, img: step4ZipdinImg },
      { id: 5, img: step5ZipdinImg },
      { id: 6, img: step6ZipdinImg },
      { id: 7, img: step7ZipdinImg },
      { id: 8, img: step8ZipdinImg },
      { id: 9, img: step9ZipdinImg },
      { id: 10, img: step10ZipdinImg },
    );
  }

  if (integrationName === 'mercantil') {
    stepByStepItems.push(
      { id: 1, img: step1MercantilImg },
      { id: 2, img: step2MercantilImg },
      { id: 3, img: step3MercantilImg },
      { id: 4, img: step4MercantilImg },
      { id: 5, img: step5MercantilImg },
      { id: 6, img: step6MercantilImg },
      { id: 7, img: step7MercantilImg },
      { id: 8, img: step8MercantilImg },
      { id: 9, img: step9MercantilImg },
      { id: 10, img: step10MercantilImg },
    );
  }

  function handleGoBalancePage() {
    changeLoadingModal(true);
    changePage('Balance');
  }

  async function getIntegrations() {
    const { data } = await api.get(
      '/integration-product/product/name/Saque aniversário FGTS/integrations',
    );
    setIntegrations(data);

    const integrationDefault = data.filter(
      (integration: { default: boolean }) => integration.default === true,
    );

    setIntegrationName(integrationDefault[0].integration.name);
  }

  useEffect(() => {
    getIntegrations();
  }, []);

  return (
    <>
      <div className="modal-body release">
        <div className="container">
          <h3>
            Agora precisamos que você autorize que consultemos o seu saldo de
            FGTS. Só seguir os passos abaixo.
          </h3>
          <Carousel breakPoints={stepByStepBreakPoints}>
            {stepByStepItems.map((item) => (
              <div key={item.id}>
                <img src={item.img} alt={`Passo ${item.id}`} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleGoBalancePage}
        >
          Já autorizei
        </button>
      </div>
    </>
  );
}
