import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

import '../../styles/global.scss';

import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/Button';

import logoImg from '../../assets/logo/logo_header.svg';

type StatusAlertType = {
  status: string;
  message: string;
}

export default function RecoverPassword() {
  const [statusMessageAlert, setStatusMessageAlert] = useState<StatusAlertType | null>(null);
  const [cpf, setCpf] = useState<string>('');

  const { loading, handleSendRecoverEmail } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === "true") {
      setCpf(process.env.REACT_APP_FAKER_CPF_FGTS);
    }
  }, []);

  async function handleRecoverPassword() {
    const unmaskedCpf = cpf.replace(/[^\d]+/g, '');
    setStatusMessageAlert(null);

    if (cpf === '') {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha o CPF para continuar.',
      });
      
      return;
    }

    const response = await handleSendRecoverEmail(unmaskedCpf);
    
    if (response) {
      setStatusMessageAlert({
        status: 'info',
        message: response.message,
      });
    } else {
      setStatusMessageAlert({
        status: 'error',
        message: 'Dados incorretos, favor verificar e tentar novamente.',
      });
    }
  }

  return (
    <div id="page-auth">
      <aside>
        <strong>Recuperação de senha</strong>
        <p>Aqui você consegue recuperar sua conta que esqueceu a senha.</p>
      </aside>
      <main>  
        <div className="main-content">
          <div className="logoTnb"><img src={logoImg} alt="Ta no bolso" /></div>
          <form onSubmit={() => {}}>
            <h2>Recuperar sua senha</h2>
            <div className="form-row">
              <div className="form-group col-md-12">
              <label>CPF</label>
                <InputMask
                  name="cpf"
                  mask="999.999.999-99"
                  className={`form-control`}
                  onChange={(e: any) => setCpf(e.target.value)}
                  autoComplete="off"
                  placeholder=""
                  value={cpf}
                />
              </div>
            </div>
            {statusMessageAlert && (
                <div className={statusMessageAlert.status === 'error' ? 'alert alert-danger alert-text-center' : 'alert alert-primary alert-text-center'} role="alert">{statusMessageAlert.message}</div>
            )}
            <Button type="button" onClick={handleRecoverPassword}>
              {!loading ? 'Enviar' : 'Enviando...'}
            </Button>
            <p><Link to="/signin">Voltar ao login.</Link></p>
          </form>
        </div>
      </main>
    </div>
  );
}
