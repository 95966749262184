import { useContext } from 'react';

import { LandingPageContext } from '../contexts/LandingPageContext';

function useLandingPage() {
  const context = useContext(LandingPageContext);

  return context;
}

export { useLandingPage };
