import React from 'react';

import Button from '../../../../../button/Button';

import './styles.scss';

function BidOffer({
  proposalId,
  bankProposalId,
  offer,
}: {
  proposalId: string;
  bankProposalId: string;
  offer: number;
}) {
  const regexMoney = /\d(?=(\d{3})+,)/g;
  // const financedAmount = parseFloat(simulatedData.financedAmount).toFixed(0).replace(regexMoney, '$&.');
  // const financedAmountCents = String(simulatedData.financedAmount).substr(-2);
  // const availablePaymentCapacity = parseFloat(simulatedData.availablePaymentCapacity).toFixed(2).replace('.', ',').replace(regexMoney, '$&.');
  // const operationGrossValue = parseFloat(simulatedData.operationGrossValue).toFixed(2).replace('.', ',').replace(regexMoney, '$&.');
  // const installments = simulatedData.installments;
  // const iofValue = parseFloat(simulatedData.iof).toFixed(2).replace('.', ',');
  // const iofPercent = simulatedData.percentageCompositionCet.iof;
  // const monthlyRate = parseFloat(simulatedData.rate).toFixed(2).replace('.', ',');
  // const yearlyRate = parseFloat(simulatedData.yearlyRate).toFixed(2).replace('.', ',');
  // const monthlyCet = parseFloat(simulatedData.monthlyCet).toFixed(2).replace('.', ',');
  // const yearlyCet = parseFloat(simulatedData.yearlyCet).toFixed(2).replace('.', ',');

  return (
    <>
      <div className="modal-body">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="stepBar">
                <li className="active" key="simulation"><i className="fa fa-money"><strong>Simulação</strong></i></li>
                <li key="complementaryData"><i className="fa fa-id-card"><strong>Dados Complementares</strong></i></li>
                <li key="adhesionContract"><i className="fa fa-file-text"><strong>Contrato de adesão</strong></i></li>
                <li key="completedRequest"><i className="fa fa-check-circle"><strong>Solicitação concluída</strong></i></li>
              </ul>
            </div>
          </div>

          <div className="container-bid-offer">
            <h3 style={{marginBottom: '15px'}}>
              Você sabia que tem limite liberado de <span className="offer-value">R$ {offer.toFixed(2).replace('.', ',').replace(regexMoney, '$&.')}</span>?
            </h3>
            <h3>Que tal simular?</h3>

            {/* <div className="row justify-content-md-center">
              <div className="col-md-3 col-sm-6">
                <div className="pricingTable10">
                  <div className="pricingTable-header">
                      <h3 className="heading">Você vai receber</h3>
                      <span className="price-value">
                          <span className="currency">R$</span>
                          <span>{financedAmount}</span>
                          <span className="cents">,{financedAmountCents}</span>
                      </span>
                  </div>
                  <div className="pricing-content">
                      <ul>
                          <li>Valor da parcela: <span>R$ {availablePaymentCapacity}</span></li>
                          <li>N° de parcelas: <span>{installments}</span></li>
                          <li>Total à pagar: <span>R$ {operationGrossValue}</span></li>
                          <li className="info-simulation">
                            IOF: <span>R$ {iofValue}</span><br />
                            Taxa de juros: <span>{monthlyRate}% a.m.</span> / <span>{yearlyRate}% a.a.</span><br />
                            CET: <span>{monthlyCet}% a.m.</span> / <span>{yearlyCet}% a.a.</span><br />
                          </li>
                      </ul>
                  </div>
                </div>
              </div>
          </div> */}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <span style={{ width: '100%' }}>
          <Button onClick={() => {}} className="button-accept" style={{width: '15rem'}}>
            Sim, vamos lá!
          </Button>
          <Button onClick={() => {}} className="button-accept" style={{width: '15rem'}}>
            Não, obrigado!
          </Button>
        </span>
      </div>
    </>
  )
}

export default BidOffer;
