import { scroller } from 'react-scroll';
import { BiCheckCircle } from 'react-icons/bi';

import polygonBlueImg from '../../../assets/shapes/polygon_blue.svg';
import polygonOrangeImg from '../../../assets/shapes/polygon_orange.svg';
import posterManImg from '../../../assets/peoples/poster-man.svg';

import './styles.scss';

type AboutTypes = {
  partnerPage: boolean;
};

function handleSimulateNow() {
  scroller.scrollTo('simulate-card', {
    duration: 500,
    delay: 100,
    smooth: true,
    offset: -80,
  });
}

function About({ partnerPage = false }: AboutTypes) {
  return (
    <section className={`new-about`} id="about">
      <div className={`container ${partnerPage ? 'partner-fgts' : ''}`}>
        <h5>SAIBA MAIS</h5>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h2>O que é a antecipação do saque-aniversário do FGTS?</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-9">
            <p className="subtitle">
              Agora, o trabalhador brasileiro que tem saldo FGTS conta com esta
              nova modalidade de crédito. Com ela é possível antecipar até 10
              saques-aniversário sem qualquer burocracia.
            </p>
          </div>
        </div>
        {partnerPage && (
          <div className="requeriments-fgts">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h4>
                  Quais são os requisitos para usufruir da antecipação do
                  saque-aniversário?
                </h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <ul>
                  <li>
                    <span>
                      <BiCheckCircle className="icon" />
                    </span>
                    Ser maior de 18 anos ou emancipado
                  </li>
                  <li>
                    <span>
                      <BiCheckCircle className="icon" />
                    </span>
                    Ter saldo do FGTS em conta ativa ou inativa
                  </li>
                  <li>
                    <span>
                      <BiCheckCircle className="icon" />
                    </span>
                    Ser optante da modalidade saque-aniversário do FGTS
                  </li>
                  <li>
                    <span>
                      <BiCheckCircle className="icon" />
                    </span>
                    Possuir conta corrente ou conta poupança
                  </li>
                  <li>
                    <span>
                      <BiCheckCircle className="icon" />
                    </span>
                    Ter situação regular com a Receita Federal
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {!partnerPage && (
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="row align-items-center">
                <div className="col-lg-6 col-sm-12">
                  <table>
                    <thead>
                      <tr>
                        <th>Faixa de saldos</th>
                        <th>Alíquota</th>
                        <th>Parcela adicional fixa</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Até R$ 500,00</td>
                        <td>50%</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>
                          De R$ 500,01 até
                          <br />
                          R$ 1 mil
                        </td>
                        <td>40%</td>
                        <td>R$ 50,00</td>
                      </tr>
                      <tr>
                        <td>
                          De R$ 1.000,01 até
                          <br />
                          R$ 5 mil
                        </td>
                        <td>30%</td>
                        <td>R$ 150,00</td>
                      </tr>
                      <tr>
                        <td>
                          De R$ 5.000,01 até
                          <br />
                          R$ 10 mil
                        </td>
                        <td>20%</td>
                        <td>R$ 650,00</td>
                      </tr>
                      <tr>
                        <td>
                          De R$ 10.000,01 até
                          <br />
                          R$ 15 mil
                        </td>
                        <td>15%</td>
                        <td>R$ 1.150,00</td>
                      </tr>
                      <tr>
                        <td>
                          De R$ 15.000,01 até
                          <br />
                          R$ 20 mil
                        </td>
                        <td>10%</td>
                        <td>R$ 1.900,00</td>
                      </tr>
                      <tr>
                        <td>Acima de R$ 20 mil</td>
                        <td>5%</td>
                        <td>R$ 2.900,00</td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="info-table">
                    Para cada faixa de saldo é cobrada uma alíquota mais um
                    valor fixo adicional, descontados do valor total no momento
                    do saque.
                  </p>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="group-shape">
                    <img src={polygonBlueImg} alt="Polygono azul" />
                    <span>
                      <h4>
                        Não perca tempo e simule o seu saldo com a Tá no Bolso!
                      </h4>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        onClick={handleSimulateNow}
                      >
                        SIMULE AGORA
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!partnerPage && (
        <div className="row requirements-fgts">
          <div className="col-lg-6 group-people-shape">
            <img className="people" src={posterManImg} alt="Pessoa" />
            <div className="group-shape">
              <img src={polygonOrangeImg} alt="Polygono laranja" />
              <span>
                <h4>
                  Quais são os requisitos para usufruir da antecipação do
                  saque-aniversário?
                </h4>
              </span>
            </div>
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                <span>
                  <BiCheckCircle className="icon" />
                </span>
                Ser maior de 18 anos ou emancipado
              </li>
              <li>
                <span>
                  <BiCheckCircle className="icon" />
                </span>
                Ter saldo do FGTS em conta ativa ou inativa
              </li>
              <li>
                <span>
                  <BiCheckCircle className="icon" />
                </span>
                Ser optante da modalidade saque-aniversário do FGTS
              </li>
              <li>
                <span>
                  <BiCheckCircle className="icon" />
                </span>
                Possuir conta corrente ou conta poupança
              </li>
              <li>
                <span>
                  <BiCheckCircle className="icon" />
                </span>
                Ter situação regular com a Receita Federal
              </li>
            </ul>
          </div>
        </div>
      )}
    </section>
  );
}

export default About;
