import Carousel from 'react-elastic-carousel';
import { FaQuoteLeft, FaStar, FaRegStar } from 'react-icons/fa';

import classes from './styles.module.scss';

function Testimonial() {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
        { width: 850, itemsToShow: 2 },
        { width: 1150, itemsToShow: 2, itemsToScroll: 1 },
        { width: 1450, itemsToShow: 2 },
        { width: 1750, itemsToShow: 2 },
    ];
    const testimonials = [
        {
            text: 'Venho agradecer à toda a equipa da Tá no Bolso pela atenção, agilidade e principalmente pela seriedade e competência, nos serviços a mim prestado. Nota 100, Parabéns.',
            name: 'Claudinei C',
            job: 'Aposentada',
            stars: 4,
        },
        {
            text: 'Gostei muito do atendimento de vocês.',
            name: 'Simone M',
            job: 'Aposentada',
            stars: 4,
        },
        {
            text: 'Boa noite, queria agradecer a TA NO BOLSO por me ajudar a pagar a continhas de Dezembro e ainda sobrou para ir para a praia com a minha família. O Atendimento nota 1000 com a LU, super atenciosa, educada, obrigada pela ajuda TA NO BOLSO.',
            name: 'Jana',
            job: 'Aposentada',
            stars: 4,
        },
        {
            text: 'Graças a Deus eu estava tão preocupado como ia fazer para conseguir o dinheiro para arrumar as telhas da minha casa que o vento arrancou. Mas graças a Deus eu conseguir adiantar o dinheiro do fundo do de garantia com a tá no bolso.',
            name: 'Reginaldo',
            job: 'Aposentada',
            stars: 4,
        },
        {
            text: 'O atendimento é ótimo ..... super seguro de confiança nota 10000000000000 parabéns Tá no bolso excelente trabalho ❤️👏👏👏👏👏🤝',
            name: 'Luana',
            job: 'Aposentada',
            stars: 4,
        },
        {
            text: 'Porque eu indico o Tá no bolso? Além de um ótimo, excelente atendimento, facilita a nossa vida. Porque o empenho dos funcionários para que os clientes recebam o pedido solicitado de forma rápida, segura e eficaz é sensacional. Solicite você também. 😉',
            name: 'Kayenne',
            job: 'Aposentada',
            stars: 4,
        },
    ];

    function Star({ rating }): any {
        return [...Array(5)].map((item, index) => {
            return (
                <span key={index}>
                    {rating === index ? <FaRegStar /> : <FaStar />}
                </span>
            )
        })
    }

    return (
        <section className={classes.testimonial} id="testimonial">
            <div className="container">
                <h5>DEPOIMENTOS</h5>
                <h2>Veja o que estão falando sobre nós</h2>
                <Carousel breakPoints={breakPoints} className={classes.rec} enableAutoPlay autoPlaySpeed={5000}>
                    {testimonials.map((item, index) => (
                        <div key={index} className={classes.testimonialItem}>
                            <div className={classes.content}>
                                <FaQuoteLeft className={classes.icon} />
                                <p>{item.text}</p>
                                <div className={classes.info}>
                                    {item.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </section>
    )
}

export default Testimonial;
