export const APPS = [
  {
    domain: 'tanobolso',
    subdomain: '',
    main: true,
    returnDomain: false,
  },
  {
    domain: 'tanobolso',
    subdomain: 'paketa',
    main: false,
    returnDomain: false,
  },
  {
    domain: 'paketa',
    subdomain: 'saquefgts',
    main: false,
    returnDomain: true,
  },
  {
    domain: 'uy3',
    subdomain: 'fgts',
    main: false,
    returnDomain: true,
  },
  {
    domain: 'uy3',
    subdomain: 'fgtsramosilva',
    main: false,
    returnDomain: false,
  },
  {
    domain: 'uy3',
    subdomain: 'dbem',
    main: false,
    returnDomain: false,
  },
]
