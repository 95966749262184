import React, { useEffect, useState } from 'react';

import ModalSimulator from '../../../../components/ModalSimulator';

import './styles.scss';

import Release from './Release';
import Balance from './Balance';
import Simulation from './Simulation';
import CustomerData from './CustomerData';
import Concluded from './Concluded';
import { PartnerType } from '../..';

type SimulatorType = {
  showModal: boolean;
  handleCloseSimulator: () => void;
  proposalId: string | null;
  cpf: string;
  name: string;
  phone: string;
  email: string;
  bankData?: {
    bankNumber?: string;
    agency?: string;
    accountNumber?: string;
    digitAccount?: string;
  };
  partnerPage: boolean;
  partnerInfo?: PartnerType;
};

export default function Simulator({
  showModal = false,
  handleCloseSimulator,
  proposalId = null,
  cpf,
  name,
  phone,
  email,
  bankData,
  partnerPage = false,
  partnerInfo,
}: SimulatorType) {
  const [page, setPage] = useState<string>('Release');
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [simulationId, setSimulationId] = useState<string>('');
  const [simulationData, setSimulationData] = useState<{}>({});
  const [concludedMessage, setConcludedMessage] = useState<string>('');
  const [integrationName, setIntegrationName] = useState<string>('')

  useEffect(() => {
    if (showModal) {
      setLoadingModal(false);
      setPage('Release');
    }
  }, [showModal]);

  function handleCloseModal() {
    setLoadingModal(false);
    setPage('Release');
    handleCloseSimulator();
  }

  function changePage(pageName: string) {
    setPage(pageName);
  }

  function changeLoadingModal(loading: boolean) {
    setLoadingModal(loading);
  }

  function goPageSimulationFgts(data: any) {
    setSimulationId('');
    setSimulationData({
      ...data,
      cpf,
      name,
      phone,
      email,
      bankData,
      partnerInfo,
    });
    setPage('Simulation');
  }

  return (
    <ModalSimulator
      visible={showModal}
      handleCloseModal={handleCloseModal}
      loading={loadingModal}
      partnerPage={partnerPage}
      partnerLogo={partnerInfo?.logo || undefined}
    >
      {page === 'Release' && (
        <Release
          changePage={changePage}
          changeLoadingModal={changeLoadingModal}
        />
      )}
      {page === 'Balance' && (
        <Balance
          proposalId={proposalId}
          loading={loadingModal}
          goPageSimulationFgts={goPageSimulationFgts}
          changeLoadingModal={changeLoadingModal}
          cpf={cpf}
          setIntegrationName={setIntegrationName}
        />
      )}
      {page === 'Simulation' && (
        <Simulation
          proposalId={proposalId}
          loading={loadingModal}
          simulationData={simulationData}
          changePage={changePage}
          changeLoadingModal={changeLoadingModal}
        />
      )}
      {page === 'CustomerData' && (
        <CustomerData
          proposalId={proposalId}
          simulationId={simulationId}
          loading={loadingModal}
          simulationData={simulationData}
          changePage={changePage}
          changeLoadingModal={changeLoadingModal}
          setConcludedMessage={setConcludedMessage}
        />
      )}
      {page === 'Concluded' && (
        <Concluded
          changePage={changePage}
          changeLoadingModal={changeLoadingModal}
          partnerLogo={partnerInfo?.logo || undefined}
          concludedMessage={concludedMessage}
          integrationName={integrationName}
        />
      )}
    </ModalSimulator>
  );
}
