import React from "react"


const FgtsPayTermsAndConditions = () => {

  return (
    <div>
      <h1> Termos e condições</h1>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium, beatae tempore nisi quas dolore laborum,
        natus obcaecati rem cumque voluptate harum error alias voluptatum adipisci? Autem perspiciatis et voluptatibus dolorem.
      </p>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium, beatae tempore nisi quas dolore laborum,
        natus obcaecati rem cumque voluptate harum error alias voluptatum adipisci? Autem perspiciatis et voluptatibus dolorem.
      </p>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium, beatae tempore nisi quas dolore laborum,
        natus obcaecati rem cumque voluptate harum error alias voluptatum adipisci? Autem perspiciatis et voluptatibus dolorem.
      </p>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium, beatae tempore nisi quas dolore laborum,
        natus obcaecati rem cumque voluptate harum error alias voluptatum adipisci? Autem perspiciatis et voluptatibus dolorem.
      </p>
    </div>
  )
}

export default FgtsPayTermsAndConditions
