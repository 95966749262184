import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';

import banner1Img from '../../../assets/banner/home_1_g.jpg';
import banner2Img from '../../../assets/banner/home_2_g.jpg';
import banner3Img from '../../../assets/banner/home_3_g.jpg';
import consignadoIcon from '../../../assets/icons/hand_money.svg';
import portabilidadeIcon from '../../../assets/icons/arrows.svg';
import fgtsIcon from '../../../assets/icons/ballons.svg';

import './styles.scss';

function Slider() {
    const history = useHistory();
    const bannerRef = useRef(null);
    const bannerItems = [
        {
            image: banner1Img,
            className: 'box-banner-1',
            title: 'As melhores taxas para aposentados, pensionistas do INSS e servidores públicos',
            subtitle: '',
        },
        {
            image: banner2Img,
            className: 'box-banner-2',
            title: (<>Consignado rápido,<br />fácil e online?</>),
            subtitle: 'Tá no Bolso!',
        },
        {
            image: banner3Img,
            className: 'box-banner-3',
            title: 'Te ajudamos a conquistar seus objetivos!',
            subtitle: 'Faça uma simulação agora.',
        },
    ];
    const productBreakPoints = [
        { width: 1, itemsToShow: 1, itemsToScroll: 1, enableAutoPlay: true, autoPlaySpeed: 5000 },
        { width: 550, itemsToShow: 2, itemsToScroll: 1, enableAutoPlay: true, autoPlaySpeed: 5000 },
        { width: 850, itemsToShow: 2, enableAutoPlay: true, autoPlaySpeed: 5000 },
        { width: 1150, itemsToShow: 3 },
        { width: 1450, itemsToShow: 3 },
        { width: 1750, itemsToShow: 3 },
    ];
    const productItems = [
        {
            icon: consignadoIcon,
            title: (<>Empréstimo<br />consignado</>),
            description: 'Para beneficiários do INSS com pagamento em 10 minutos.',
            linkButton: '/consignado',
        },
        {
            icon: portabilidadeIcon,
            title: (<>Portabilidade do<br />consignado</>),
            description: 'Livre-se de taxas abusivas e pague até 30% mais barato.',
            linkButton: '/portabilidade',
        },
        {
            icon: fgtsIcon,
            title: (<>Antecipação<br />Saque-Aniversário FGTS</>),
            description: 'Antecipe seu saldo FGTS e realize o que quiser. Sem parcela mensal.',
            linkButton: '/fgts',
        },
    ];
    let bannerResetTimeout;

    function handleSimulate(link: string) {
        history.push(link);
    }

    return (
        <section className="slider-page-home">
            <Carousel
                ref={bannerRef}
                itemsToShow={1}
                itemsToScroll={1}
                pagination={false}
                showArrows={false}
                autoPlaySpeed={6000}
                onNextEnd={({ index }) => {
                    clearTimeout(bannerResetTimeout);

                    if (index + 1 === 3) {
                        bannerResetTimeout = setTimeout(() => {
                            bannerRef.current.goTo(0);
                        }, 6000);
                    }
                }}
                enableAutoPlay
            >
                {bannerItems.map((item, index) => {
                    return (
                        <div key={index} className="banner" style={{backgroundImage: `url(${item.image})`}}>
                            <div className="container banner-content">
                                <div className="row justify-content-end">
                                    <div className={`col-lg-5 col-md-8 ${item.className}`}>
                                        <h2 className="title">{item.title}</h2>
                                        <h2 className="subtitle">{item.subtitle}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
            <div className="carousel-products">
                <Carousel
                    breakPoints={productBreakPoints}
                    pagination={false}
                    showArrows={false}
                    enableAutoPlay
                >
                    {productItems.map((item, index) => {
                        const htmlTitle = String(item.title);
                        const stripedHtmlTitle = htmlTitle.replace(/<[^>]+>/g, '');

                        return (
                            <span className="product-card" key={index}>
                                <div className="card">
                                    <div className="card-body">
                                        <img className="card-icon" src={item.icon} alt={stripedHtmlTitle} />
                                        <p className="card-text card-title">
                                            <span>{item.title}</span>
                                        </p>
                                        <p className="card-text card-description">
                                            <span>{item.description}</span>
                                        </p>
                                        <span className="bottom-container">
                                            <button type="button" className="btn btn-lg btn-primary" onClick={() => handleSimulate(item.linkButton)}>SIMULE AGORA</button>
                                        </span>
                                    </div>
                                </div>
                            </span>
                        )
                    })}
                </Carousel>
            </div>
        </section>
    )
}

export default Slider;
