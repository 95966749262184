import React, { createContext, useEffect, useState } from 'react';

import { api } from '../services/api';
import history from '../services/history';
import { SimpleResponse } from '../interfaces/simple-response.interface';
import { ResponseStatus } from '../enums/response-status';

type UserType = {
  id?: string;
  name?: string;
  cpf?: string;
  phone?: string;
  email?: string;
  birthDate?: string;
  role?: string;
  partner?: any;
};

type ErrorResponseType = {
  error: string;
  message: string[] | string;
  statusCode: number;
};

interface AncarLoginResponse {
  status: 'success' | 'error';
  message?: string;
  error?: {
    message?: string;
  };
  userId?: string;
  token?: string;
  storeId?: string;
}

interface ILoginWithToken {
  tokenCheck: string;
  storeId: string;
  cpf: string;
  userId: string;
}

type AuthContextType = {
  authenticated: boolean;
  user: UserType | null;
  loading: boolean;
  handleLogin(cpf: string, password: string): Promise<object>;
  handleLogout(): void;
  handleSendRecoverEmail(email: string): Promise<{ message: string }>;
  handleResetPassword(
    token: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<{ message: string | ErrorResponseType }>;
  handleNewPassword(
    id: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<{ message: string | ErrorResponseType }>;
  activeMenu: boolean;
  toggleMenu(): void;
  ancarLogin(
    ancarToken: string | null,
    storeId: string | null,
  ): Promise<AncarLoginResponse>;
  handleLoginWithToken(
    props: ILoginWithToken,
  ): Promise<SimpleResponse | { token: string }>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

function AuthProvider({ children }) {
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    const storagedToken = localStorage.getItem('@TNB:token');

    if (storagedToken) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(
        storagedToken,
      )}`;

      setUser({
        id: '',
      });

      const fetchUserData = async () => {
        try {
          const { data } = await api.get('/auth/me');

          setUser(data);
        } catch (error) {
          setUser(null);
        }
      };

      fetchUserData();
    }

    setLoading(false);
  }, []);

  function toggleMenu() {
    setActiveMenu(!activeMenu);
  }

  async function handleLogin(cpf: string, password: string) {
    setLoading(true);

    try {
      const {
        data: { token },
      } = await api.post('/auth/signin', {
        cpf,
        password,
      });

      localStorage.setItem('@TNB:token', JSON.stringify(token));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      const { data } = await api.get('/auth/me');

      setUser(data);
      setLoading(false);

      history.push('/admin/home');
    } catch (error) {
      setLoading(false);

      return error.response.data;
    }
  }

  function handleLogout() {
    setUser(null);

    localStorage.removeItem('@TNB:token');

    api.defaults.headers.Authorization = undefined;

    history.push('/signin');
  }

  async function handleSendRecoverEmail(cpf: string) {
    const { data } = await api.post('/auth/send-recover-email', {
      cpf,
    });
    return data;
  }

  async function handleResetPassword(
    token: string,
    password: string,
    passwordConfirmation: string,
  ) {
    const { data } = await api.patch(`/auth/reset-password/${token}`, {
      password,
      passwordConfirmation,
    });

    return data;
  }

  async function handleNewPassword(
    id: string,
    password: string,
    passwordConfirmation: string,
  ) {
    const { data } = await api.patch(`auth/${id}/change-password`, {
      password,
      passwordConfirmation,
    });

    return data;
  }

  async function ancarLogin(
    userId: string | null,
    storeId: string | null,
  ): Promise<AncarLoginResponse> {
    setLoading(true);

    try {
      const { data } = await api.post('/auth/check-ancar-user-id', {
        userId,
        storeId,
      });
      setLoading(false);

      return data;
    } catch (error) {
      setLoading(false);

      return {
        status: 'error',
        message: 'Algo ocorreu ao tentar logar, tenta novamente mais tarde.',
      };
    }
  }

  async function handleLoginWithToken(props: ILoginWithToken) {
    setLoading(true);

    try {
      const { cpf, storeId, tokenCheck, userId } = props;
      const response = await api.post('/auth/signin-with-token', {
        token: tokenCheck,
        cpf,
        storeId,
        userId,
      });

      if (response.data.status === ResponseStatus.ERROR) {
        setLoading(false);
        return response.data;
      }

      const { token } = response.data;

      localStorage.setItem('@TNB:token', JSON.stringify(token));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      const { data } = await api.get('/auth/me');

      setUser(data);
      setLoading(false);

      history.push('/admin/home');
    } catch (error) {
      setLoading(false);

      return error.response.data;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        loading,
        handleLogin,
        handleLogout,
        activeMenu,
        toggleMenu,
        handleSendRecoverEmail,
        handleResetPassword,
        handleNewPassword,
        ancarLogin,
        handleLoginWithToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
