import React, { useState } from 'react';

import Accordion from '../../../components/Accordion';

import classes from './styles.module.scss';

function Faq() {
    const [selectedAccordionFaq, setSelectedAccordionFaq] = useState<number>(1);

    function toggleAccordionFaq(index: number) {
        if (selectedAccordionFaq === index) {
            return setSelectedAccordionFaq(null);
        }

        setSelectedAccordionFaq(index);
    }

    return (
        <section className={classes.faq} id="faq">
            <div className="container">
                <h5>TIRE SUAS DÚVIDAS</h5>
                <h2>Perguntas Frequentes</h2>

                <div>
                    <Accordion id={1} title="Em quanto tempo o troco é liberado em minha conta?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(1)}>
                        O troco será liberado na conta bancária indicada, em um prazo que pode variar de 2 a 5 dias úteis. Este é o prazo médio, e pode variar em cada instituição bancária.
                    </Accordion>
                    <Accordion id={2}  title="Como posso saber o valor liberado?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(2)}>
                        Quanto mais parcelas estiverem sido quitadas, maior será o valor (dentro do limite) liberado, que sempre serão proporcionais ao valor que já foi pago no empréstimo atual.
                    </Accordion>
                    <Accordion id={3}  title="Qual o valor do troco mínimo para realizar a transação de refinanciamento?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(3)}>
                        Esse valor pode variar de banco para banco, mas a partir de R$ 300,00 (trezentos reais) já é possível realizar o refinanciamento do empréstimo.
                    </Accordion>
                    <Accordion id={4} title="Se eu estiver negativado, posso solicitar a refinanciamento do meu empréstimo?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(4)}>
                        Sim, não há o menor problema neste caso, pois não implica em consulta nos órgãos SPC e Serasa.
                    </Accordion>
                    <Accordion id={5} title="Se eu estiver com margem zerada ou margem negativa, posso aderir ao refinanciamento mesmo assim?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(5)}>
                        Sim, você não terá problema, pois a sua margem não se altera na modalidade de refinanciamento.
                    </Accordion>
                    <Accordion id={6} title="Qual o número de parcelas mínimas que devo ter quitadas para solicitar o refinanciamento do meu empréstimo?" selected={selectedAccordionFaq} toggleAccordion={() => toggleAccordionFaq(6)}>
                        Você pode  refinanciar sua dívida, a partir de cinco parcelas pagas, desde que o valor bruto do contrato seja superior a R$ 5.000,00.<br /><br />
                        Se o valor bruto for inferior a R$ 5.000,00, então serão necessárias ao menos 10 parcelas quitadas.
                    </Accordion>
                </div>
            </div>
        </section>
    )
}

export default Faq;
