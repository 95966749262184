import { useState } from 'react';
import { APPS } from './constants';

export const getApp = () => {
  const locationHostname = window.location.hostname;
  const domain = getDomain(locationHostname);
  const subdomain = getSubdomain(locationHostname);
  const main = APPS.find((app) => app.main);

  if (subdomain === '' || subdomain === 'www') return main?.subdomain;

  const app = APPS.find(
    (app) => domain === app.domain && subdomain === app.subdomain,
  );

  if (!app) return main?.subdomain;

  if (app.returnDomain) return app.domain;

  return app.subdomain;
};

export const getDomain = (location: string) => {
  const locationParts = location.split('.');
  const isLocalHost = locationParts.slice(-1)[0] === 'localhost';
  let sliceTill = -2;

  if (isLocalHost) sliceTill = 2;

  const sliceLocation = locationParts.slice(0, sliceTill);

  if (sliceLocation.length > 1) return sliceLocation.slice(1).join('');

  return sliceLocation.join('');
};

export const getSubdomain = (location: string) => {
  const locationParts = location.split('.');
  const isLocalHost = locationParts.slice(-1)[0] === 'localhost';
  let sliceTill = -3;

  if (isLocalHost) sliceTill = -1;

  return locationParts.slice(0, sliceTill).join('');
};

export const isValidCpf = (cpf: any) => {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split('').map((el: string | number) => +el);
  const rest = (count: number) =>
    ((cpf
      .slice(0, count - 12)
      .reduce(
        (soma: number, el: number, index: number) =>
          soma + el * (count - index),
        0,
      ) *
      10) %
      11) %
    10;
  return rest(10) === cpf[9] && rest(11) === cpf[10];
};

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;

export const useCopyToClipboard = (): [CopiedValue, CopyFn] => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Função não suportada, por gentileza copiar manualmente.');
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Cópia falhou', error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64 = reader.result as string;

      resolve(base64.slice(base64.indexOf(',') + 1));
    };

    reader.readAsDataURL(blob);
  });
};
