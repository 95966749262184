import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import InputMask from 'react-input-mask';
import { isValidCpf, useCopyToClipboard } from '../../../../utils/helpers';
import Layout from '../../../../components/Admin/Layout';
import { api } from '../../../../services/api';
import './styles.scss';
import { useAuth } from '../../../../hooks/useAuth';
import CurrencyInput from 'react-currency-input-field';

const CreatePay = () => {
  const { user } = useAuth();
  const [value, copy] = useCopyToClipboard();
  const [haveError, setHaveError] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>('');
  const [orderValue, setOrderValue] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [validCpf, setValidCpf] = useState<boolean>(true);
  const [proposalUuid, setProposalUuid] = useState<string>('');
  const [partnerUuid, setPartnerUuid] = useState<string>('');
  const [frontendLink, setFrontEndLink] = useState<string>('');
  const [whatsAppLink, setWhatsAppLink] = useState<string>('');
  const [pcCopiedMessage, setPcCopiedMessage] = useState<string>('');
  const [WhatsAppCopiedMessage, setWhatsAppCopiedMessage] =
    useState<string>('');
  const [notAllowedMessage, setNotAllowedMessage] = useState<boolean>(false);
  const [integrationName, setIntegrationName] = useState<string>('');
  const [integrationUuid, setIntegrationUuid] = useState<string | undefined>(
    '',
  );

  async function getIntegrations() {
    const { data } = await api.get(
      '/integration-product/product/name/Saque aniversário FGTS/integrations',
    );

    const integrationDefault = data.filter(
      (integration: { default: boolean }) => integration.default === true,
    );

    if (integrationDefault) {
      setIntegrationUuid(integrationDefault[0].integration.uuid);
    }
  }

  useEffect(() => {
    getIntegrations();
  }, []);

  const checkUserPartnerUuid = () => {
    if (!user?.partner) {
      setNotAllowedMessage(true);
      return;
    }

    if (user?.id && user?.partner.uuid) {
      setPartnerUuid(user.partner.uuid);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setCpf('15710912034');
      setOrderId('1999-5');
      setOrderValue('250,35');
    }
  }, []);

  useEffect(() => {
    checkUserPartnerUuid();
  }, [user]);

  useEffect(() => {
    setFrontEndLink(
      `${process.env.REACT_APP_FRONTEND}/fgts-pay/${proposalUuid}`,
    );
    whatsAppLinktext();
  }, [proposalUuid]);

  const whatsAppLinktext = () => {
    const formattedCpf = cpf.replace(/[^0-9]/g, '');
    setWhatsAppLink(
      `https://wa.me/${user?.partner.ownWhatsAppNumber}?text=${formattedCpf}`,
    );
  };

  const checkCpf = () => {
    setValidCpf(true);

    if (cpf === '') {
      setHaveError(true);
      return false;
    }

    if (!isValidCpf(cpf)) {
      setValidCpf(false);
      setHaveError(true);
      return false;
    }

    return true;
  };

  const checkName = () => {
    if (name === '') {
      setHaveError(true);
      return false;
    }

    const splitName = name.split(' ');

    if (splitName.length < 2) {
      setHaveError(true);
      return false;
    }

    return true;
  };

  const checkOrderInfo = () => {
    if (
      orderId === '' ||
      orderId === null ||
      orderValue === '' ||
      orderValue === null ||
      orderValue === '0' ||
      name === '' ||
      phone === ''
    ) {
      setHaveError(true);
      return false;
    }

    return true;
  };

  const createLink = async () => {
    setHaveError(false);

    if (!checkCpf()) return false;

    if (!checkOrderInfo()) return false;

    if (!checkName()) return false;

    setPcCopiedMessage('');
    const formattedOrderValue = orderValue!.replace(',', '.');
    const formattedCpf = cpf.replace(/[^0-9]/g, '');
    const formattedPhone = phone.replace(/[^0-9]/g, '');

    try {
      const { data } = await api.post(`/fgts/pay`, {
        name: name,
        cpf: formattedCpf,
        email: '',
        phone: formattedPhone,
        orderId: orderId,
        value: formattedOrderValue,
        sendWhatsApp: true,
      });

      setProposalUuid(data.proposalUuid);
    } catch (error) {
      console.log(error);
    }
  };

  const copyPcLink = () => {
    copy(frontendLink);
    setPcCopiedMessage('Link copiado!');
    setWhatsAppCopiedMessage('');
    setOrderId('');
    setOrderValue('');
  };

  const copyWhatsAppLink = () => {
    copy(whatsAppLink);
    setWhatsAppCopiedMessage('Link copiado!');
    setPcCopiedMessage('');
    setOrderId('');
    setOrderValue('');
  };

  if (notAllowedMessage) {
    return (
      <Layout
        pageBreadcrumb="Criar proposta-pay"
        pageTitle="Criar proposta-pay"
      >
        <div className="not-allowed-info-container">
          <h2>
            Infelizmente você não possui um parceiro atrelado ao seu usuário.
          </h2>
        </div>
      </Layout>
    );
  }

  return (
    <Layout pageBreadcrumb="Criar proposta-pay" pageTitle="Criar proposta-pay">
      <div className="create-pay-info-container">
        <label>Número da compra *</label>
        <input
          className={`form-control ${haveError && !orderId && 'is-invalid'}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setOrderId(e.target.value)
          }
          value={orderId}
        />
        <div className="invalid-feedback">
          {haveError && !orderId && 'Por favor digite o número da compra.'}
        </div>
      </div>
      <div className="create-pay-info-container">
        <label>Valor da compra *</label>
        <CurrencyInput
          className={`form-control ${
            haveError && (!orderValue || orderValue === '0') && 'is-invalid'
          }`}
          id="inputValue"
          onValueChange={(value) => setOrderValue(value!)}
          value={orderValue}
          prefix={'R$ '}
          decimalsLimit={2}
          decimalSeparator={','}
          groupSeparator={'.'}
        />
        <div className="invalid-feedback">
          {haveError &&
            (!orderValue || orderValue === '0') &&
            'Por favor informe o valor da compra.'}
        </div>
      </div>
      <div className="create-pay-info-container">
        <label>Nome completo *</label>
        <input
          className={`form-control ${
            haveError && (!name || name.split(' ').length < 2) && 'is-invalid'
          }`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          value={name}
        />
        <div className="invalid-feedback">
          {haveError && !name && 'Por favor digite o nome do cliente.'}
          {haveError &&
            name &&
            name.split(' ').length < 2 &&
            'O nome precisa ser completo.'}
        </div>
      </div>
      <div className="create-pay-info-container">
        <label>Celular com WhatsApp e SMS*</label>
        <InputMask
          mask="(99) 99999-9999"
          className={`form-control ${haveError && !phone && 'is-invalid'}`}
          autoComplete="off"
          placeholder=""
          value={phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPhone(e.target.value)
          }
        />
        <div className="invalid-feedback">
          {haveError && !phone && 'Por favor digite um celular.'}
        </div>
      </div>
      <div className="create-pay-info-container">
        <label>CPF *</label>
        <InputMask
          mask="999.999.999-99"
          className={`form-control ${
            haveError && (cpf === '' || !validCpf) && 'is-invalid'
          }`}
          id="inputValue"
          autoComplete="off"
          placeholder=""
          value={cpf}
          onChange={(e: any) => setCpf(e.target.value)}
        />
        <div className="invalid-feedback">
          {haveError && cpf === '' && 'Por favor informe o CPF do cliente.'}
        </div>
        <div className="invalid-feedback">
          {haveError && !validCpf && cpf !== '' && 'Número de CPF inválido'}
        </div>
      </div>
      <div className="create-pay-button-container">
        <button className="create-link-button" onClick={createLink}>
          Gerar proposta
        </button>
      </div>
      <div>
        {proposalUuid ? (
          <div className="link-info-main-container">
            {user?.role === 'ADMIN' || user?.role === 'CONSULTANT' ? (
              <div className="link-info-container">
                <h4>Link para contratação (Computador / Celular)</h4>
                <div className="link-options-container">
                  <input value={frontendLink} />
                  <div className="link-options-buttons-container">
                    <button className="copy-link-button" onClick={copyPcLink}>
                      Copiar link
                    </button>
                    {pcCopiedMessage ? <p>{pcCopiedMessage}</p> : ''}
                  </div>
                  <div className="link-options-buttons-container">
                    <a
                      className="open-link-button"
                      href={frontendLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Abrir link
                    </a>
                  </div>
                </div>
                <div className="qr-code-container">
                  <QRCode
                    size={256}
                    style={{ height: 'auto', width: '120px' }}
                    value={frontendLink}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="link-info-container">
              <h4>Link para contratação (WhatsApp)</h4>
              <div className="link-options-container">
                <input value={whatsAppLink} />
                <div className="link-options-buttons-container">
                  <button
                    className="copy-link-button"
                    onClick={copyWhatsAppLink}
                  >
                    Copiar link
                  </button>
                  {WhatsAppCopiedMessage ? <p>{WhatsAppCopiedMessage}</p> : ''}
                </div>
                <div className="link-options-buttons-container">
                  <a
                    className="open-link-button"
                    href={whatsAppLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Abrir link
                  </a>
                </div>
              </div>
              <div className="qr-code-container">
                <QRCode
                  size={256}
                  style={{ height: 'auto', width: '120px' }}
                  value={whatsAppLink}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </Layout>
  );
};

export default CreatePay;
