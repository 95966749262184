import { BsAlarm, BsTrophy, BsWallet } from 'react-icons/bs';
import { GiArcheryTarget } from 'react-icons/gi';

import classes from './styles.module.scss';

function About() {
    return (
        <section className={classes.about} id="about">
            <div className="container">
                <h5>EXPERIÊNCIA E SEGURANÇA</h5>
                <h2>Quem somos</h2>
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <p>
                            Tá no Bolso é a sua melhor opção para te apoiar a realizar os seus sonhos.
                            Não adie para amanhã o que você pode realizar hoje.
                            Comprar alguma coisa muito desejada, fazer uma viagem, abrir seu próprio negócio, renegociar sua dívida, ou para o que você precisar, a Tá no Bolso está aqui disponível para te apoiar.
                            <span>
                                Vem realizar seu sonho e reorganizar<br />
                                sua vida com a Tá no Bolso!
                            </span>
                        </p>
                    </div>
                </div>
                <div className={`row ${classes.iconsList}`}>
                    <div className="col-md-3">
                        <BsTrophy className={classes.icon} />
                        <h6>
                            Taxas<br />
                            competitivas
                        </h6>
                    </div>
                    <div className="col-md-3">
                        <BsAlarm className={classes.icon} />
                        <h6>
                            Prazos<br />
                            acessíveis
                        </h6>
                    </div>
                    <div className="col-md-3">
                        <GiArcheryTarget className={classes.icon} />
                        <h6>
                            Praticidade e<br />
                            Agilidade
                        </h6>
                    </div>
                    <div className="col-md-3">
                        <BsWallet className={classes.icon} />
                        <h6>
                            Crédito online na<br />
                            palma da sua mão
                        </h6>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
