import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import './App.css';
import history from './services/history';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes/Routes';

function App() {
  const [isSimulatorOpen, setIsSimulatorOpen] = useState(false);
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [defaultValue, setDefaultValue] = useState(0);
  const [simulationType, setSimulationType] = useState<string>('Consigned');
  const [tokenId, setToken] = useState(null);
  const [currentUrl, setCurrentUrl] = useState<string>('Home');
  const [simulatorForWhatsApp, setSimulatorForWhatsApp] =
    useState<boolean>(false);

  const faker = window.location.href.indexOf('localhost') !== -1;
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const paramToken = params.get('token');
  const paramName = params.get('nome') ?? '';
  const paramCpf = params.get('cpf') ?? '';
  const paramEmail = params.get('email') ?? '';
  const paramPhone = params.get('telefone') ?? '';
  const paramValue: number = Number(params.get('valor')) ?? 0;
  const paramInstallments = params.get('parcelas') ?? '84x';
  const paramSimulator = params.get('simulator');
  const paramAdhesionContractData = params.get('adhesion-contract')
    ? params.get('adhesion-contract').replace(/ /g, '+')
    : '';

  function clickTracking(url: string) {
    setCurrentUrl(url);
  }

  const handleSimulateWhatsApp = ({
    simulationTypeText,
  }: {
    simulationTypeText?: string;
  }) => {
    const whatsAppPhone = faker
      ? `55${process.env.REACT_APP_FAKER_PHONE}`
      : '5521965773075';
    const text = `Tipo de simulação: ${simulationTypeText}`;

    window.open(
      `https://wa.me/${whatsAppPhone}?text=${text
        .replace(/  +/g, '')
        .replace(/(?:\r\n|\r|\n)/g, '%0a')
        .replace(/ /g, '%20')}`,
      '_blank',
    );
  };

  const closeSimulator = () => [
    clickTracking('#simulator-close'),
    setIsSimulatorOpen(false),
    setIsResultOpen(false),
  ];

  const openSimulator = ({
    value = 0,
    simulationType = 'Consigned',
    simulationTypeText = 'Empréstimo Consignado',
  }: {
    value?: number;
    simulationType?: string;
    simulationTypeText?: string;
  }) => {
    setDefaultValue(value);
    setSimulationType(simulationType);

    clickTracking('#simulator-open');

    if (simulationType === 'Consigned') {
      setIsSimulatorOpen(true);
    } else {
      handleSimulateWhatsApp({ simulationTypeText });
    }
  };

  const openResult = (value) => {
    setToken(value);
    setIsResultOpen(true);
  };

  useEffect(() => {
    if (paramToken) {
      openResult(paramToken);
      clickTracking('#simulation-status-open');
    }

    if (paramSimulator) {
      openSimulator({
        value: 0,
      });

      clickTracking('#simulator-open-with-link');

      if (paramSimulator === 'for-whatsapp') {
        setSimulatorForWhatsApp(true);
      }
    }

    if (
      paramName &&
      paramEmail &&
      paramPhone &&
      !paramValue &&
      !paramInstallments
    ) {
      clickTracking('#simulator-open-with-name-email-phone');
      openSimulator({
        value: 0,
      });
    }

    if (
      paramName &&
      paramEmail &&
      paramPhone &&
      paramValue &&
      paramInstallments
    ) {
      clickTracking('#simulator-open-with-name-email-phone-value-installment');
      openSimulator({
        value: paramValue,
      });
    }
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <Router history={history}>
          {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (
            <span className="dev-container">AMBIENTE DEV</span>
          ) : (
            ''
          )}
          <Routes />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
