import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface Params {
  type: string;
}

export default function Redirect() {
  const { type } = useParams<Params>();

  useEffect(() => {
    redirect();
  }, []);

  const redirect = async () => {
    if (type === 'obrigado-lp') {
      await window.gtag('event', 'conversion', {'send_to': 'AW-389436595/aHGrCJnG7Y8CELOp2bkB'});
      sendForWhatsApp('Tipo de simulação: Antecipação Saque-Aniversário');
    }
  }

  function sendForWhatsApp(text: string = '')  {
    const whatsAppPhone = process.env.REACT_APP_WHATSAPP_PHONE;

    window.location.replace(`https://wa.me/${whatsAppPhone}?text=${text.replace(/  +/g, '').replace(/(?:\r\n|\r|\n)/g, '%0a').replace(/ /g, '%20')}`);
}

  return (<></>);
}
