import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import '../../../styles/global.scss';
import '../styles.scss';

import { useAuth } from '../../../hooks/useAuth';
import { useQuery } from '../../../hooks/useQuery';

import { Button } from '../../../components/Button';

import logoImg from '../../../assets/logo/logo_header.svg';
import { ResponseStatus } from '../../../enums/response-status';

type StatusAlertType = {
  status: string;
  message: string;
};

function SignInAncar() {
  const query = useQuery();
  const [loadingAncarLogin, setLoadingAncarLogin] = useState<boolean>(true);
  const [cpf, setCpf] = useState<string>('');
  const [tokenCheck, setTokenCheck] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [storeId, setStoreId] = useState<string>('');
  const [disableLogin, setDisableLogin] = useState<boolean>(false);
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);

  const { loading, ancarLogin, handleLoginWithToken } = useAuth();

  useEffect(() => {
    const handleAncar = async () => {
      setLoadingAncarLogin(true);

      const userIdAncar = query.get('userId');
      const storeId = query.get('storeId');
      const response = await ancarLogin(userIdAncar, storeId);

      if (response?.status === 'success') {
        setTokenCheck(response.token || '');
        setUserId(response.userId || '');
        setStoreId(response.storeId || '');
      } else {
        setDisableLogin(true);
        setStatusMessageAlert({
          status: response.status,
          message: response?.error?.message || response.message || '',
        });
      }

      setLoadingAncarLogin(false);
    };

    handleAncar();
  }, []);

  async function handleSignIn(
    event: React.FormEvent<HTMLFormElement | HTMLInputElement>,
  ) {
    event.preventDefault();

    setStatusMessageAlert(null);

    if (cpf === '') {
      setStatusMessageAlert(null);
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha o cpf para continuar.',
      });
      return;
    }

    const response = await handleLoginWithToken({
      cpf,
      storeId,
      tokenCheck,
      userId,
    });

    if ('status' in response)
      if (response.status === ResponseStatus.ERROR) {
        setStatusMessageAlert({
          status: 'error',
          message: response.message as string,
        });
      }
  }

  return (
    <div id="page-auth">
      <aside>
        <strong>Entrar</strong>
        <p>Aqui você consegue entrar na sua área logada.</p>
      </aside>
      <main>
        <div className="main-content">
          <div className="logoTnb">
            <img src={logoImg} alt="Ta no bolso" />
          </div>
          <form onSubmit={handleSignIn}>
            {loadingAncarLogin ? (
              <h2>Checando seu token...</h2>
            ) : (
              <>
                <h2>Login</h2>
                {!disableLogin ? (
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>Digite os 3 primeiros dígitos do seu CPF</label>
                      <input
                        name="cpf"
                        type="text"
                        className={`form-control`}
                        onChange={(e: any) => setCpf(e.target.value)}
                        onKeyDown={(e) =>
                          e.key === 'Enter' ? handleSignIn(e) : ''
                        }
                        autoComplete="off"
                        placeholder=""
                        value={cpf}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {statusMessageAlert && (
                  <div
                    className={
                      statusMessageAlert.status === 'error'
                        ? 'alert alert-danger alert-text-center'
                        : 'alert alert-primary alert-text-center'
                    }
                    role="alert"
                  >
                    {statusMessageAlert.message}
                  </div>
                )}
                {!disableLogin ? (
                  <Button type="submit" disabled={!!loading}>
                    {!loading ? 'Entrar' : 'Entrando...'}
                  </Button>
                ) : (
                  ''
                )}
              </>
            )}
          </form>
        </div>
      </main>
    </div>
  );
}

export default SignInAncar;
