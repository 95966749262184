import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';

import '../../styles/global.scss';
import './styles.scss';

import { api } from '../../services/api';
import { Button } from '../../components/Button';

import logoImg from '../../assets/logo/logo_header.svg';
import { FiChevronLeft } from 'react-icons/fi';

type StatusAlertType = {
  status: string;
  message: string;
};

function SignUp() {
  const { partner } = useParams();
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setName(process.env.REACT_APP_FAKER_NAME);
      setCpf(process.env.REACT_APP_FAKER_CPF_FGTS);
      setPhone(process.env.REACT_APP_FAKER_PHONE);
      setEmail(process.env.REACT_APP_FAKER_EMAIL);
    }
  }, []);

  async function handleSignUp() {
    const unmaskedCpf = cpf.replace(/[^\d]+/g, '');
    const unmaskedPhone = phone.replace(/[^\d]+/g, '');

    setLoading(true);
    setStatusMessageAlert(null);

    if (
      name === '' ||
      email === '' ||
      unmaskedCpf === '' ||
      unmaskedPhone === '' ||
      password === ''
    ) {
      setLoading(false);
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha todos os campos!',
      });

      return;
    }

    if (password !== passwordConfirmation) {
      setLoading(false);
      setStatusMessageAlert({
        status: 'error',
        message: 'A senha e a confirmação de senha devem ser iguais!',
      });

      return;
    }

    try {
      const { data } = await api.post('/auth/signup', {
        name,
        email,
        phone: unmaskedPhone,
        cpf: unmaskedCpf,
        password,
        passwordConfirmation,
      });

      clearInputs();
      setStatusMessageAlert({
        status: 'info',
        message: `Conta criada com sucesso! Você receberá um e-mail (${email}) para ativar, caso não ative, o mesmo será excluído em 24 horas.`,
      });
    } catch (error) {
      const message = Array.isArray(error.response.data.message)
        ? error.response.data.message[0]
        : error.response.data.message;

      setStatusMessageAlert({
        status: 'error',
        message,
      });
    }

    setLoading(false);
  }

  function clearInputs() {
    setName('');
    setCpf('');
    setPhone('');
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
  }

  return (
    <div id="page-signup">
      <aside>
        <p className="back-home-link">
          <FiChevronLeft className="left-chevron" />
          <Link to="/">Voltar</Link>
        </p>
        <strong>Registrar</strong>
        <p>Aqui você registra sua conta para ter acesso ao seus empréstimos.</p>
      </aside>
      <main>
        <div className="main-content">
          <div className="logoTnb">
            <img src={logoImg} alt="Ta no bolso" />
          </div>
          <form onSubmit={() => {}}>
            <h2>Registro</h2>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Nome*</label>
                <input
                  name="name"
                  className={`form-control`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value)
                  }
                  value={name}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>CPF*</label>
                <InputMask
                  name="cpf"
                  mask="999.999.999-99"
                  className={`form-control`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCpf(e.target.value)
                  }
                  autoComplete="off"
                  placeholder=""
                  value={cpf}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>E-mail*</label>
                <input
                  name="email"
                  className={`form-control`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  value={email}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Telefone*</label>
                <InputMask
                  name="phone"
                  mask="(99) 99999-9999"
                  className={`form-control`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPhone(e.target.value)
                  }
                  autoComplete="off"
                  placeholder=""
                  value={phone}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Senha*</label>
                <input
                  name="password"
                  type="password"
                  className={`form-control`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  value={password}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Confirmação de senha*</label>
                <input
                  name="password_confirmation"
                  type="password"
                  className={`form-control`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPasswordConfirmation(e.target.value)
                  }
                  value={passwordConfirmation}
                />
              </div>
            </div>
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger alert-text-center'
                    : 'alert alert-primary alert-text-center'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
            <Button type="button" onClick={handleSignUp}>
              {!loading ? 'Registrar' : 'Registrando...'}
            </Button>
            <p>
              <Link to={partner ? `/signin/${partner}` : '/signin'}>
                Já tenho conta!
              </Link>
            </p>
          </form>
        </div>
      </main>
    </div>
  );
}

export default SignUp;
