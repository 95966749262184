import React from 'react';
import { useParams } from 'react-router-dom';

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarLinkR,
  SidebarRoute,
  SideBtnWrap,
} from './styles';

const Sidebar = ({ isOpen, toggle, partnerParam = '' }) => {
  const { partnerParam: partnerParams }: any = useParams();
  partnerParam = partnerParam ? partnerParam : partnerParams;

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {partnerParam && (
          <>
            <SidebarMenu>
              <SidebarLinkR to="/consignado" onClick={toggle}>
                CONTRATE SEU EMPRÉSTIMO
              </SidebarLinkR>
              <SidebarLinkR to="/consignado" onClick={toggle}>
                Perguntas Frequentes
              </SidebarLinkR>
              <SidebarLinkR to="/signin" onClick={toggle}>
                Área do Cliente
              </SidebarLinkR>
            </SidebarMenu>
          </>
        )}
        {!partnerParam && (
          <>
            <SidebarMenu>
              <SidebarLinkR
                  to={{ pathname: "https://conteudo.qualitydigital.global/8dh-lp-chat-commerce-ta-no-bolso#rd-section-joq3m2vm" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={toggle}
              >
              Chat-Commerce
              </SidebarLinkR>
              <SidebarLinkR to="/fgts-pay-banner" onClick={toggle}>
                FGTS PAY
              </SidebarLinkR>
              <SidebarLinkR to="/fgts" onClick={toggle}>
                Saque-Aniversário FGTS
              </SidebarLinkR>
              <SidebarLink to="about" onClick={toggle}>
                Quem somos
              </SidebarLink>
              <SidebarLink to="faq" onClick={toggle}>
                Perguntas frequentes
              </SidebarLink>
              <SidebarLink to="contact" onClick={toggle}>
                Contato
              </SidebarLink>
              <SidebarLink to="blog" onClick={toggle}>
                Blog
              </SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
              <SidebarRoute to="/signin">Entrar</SidebarRoute>
            </SideBtnWrap>
            <SideBtnWrap>
              <SidebarRoute to="/signup">Registrar</SidebarRoute>
            </SideBtnWrap>
          </>
        )}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
